import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { IPasswordRecovery } from './IPasswordRecoveryAPI'

export class PasswordRecoveryAPI implements IPasswordRecovery {
    private routes = {
        resetPasswordRequest: makeURL('/api/users/reset_password_request'),
        validateToken: makeURL('/api/users/reset_password_validate_token'),
        resetPassword: makeURL('/api/users/reset_password')
    }

    public async resetPasswordRequest(username: string, email: string): Promise<void> {
        try {
            const body = {
                username: username,
                email: email
            }
            await axios.post(this.routes.resetPasswordRequest, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async validateToken(token: string): Promise<void> {
        try {
            const body = {
                token: token
            }
            await axios.post(this.routes.validateToken, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async resetPassword(token: string, newPassword: string): Promise<void> {
        try {
            const body = {
                token: token,
                new_password: newPassword
            }
            await axios.post(this.routes.resetPassword, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}

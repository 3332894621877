import { getEnv } from '@/utils/env'

let baseURL = getEnv('VUE_APP_BASE_API_URL')
if (process.env.NODE_ENV !== 'development') {
    baseURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
}

// baseURL = "https://vlaamsbrabant.flood4cast.be"

export function makeURL(path: string): string {
    let fullPath = path
    if (!path.startsWith('/')) {
        fullPath = `${path}/`
    }
    return `${baseURL}${fullPath}`
}

import { SettingDTO } from '@/api/setting/dto/SettingDTO'
import { Legend } from '@/model/Legend'

import { Setting } from '@/model/Setting'
import { LegendFactory } from './LegendFactory'

export class SettingFactory {
    static fromSettingDTO(dto: SettingDTO): Setting {
        return new Setting(
            dto.defaultLanguage,
            dto.cellSize,
            new Map<string, Legend>([
                ['flood_depth', LegendFactory.floodDepthLegendFromDTO(dto.legends)],
                ['flood_risk', LegendFactory.floodRiskLegendFromDTO(dto.legends)],
                ['rainfall', LegendFactory.rainfallLegendFromDTO(dto.legends)],
                ['return_period', LegendFactory.returnPeriodLegendFromDTO(dto.legends)],
                ['rain_gauges', LegendFactory.rainGaugesLegendFromDTO(dto.legends)]
            ]),
            dto.hasRainGauges
        )
    }
}

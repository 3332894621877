import { StatisticRaw } from './StatisticRaw'

export class StatisticsRaw {
    timestamp: string
    maxT: number
    rainfall_1hour_vol_max: number
    rainfall_1day_vol_max: number
    area_T5_plus: number

    constructor(
        timestamp: string,
        maxT: number,
        rainfall1hourVol: number,
        rainfall1dayVol: number,
        areaT5Plus: number
    ) {
        this.timestamp = timestamp
        this.maxT = maxT
        this.rainfall_1hour_vol_max = rainfall1hourVol
        this.rainfall_1day_vol_max = rainfall1dayVol
        this.area_T5_plus = areaT5Plus
    }

    static fromRaw(raw: StatisticRaw): StatisticsRaw {
        return new StatisticsRaw(
            raw.timestamp,
            raw.stats.maxT,
            raw.stats.rainfall_1hour_vol_max,
            raw.stats.rainfall_1day_vol_max,
            raw.stats.area_T5_plus
        )
    }
}

export class Coordinates {
    constructor(private latitude: number, private longitude: number) {}

    getLatitude(): number {
        return this.latitude
    }
    getLongitude(): number {
        return this.longitude
    }

    distanceFrom(lat: number, long: number) {
        const dLat = this.latitude - lat
        const dLong = this.longitude - long

        return Math.sqrt(dLat * dLat + dLong * dLong)
    }
}








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime, Duration } from 'luxon'

import { Snapshot } from '@/model/Snapshot'
import { TimeStep } from '@/model/TimeStep'
import { IRepresentation } from '@/model/Representation'

import DateTimeAxis from './DateTimeAxis.vue'
import Heatline from '@/components/timeline/Heatline.vue'
import Slider from '@/components/timeline/Slider.vue'

@Component({
    components: {
        Slider,
        DateTimeAxis,
        Heatline
    }
})
export default class Timeline extends Vue {
    @Prop() mouseMoveEvt!: MouseEvent
    @Prop() mouseClickEvt!: MouseEvent

    @Prop({ required: true }) snapshot!: Snapshot
    @Prop({ required: true }) timeStep!: TimeStep
    @Prop({ required: true }) representation!: IRepresentation

    @Prop({ type: Boolean, default: true }) activatedSlider!: boolean
    @Prop({ type: Boolean, default: true }) activatedAxis!: boolean
    @Prop({ type: Boolean, default: false }) playModeActivated!: boolean

    private current: TimeStep | null = null
    private loading = true

    get durationBefore(): Duration {
        return this.snapshot.getDurationBefore()
    }

    get durationAfter(): Duration {
        return this.snapshot.getDurationAfter()
    }

    get start(): DateTime {
        return this.snapshot.getStart()
    }

    get end(): DateTime {
        return this.snapshot.getEnd()
    }

    async mounted(): Promise<void> {
        this.loadSnapshot()
    }

    @Watch('snapshot')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    onCurrentSnapshotChanged(snapshot: Snapshot | null): void {
        this.loading = true
        this.loadSnapshot()
    }

    loadSnapshot(): void {
        if (!this.snapshot) {
            return
        }
        if (this.current === null || this.current.snapshot !== this.snapshot) {
            this.current = this.snapshot.getReference()
            this.loading = false
        }
    }

    @Watch('timeStep')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    onCurrentTimeStepChanged(timeStep: TimeStep | null): void {
        if (timeStep === null) return

        if (this.snapshot && this.snapshot === timeStep.snapshot) {
            this.current = timeStep
            this.loading = false
        }
    }

    onDateTimeChanged(oldDateTime: DateTime, newDateTime: DateTime): void {
        if (!oldDateTime.equals(newDateTime)) {
            this.$emit('selected-snapshot-changed', newDateTime.toUTC())
        }
    }

    onTimeStepChanged(ts: TimeStep): void {
        if (ts !== this.timeStep) {
            this.$emit('selected-timestep-changed', ts)
        }
    }
}

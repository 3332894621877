import { EventEmitter } from '@/model/EventEmitter'
import { Mode } from '@/model/Mode'

import { IModeService, ModeChangedEventArgs } from './IModeService'

export class ModeService implements IModeService {
    readonly modeChanged = new EventEmitter<ModeChangedEventArgs>()
    private mainMode: Mode = 'live'
    private secondaryMode: Mode = 'pause'

    async setMode(newMode: Mode): Promise<void> {
        if (newMode === 'live' || newMode === 'free' || newMode ==='history') {
            if (newMode !== this.mainMode) {
                const oldMode = this.mainMode
                this.mainMode = newMode
                this.modeChanged.notify(
                    new ModeChangedEventArgs(oldMode, this.secondaryMode, newMode, this.secondaryMode)
                )
            }
        } else {
            if (newMode !== this.secondaryMode) {
                const oldMode = this.secondaryMode
                this.secondaryMode = newMode
                this.modeChanged.notify(new ModeChangedEventArgs(this.mainMode, oldMode, this.mainMode, newMode))
            }
        }
    }

    getMainMode(): Mode {
        return this.mainMode
    }

    getSecondaryMode(): Mode {
        return this.secondaryMode
    }
}

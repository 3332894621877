


















import { Component, Vue } from 'vue-property-decorator'

import { CityCollection } from '@/model/CityCollection'
import { ICityService } from '@/services/city/ICityService'

import Navbar from './navbar/Navbar.vue'

@Component({
    components: {
        Navbar
    }
})
export default class Secure extends Vue {
    private cityService: ICityService | null = null

    private cityCollection?: CityCollection | null = null
    private isLoading = true

    created(): void {
        this.cityService = this.$services.get<ICityService>('city')
        this.cityCollection = new CityCollection([])
    }

    async mounted(): Promise<void> {
        this.cityCollection = (await this.cityService?.loadCityCollection()) as CityCollection
        this.isLoading = false
    }
}

import { DateTime } from 'luxon'

export class Event {
    constructor(
        private city: string,
        private start: DateTime,
        private end: DateTime,
        private maxTTime: DateTime,
        private maxTGeojson: string,
        private maxT: number,
        private max1hourVol: number,
        private max1dayVol: number,
        private areaT5Plus: number
    ) {}

    getCity(): string {
        return this.city
    }

    getStartDate(): DateTime {
        return this.start
    }

    getEndDate(): DateTime {
        return this.end
    }

    getMaxTTime(): DateTime {
        return this.maxTTime
    }

    getMaxTGeojson(): string {
        return this.maxTGeojson
    }

    getMaxT(): number {
        return this.maxT
    }

    getMax1hourVol(): number {
        return this.max1hourVol
    }

    getMax1dayVol(): number {
        return this.max1dayVol
    }

    getAreaT5Plus(): number {
        return this.areaT5Plus
    }
}

import { Coordinates } from '@/model/Coordinates'
import { EventEmitter } from '@/model/EventEmitter'
import { Feature, FeatureCollection } from '@turf/turf'
import { GeoJsonProperties, Geometry } from 'geojson'
import { DateTime } from 'luxon'
import { IRainGaugeService, RainGaugeLegend, RainGaugeSelectionEventArgs } from './IRainGaugeService'

import RainGaugesDoc from './mock/hanoi_rainGauges.json'
import TimeSeriesDoc from './mock/timeseries_rainGauge_12103.json'

import { RainGaugeFactory } from './RainGaugeFactory'
import { RainGaugeTimeSeriesFactory } from './RainGaugeTimeSeriesFactory'
import { RainGauge } from '@/model/RainGauge'
import { RainGaugeAPI } from '@/api/raingauges/RainGaugeAPI'
import { RainGaugeTimeSeries } from '@/model/RainGaugeTimeSeries'
import { ServiceLocator } from "@/services/ServiceLocator"
import { BaseService } from '@/services/BaseService'
import { ISettingService } from "@/services/setting/ISettingService"
import { Legend } from '@/model/Legend'


export class RainGaugeService extends BaseService implements IRainGaugeService {
    private api: RainGaugeAPI = new RainGaugeAPI()
    private selectedRainGauges: Map<string, RainGauge> = new Map()
    private readonly settingService!: ISettingService

    readonly rainGaugeSelectionChanged = new EventEmitter<RainGaugeSelectionEventArgs>()
    private legend!: Legend;

    constructor(locator: ServiceLocator) {
        super(locator)
        this.settingService = locator.get<ISettingService>('setting')
    }

    async toggleRainGauge(rainGauge: RainGauge): Promise<void> {
        if (this.selectedRainGauges.has(rainGauge.id)) {
            this.selectedRainGauges.delete(rainGauge.id)
            this.rainGaugeSelectionChanged.notify(new RainGaugeSelectionEventArgs('remove', [rainGauge]))
        } else {
            this.selectedRainGauges.set(rainGauge.id, rainGauge)
            this.rainGaugeSelectionChanged.notify(new RainGaugeSelectionEventArgs('add', [rainGauge]))
        }
    }

    clearRainGauges(): void {
        const deletedRainGauges = [...this.selectedRainGauges.values()]
        this.selectedRainGauges.clear()
        if (deletedRainGauges.length > 0) {
            this.rainGaugeSelectionChanged.notify(new RainGaugeSelectionEventArgs('remove', deletedRainGauges))
        }
    }

    async getRainGaugesWithinCircle(coordinates: Coordinates, radius: number): Promise<RainGauge[]> {
        try {
            // const rainGaugesDTO = RainGaugesDoc as RainGaugeDTO[]
            const rainGaugesDTO = await this.api.getRainGauges(coordinates.getLatitude(), coordinates.getLongitude())
            const filteredRainGauges = rainGaugesDTO.filter(
                (dto) => coordinates.distanceFrom(dto.geolocation.latitude, dto.geolocation.longitude) < radius
            )
            return Promise.resolve(RainGaugeFactory.fromDTO(filteredRainGauges))
        } catch (e) {
            return Promise.resolve([])
        }
    }

    async getRainGaugesWithRainfall(coordinates: Coordinates, radius: number, timestamp: DateTime): Promise<Array<RainGauge>> {
        try {
            // const rainGaugesDTO = RainGaugesDoc as RainGaugeDTO[]
            const rainGaugesDTO = await this.api.GetRainGaugesWithRainfall(coordinates.getLatitude(), coordinates.getLongitude(), timestamp)
            const filteredRainGauges = rainGaugesDTO.filter(
                (dto) => coordinates.distanceFrom(dto.geolocation.latitude, dto.geolocation.longitude) < radius
            )
            return Promise.resolve(RainGaugeFactory.fromDTO(filteredRainGauges))
        } catch (e) {
            return Promise.resolve([])
        }
    }

    async getTimeSeriesForRainGauge(rainGauge: RainGauge, timestamp: DateTime): Promise<RainGaugeTimeSeries> {
        const dto = await this.api.getTimeseriesForRainGaugeByTimestamp(rainGauge.id, timestamp.toUTC())
        return Promise.resolve(RainGaugeTimeSeriesFactory.fromDTO(dto))
    }

    async getTimeSeriesForRainGaugeByPeriod(rainGauge: RainGauge, start: DateTime, end: DateTime): Promise<RainGaugeTimeSeries> {
        const dto = await this.api.getTimeSeriesForRainGaugeByPeriod(rainGauge.id, start.toUTC(), end.toUTC())
        return Promise.resolve(RainGaugeTimeSeriesFactory.fromDTO(dto))
    }

    async mapToFeatureCollection(rainGauges: RainGauge[]): Promise<FeatureCollection<Geometry, GeoJsonProperties>> {
        const legend = await this.getLegend()
        return {
            type: 'FeatureCollection',
            features: [...rainGauges.map((s) => this.mapToFeature(s, legend))]
        }
    }

    private mapToFeature(rainGauge: RainGauge, legend: Legend): Feature<Geometry, GeoJsonProperties> {
        return {
            type: 'Feature',
            id: rainGauge.id,
            geometry: {
                type: 'Point',
                coordinates: rainGauge.getLngLat()
            },
            properties: {
                id: rainGauge.id,
                name_no: `${rainGauge.name}`,
                color: this.getColor(rainGauge.rainfall.cumulative_value, legend)
            }
        }
    }

    private async getLegend(): Promise<Legend> {
        if (this.legend == null) {
            const settings = await this.settingService.getSettings()
            this.legend = settings.getLegend("rain_gauges")
        }

        return this.legend
    }

    private getColor(cumulativeRainfall: number, legend: Legend): string {
        const thresholds = legend.thresholds

        // 0 and -1 are special cases
        if (cumulativeRainfall == -1) {
            return thresholds[0].color
        }
        if (cumulativeRainfall == 0) {
            return thresholds[1].color
        }

        for (let i = 2;  i < thresholds.length; i++) {
            const threshold = thresholds[i]
            if (cumulativeRainfall < threshold.value) {
                return threshold.color
            }
        }

        return thresholds[0].color
    }
}

import _Vue, { VNode } from 'vue'

import { serviceLocatorInstance as locator } from '@/services/ServiceLocator'
import { IAccessControlService } from '@/services/acl/IAccessControlService'
import { DirectiveBinding, DirectiveOptions } from 'vue/types/options'
import { Permissions } from '@/model/Permissions'

const ACL = locator.get<IAccessControlService>('acl')

export default {
    install: (Vue: typeof _Vue): void => {
        Vue.directive('acl', <DirectiveOptions>{
            bind: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode): void => {
                let hasRight = false
                if (binding.arg === 'is-admin') {
                    hasRight = ACL.isAdmin
                } else if (binding.arg === 'has-history') {
                    hasRight = ACL.hasPermission(Permissions.HISTORY) || ACL.hasPermission(Permissions.FULL_ACCESS)
                } else if (binding.arg === 'is-not-live-only') {
                    hasRight = !ACL.hasPermission(Permissions.LIVE_ONLY)
                } else if (binding.arg == 'has-full-access') {
                    hasRight = ACL.hasPermission(Permissions.FULL_ACCESS)
                }

                if (!hasRight && vnode.elm) {
                    const nodeElement = vnode.elm as unknown as HTMLElement
                    nodeElement.style.display = 'none'
                }
            }
        })
    }
}







import { Vue, Prop, Component, Emit, Watch } from 'vue-property-decorator'

@Component
export default class RequiredField extends Vue {
    @Prop() label!: string
    @Prop() message!: string
    @Prop({ type: String, default: '' }) value!: string
    @Prop() placeholder!: string

    hasError: boolean | null = null
    fieldType = 'is-required'
    internalValue = ''

    mounted(): void {
        this.internalValue = this.value
    }

    @Emit('update:value')
    validate(): string {
        this.hasError = this.internalValue.trim().length === 0
        return this.internalValue.trim()
    }

    @Watch('hasError')
    onValidationChanged(): void {
        this.fieldType = this.hasError ? 'is-danger' : 'is-success'
    }
}





















import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'

import { User } from '@/model/User'

import { ITranslationService } from '@/services/translation/ITranslationService'

@Component({
    components: {}
})
export default class GeneralForm extends Vue {
    @Prop({ type: User }) user!: User

    private translationService!: ITranslationService

    private userLanguage = ''
    private selectedLanguage = ''
    private availableLanguages = new Map()
    private languages: string[] = []

    created(): void {
        this.translationService = this.$services.get<ITranslationService>('translation')
    }

    async displayAvailableLanguages(): Promise<void> {
        this.languages = []
        let availableLanguages = await this.translationService.getAvailableLanguages()
        if (availableLanguages) {
            this.availableLanguages = availableLanguages

            for (const [key, value] of this.availableLanguages) {
                this.languages.push(value)
                if (this.userLanguage !== '' && this.userLanguage == key) {
                    this.selectedLanguage = value
                }
            }

            if (this.userLanguage === '') {
                this.selectedLanguage = this.languages[0]
            }
        }
    }

    @Emit('submit')
    public notifySubmit(): string | null {
        for (const [key, value] of this.availableLanguages) {
            if (value === this.selectedLanguage) {
                return key
            }
        }
        return null
    }

    @Watch('user')
    onUserSet(): void {
        this.userLanguage = this.user.language
        this.displayAvailableLanguages()
    }
}

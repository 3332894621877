
































import { ITimeAndCityLocalizable } from '@/model/ITimeAndCityLocalizable'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import RainGaugeChart from '@/components/raingauge/RainGaugeChart.vue'
import { DateTime, Interval } from 'luxon'
import { IRainGaugeService, RainGaugeSelectionEventArgs } from '@/services/raingauge/IRainGaugeService'
import { RainGauge } from '@/model/RainGauge'

@Component({
    components: {
        RainGaugeChart
    }
})
export default class RainGaugesTimeSeries extends Vue {
    @Prop({ type: String, default: 'timestamp' }) mode!: string
    @Prop() snapshot!: ITimeAndCityLocalizable
    private snapshotTimestamp!: DateTime
    private rainGaugeService!: IRainGaugeService
    private rainGauges: RainGauge[] = []
    private open = false

    created(): void {
        this.rainGaugeService = this.$services.get<IRainGaugeService>('raingauges')
        this.rainGaugeService.rainGaugeSelectionChanged.subscribe(this.rainGaugeSelectionChanged)
    }

    mounted(): void {
        this.snapshotTimestamp = this.snapshot?.timestamp ?? DateTime.fromSeconds(0)
    }

    beforeDestroy(): void {
        this.rainGaugeService.rainGaugeSelectionChanged.unsubscribe(this.rainGaugeSelectionChanged)
    }

    async rainGaugeSelectionChanged(eventArgs: RainGaugeSelectionEventArgs): Promise<void> {
        if ('add' === eventArgs.action) {
            this.rainGauges = [...this.rainGauges, ...eventArgs.rainGauges]
        } else if ('remove' === eventArgs.action) {
            eventArgs.rainGauges.forEach((r) => {
                const index = this.rainGauges.findIndex((sc) => sc.id === r.id)
                this.rainGauges.splice(index, 1)
            })
            this.rainGauges = [...this.rainGauges]
        }
    }

    @Watch('rainGauges')
    onRainGaugesChanged(): void {
        this.open = (this.rainGauges?.length ?? 0) > 0
    }

    @Watch('snapshot')
    onSnapshotChanged(): void {
        if (!this.snapshot) return

        if (this.snapshotTimestamp.toSeconds() != this.snapshot.timestamp.toSeconds()) {
            //Reload observed stations to update charts
            this.snapshotTimestamp = this.snapshot.timestamp

            const temp = [...this.rainGauges]
            this.rainGaugeService.clearRainGauges()
            window.setTimeout(() => {
                for (const r of temp) {
                    this.rainGaugeService.toggleRainGauge(r)
                }
            }, 33)
        }
    }

    onCloseRequested(): void {
        this.rainGaugeService.clearRainGauges()
    }

    get timestamp(): DateTime {
        return this.snapshot.timestamp
    }

    get timeperiod(): Interval {
        return Interval.fromDateTimes(this.snapshot.getStart(), this.snapshot.getEnd())
    }
}


























































































import { DateTime } from 'luxon'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { Statistic } from '@/model/Statistic'

import { IStatisticService } from '@/services/statistics/IStatisticService'

@Component({
    components: {}
})
export default class EventTimesteps extends Vue {
    @Prop({ type: City, required: true }) city!: City
    @Prop({ type: String, required: true }) start!: string
    @Prop({ type: String, required: true }) end!: string
    @Prop({ type: Array, required: true }) initStatistics!: Statistic[]
    @Prop({ type: Number, required: true }) totalStatistics!: number

    private statisticsService: IStatisticService | null = null

    private isLoading = false
    private statistics: Statistic[] = []
    private noStatistics = true
    private allStatisticsOnPage = false
    private statsPerPage = 10
    private currentPage = 1
    private sortDirection = 'asc'

    async created(): Promise<void> {
        this.statisticsService = this.$services.get<IStatisticService>('statistics')
        this.loadRainfallStatistics()
        if (this.totalStatistics < 10 && this.totalStatistics > 5) {
            this.statsPerPage = 5
        } else if (this.totalStatistics < 5) {
            this.statsPerPage = this.totalStatistics
        }
    }

    async loadRainfallStatistics(): Promise<void> {
        this.isLoading = true
        if (!this.city) {
            this.noStatistics = true
            this.isLoading = false
            return
        }
        const sortDirectionRequest = this.sortDirection === 'asc' ? true : false
        const statistics = await this.statisticsService?.getCityStatistics(
            this.city,
            this.currentPage,
            this.statsPerPage,
            sortDirectionRequest,
            DateTime.fromISO(this.start, { zone: 'UTC' }),
            DateTime.fromISO(this.end, { zone: 'UTC' })
        )

        if (!statistics) {
            this.noStatistics = true
            this.isLoading = false
            return
        }

        this.statistics = statistics.getStatistics()
        this.totalStatistics = statistics.getTotalStatistics()
        this.noStatistics = this.totalStatistics == 0 ? true : false
        this.statsPerPage = this.resetNumberOfStatisticsPerPage()
        this.isLoading = false
    }

    saveSortDirection(column: string, sort: string): void {
        /**
         * Currently, the only column that is sorted is the start date.
         */
        switch (column) {
            case 'time':
                this.sortDirection = sort
                break
            default:
                break
        }
    }

    resetNumberOfStatisticsPerPage(): number {
        return this.allStatisticsOnPage && this.noStatistics ? 10 : this.statsPerPage
    }

    showRainfall(statistic: Statistic): void {
        this.$router.push({
            name: 'Overview',
            query: {
                mode: 'free',
                snapshotTimestamp: statistic.getTimestamp().toISO(),
                timeStepTimestamp: statistic.getTimestamp().toISO()
            }
        })
    }

    get statsPerPageOptions(): number[] {
        const statsPerPageOptions: number[] = []
        for (let i = 5; i <= 20; i += 5) {
            if (this.totalStatistics && i < this.totalStatistics) {
                statsPerPageOptions.push(i)
            }
        }
        if (this.totalStatistics) {
            statsPerPageOptions.push(this.totalStatistics) // all rainfalls option
        }
        return statsPerPageOptions
    }

    @Watch('initStatistics')
    onInitStatisticsChanged(): void {
        this.statistics = this.initStatistics
    }

    @Watch('statsPerPage')
    onStatsPerPageChanged(): void {
        this.allStatisticsOnPage = this.statsPerPage === this.totalStatistics ? true : false
        this.currentPage = 1
        this.loadRainfallStatistics()
    }

    @Watch('currentPage')
    onPageChanged(): void {
        this.loadRainfallStatistics()
    }

    @Watch('sortDirection')
    onSortDirectionChanged(): void {
        this.currentPage = 1
        this.loadRainfallStatistics()
    }
}

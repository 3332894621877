import { Event } from './Event'

export class EventCollection {
    constructor(private events: Event[], private totalEvents: number) {
        this.events = events
        this.totalEvents = totalEvents
    }

    getEvents(): Event[] {
        return this.events
    }

    getTotalEvents(): number {
        return this.totalEvents
    }
}

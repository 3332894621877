import { GeoJsonProperties } from 'geojson'
import { AnyLayer } from 'mapbox-gl'

import { City } from '@/model/City'
import { TimeStep } from '@/model/TimeStep'
import { IMapService } from '@/services/map/IMapService'
import { serviceLocatorInstance } from '@/services/ServiceLocator'
import { ITranslationService } from '@/services/translation/ITranslationService'
import { MapBoxGL } from '../MapBoxGL'
import { IController } from './IController'
import { IControllerParent } from './IControllerParent'

export type LayerEvent = (mapboxgl.MapLayerMouseEvent | mapboxgl.MapLayerTouchEvent) & mapboxgl.EventData

export class BaseController implements IController {
    protected readonly translationService: ITranslationService

    public readonly sources: string[] = []
    public readonly layers: AnyLayer[] = []

    constructor(
        protected readonly parent: IControllerParent,
        protected readonly mapBoxGL: MapBoxGL,
        protected readonly mapService: IMapService
    ) {
        this.translationService = serviceLocatorInstance.get<ITranslationService>('translation')
    }

    public initialize(): void {
        this.initializeSources()
        this.initializeLayers()
        this.initializeCursors()
        this.initializeSymbols()
        this.initializePopup()
    }

    protected initializeSources(): void {
        const sources = this.getSources()
        for (const source of sources) {
            this.mapBoxGL.addSource(source)
        }
    }

    protected initializeLayers(): void {
        const layers = this.getLayers()
        for (const layer of layers) {
            this.layers.push(layer)
            this.mapBoxGL.addLayer(layer)
        }
    }

    protected initializeCursors(): void {
        const cursors = this.getCursors()
        for (const [layerId, cursorId] of cursors.entries()) {
            this.mapBoxGL.addCursor(layerId, cursorId)
        }
    }

    protected initializeSymbols(): void {
        const symbols = this.getSymbols()
        for (const [name, symbol] of symbols) {
            this.mapBoxGL.addSymbol(name, symbol)
        }
    }

    protected initializePopup(): void {
        const layers = this.getLayersWithPopup()
        for (const layer of layers) {
            this.mapBoxGL.addLayerEventHandler('mousemove', layer.id, (event: LayerEvent) =>
                this.onMouseMovingOnLayer(event)
            )
            this.mapBoxGL.addLayerEventHandler('mouseleave', layer.id, (event: LayerEvent) =>
                this.onMouseLeavingLayer(event)
            )
        }
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    public async load(city: City, timeStep: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        return Promise.resolve(false)
    }

    onMouseMovingOnLayer(event: LayerEvent): void {
        if (!event.features) {
            return
        }

        const feature = event.features[0]
        if (!feature) {
            return
        }

        const popupText = this.buildPopupText(feature.properties)
        if (!popupText) {
            return
        }

        this.mapBoxGL.showPopup(event.lngLat, popupText)
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    buildPopupText(properties: GeoJsonProperties): string | null {
        return ''
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    onMouseLeavingLayer(event: LayerEvent): void {
        this.mapBoxGL.hidePopup()
    }

    getSources(): string[] {
        return []
    }

    getLayers(): AnyLayer[] {
        return []
    }

    getLayersWithPopup(): AnyLayer[] {
        return []
    }

    getCursors(): Map<string, string> {
        return new Map()
    }

    getSymbols(): Map<string, { url: string; sdf: boolean }> {
        return new Map()
    }
}

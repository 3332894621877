








import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { newPlot, Data, Layout } from 'plotly.js'

@Component({
    components: {}
})
export default class EventChart extends Vue {
    @Prop({ type: Array, required: true }) readonly chartdata!: Data[]
    @Prop({ type: Object, required: true }) readonly layout!: Partial<Layout>

    @Ref('chart') readonly chart!: HTMLDivElement

    mounted(): void {
        this.drawPlot()
    }

    drawPlot(): void {
        newPlot(this.chart, this.chartdata, this.layout, {
            responsive: true,
            displayModeBar: false
        })
    }

    @Watch('chartdata')
    onChartDataChanged(): void {
        this.drawPlot()
    }
}

export class Station {
    constructor(
        readonly id: number,
        readonly no: number,
        readonly name: string,
        readonly location: [number, number],
        readonly stationTypes: string[],
        private readonly waterLevels: [number, number] = [0, 100]
    ) {}

    getLngLat(): [number, number] {
        return [this.location[1], this.location[0]]
    }

    getWaterLevels(): [number, number] {
        const rounded = [Math.max(0, Math.floor(this.waterLevels[0])), Math.max(0, Math.ceil(this.waterLevels[1]))]
        if (rounded[1] === 0) {
            rounded[1] = 100
        }
        return [Math.min(...rounded), Math.max(...rounded)]
    }
}

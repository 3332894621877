








































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'

import { UserPasswordUpdate } from '@/model/UserPasswordUpdate'

import RequiredPasswordField from './RequiredPasswordField.vue'

@Component({
    components: { RequiredPasswordField }
})
export default class PasswordForm extends Vue {
    private currentPassword = ''
    private newPassword = ''
    private confirmedNewPassword = ''

    private confirmedPasswordFieldHasError: boolean | null = null
    private confirmedPasswordFieldType = 'is-required'
    private confirmedPasswordFieldMessage = ''

    validateConfirmedNewPassword(): void {
        if (this.confirmedNewPassword.trim().length === 0) {
            this.confirmedPasswordFieldHasError = true
            this.confirmedPasswordFieldMessage = `${this.$t('model.user.password.current.required')}`
        } else if (this.newPassword !== this.confirmedNewPassword) {
            this.confirmedPasswordFieldHasError = true
            this.confirmedPasswordFieldMessage = `${this.$t(
                'dialogs.settings.user.section.password.must_be_identical'
            )}`
        } else {
            this.confirmedPasswordFieldHasError = false
        }
    }

    @Emit('submit')
    public notifySubmit(): UserPasswordUpdate {
        return new UserPasswordUpdate(this.currentPassword, this.newPassword, this.confirmedNewPassword)
    }

    get canSubmit(): boolean {
        return (
            this.currentPassword.trim() !== '' &&
            this.newPassword.trim() !== '' &&
            this.confirmedNewPassword.trim() !== '' &&
            this.newPassword === this.confirmedNewPassword
        )
    }

    @Watch('confirmedPasswordFieldHasError')
    onValidationChanged(): void {
        if (this.confirmedPasswordFieldHasError) {
            this.confirmedPasswordFieldType = 'is-danger'
        } else {
            this.confirmedPasswordFieldType = 'is-success'
            this.confirmedPasswordFieldMessage = ''
        }
    }
}
































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { IPasswordRecoveryService } from '@/services/password-recovery/IPasswordRecoveryService'
import { showToast } from '@/utils/toast'

@Component
export default class ResetPassword extends Vue {
    @Prop({ type: String }) readonly token!: string

    private passwordRecoveryService: IPasswordRecoveryService | null = null

    private password = ''
    private confirmPassword = ''
    private isTokenValid = false
    private error: string | null = null

    created(): void {
        this.passwordRecoveryService = this.$services.get<IPasswordRecoveryService>('password')
    }

    async mounted(): Promise<void> {
        this.isTokenValid = await this.validateToken()
    }

    async validateToken(): Promise<boolean> {
        try {
            if (this.token) {
                await this.passwordRecoveryService?.validateToken(this.token)
                return true
            }
        } catch (e) {
            this.error = 'dialogs.reset_password.errors.token'
            return false
        }
        return false
    }

    async resetPassword(): Promise<void> {
        if (this.isTokenValid) {
            try {
                await this.passwordRecoveryService?.resetPassword(this.token, this.password)
                showToast('is-success', 3000, `${this.$t('dialogs.reset_password.success')}`)
                this.redirectUser('Auth')
            } catch (error) {
                this.error = 'dialogs.reset_password.errors.reset'
            }
        } else {
            this.error = 'dialogs.reset_password.errors.invalid_token'
            this.redirectUser('PasswordForgotten')
        }
    }

    redirectUser(view: string): void {
        this.$router.push({ name: view })
    }

    get canSubmit(): boolean {
        return this.password.trim() !== '' && this.confirmPassword.trim() !== ''
    }
}

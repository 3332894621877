import { EventEmitter } from '@/model/EventEmitter'
import { IService } from '../IService'

export class UserAuthEventArgs {}

export interface IAuthService extends IService {
    readonly loggedIn: EventEmitter<UserAuthEventArgs>
    readonly loggedOut: EventEmitter<UserAuthEventArgs>

    login(username: string, password: string): Promise<boolean>
    isAuthenticated(): boolean
    logout(): void
    hasCaptchaConfigured(): boolean
}

export class EventDTO {
    constructor(
        readonly cityName: string,
        readonly startDate: string,
        readonly endDate: string,
        readonly maxTTime: string,
        readonly maxTGeojson: string,
        readonly maxT: number,
        readonly max1hourVol: number,
        readonly max1dayVol: number,
        readonly areaT5Plus: number
    ) {}
}

import { DateTime } from 'luxon'

import { MeasureKind } from '@/model/MeasureKind'
import { Station } from '@/model/Station'
import { TimeSeries } from '@/model/TimeSeries'
import { TimeSeriesDTO } from '@/api/stations/dto/TimeSeriesDTO'
import { MeasureDTO } from '@/api/stations/dto/MeasureDTO'
import { MeasureCollection } from '@/model/MeasureCollection'

export class TimeSeriesFactory {
    // TODO remove the below method
    static fromDTO(station: Station, dto: TimeSeriesDTO): TimeSeries {
        const measureKind = new MeasureKind(dto.measure_kind_name, 'mTAW')
        const flowMeasureKind = new MeasureKind(dto.flow_measure_kind_name, dto.flow_measure_kind_unit)

        return new TimeSeries(
            dto.ts_id,
            station,
            measureKind,
            TimeSeriesFactory.mapToRow(dto.history),
            TimeSeriesFactory.mapToRow(dto.forecasts),
            flowMeasureKind,
            TimeSeriesFactory.waterlevelToFlow(dto.history)
        )
    }

    static newFromDTO(station: Station, dtos: TimeSeriesDTO[]): TimeSeries {
        const waterlevelDTO = dtos.filter(dto => dto.measure_kind_name == "water.level")[0]
        const measureKind = new MeasureKind(waterlevelDTO.measure_kind_name, waterlevelDTO.measure_kind_unit)

        const flowDTO = dtos.filter(dto => dto.measure_kind_name == "water.flow")[0]

        let flowMeasureKind
        let flowHistory: MeasureDTO[]

        // TODO please handle this in a better way
        if (!flowDTO) {
            flowMeasureKind = new MeasureKind("water.flow", "m³/s")
            flowHistory = []
        } else {
            flowMeasureKind = new MeasureKind(flowDTO.measure_kind_name, flowDTO.measure_kind_unit)
            flowHistory = flowDTO.history
        }

        return new TimeSeries(
            waterlevelDTO.ts_id,
            station,
            measureKind,
            TimeSeriesFactory.mapToRow(waterlevelDTO.history),
            TimeSeriesFactory.mapToRow(waterlevelDTO.forecasts),
            flowMeasureKind,
            TimeSeriesFactory.mapToRow(flowHistory)
        )
    }

    static mapToRow(data: MeasureDTO[]): MeasureCollection {
        return new MeasureCollection(
            data.map((m) => [DateTime.fromISO(m.timestamp).toUTC(), m.value])
        )
    }

    static waterlevelToFlow(data: MeasureDTO[]): MeasureCollection {
        return new MeasureCollection(
            data.map((m) => {
                const waterLevelValue = m.value
                const flowValue = (waterLevelValue * 0.5) + (Math.random() * (2) - 1) * 2
                return [DateTime.fromISO(m.timestamp).toUTC(), flowValue]
            })
        )
    }
}

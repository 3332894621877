import { IService } from './IService'

export class ServiceLocator {
    /**
     * Registers and returns service instances based on a string key.
     * Inspired from : https://paloma.one/de/blog/simple-service-locator-with-typescript
     */

    private services: Map<string, IService> = new Map()

    add(serviceId: string, instance: IService): void {
        if (serviceId === null || serviceId.length === 0) {
            throw 'ServiceLocator.add.invalidServiceId : should be a non-blank string'
        }
        if (this.hasService(serviceId)) {
            throw `ServiceLocator.add.invalidServiceId : there is an already registered service for id ${serviceId}`
        }
        this.services.set(serviceId, instance)
    }

    get<T extends IService>(serviceId: string): T {
        if (!this.hasService(serviceId)) {
            throw `ServiceLocator.get.invalidServiceId : no registered service for id ${serviceId}`
        }
        return this.services.get(serviceId) as T
    }

    private hasService(serviceId: string): boolean {
        return this.services.has(serviceId)
    }
}

import { AuthService } from './auth/AuthService'
import { TranslationService } from './translation/TranslationService'
import { CityService } from './city/CityService'
//import { MockSnapshotService } from './snapshot/MockSnapshotService'
import { SnapshotService } from './snapshot/SnapshotService'
import { MapService } from './map/MapService'
import { EventService } from './event/EventService'
import { UserService } from './user/UserService'
import { MockCountryService } from './country/MockCountryService'
import { StatisticService } from './statistics/StatisticService'
import { HistoryService } from './history/HistoryService'
import { PasswordRecoveryService } from './password-recovery/PasswordRecoveryService'
import { SettingService } from './setting/SettingService'
import { RepresentationService } from './representation/RepresentationService'
import { ModeService } from './mode/ModeService'
import { StationService } from './station/StationService'
import { RainGaugeService } from './raingauge/RainGaugeService'
import { AccessControlService } from './acl/AccessControlService'

const serviceLocator = new ServiceLocator()

serviceLocator.add('auth', new AuthService(serviceLocator))
serviceLocator.add('password', new PasswordRecoveryService(serviceLocator))
serviceLocator.add('translation', new TranslationService(serviceLocator))
serviceLocator.add('setting', new SettingService(serviceLocator))
serviceLocator.add('city', new CityService(serviceLocator))
serviceLocator.add('map', new MapService(serviceLocator))
//serviceLocator.add('snapshot', new MockSnapshotService(serviceLocator))
serviceLocator.add('snapshot', new SnapshotService(serviceLocator))
serviceLocator.add('event', new EventService(serviceLocator))
serviceLocator.add('user', new UserService(serviceLocator))
serviceLocator.add('country', new MockCountryService(serviceLocator))
serviceLocator.add('statistics', new StatisticService(serviceLocator))
serviceLocator.add('history', new HistoryService(serviceLocator))
serviceLocator.add('representation', new RepresentationService(serviceLocator))
serviceLocator.add('mode', new ModeService())
serviceLocator.add('stations', new StationService())
serviceLocator.add('acl', new AccessControlService(serviceLocator))
serviceLocator.add('raingauges', new RainGaugeService(serviceLocator))

export const serviceLocatorInstance = serviceLocator

import { DateTime } from 'luxon'

import { SnapshotDTO } from '@/api/core/dto/snapshot/SnapshotDTO'
import { City } from '@/model/City'
import { Snapshot } from '@/model/Snapshot'
import { TimeStepFactory } from './TimeStepFactory'
import { TimeStepMaxFactory } from './TimeStepMaxFactory'

export class SnapshotFactory {
    static createEmpty(city: City, timestamp: DateTime): Snapshot {
        const result = new Snapshot(city, timestamp)
        result.setReference(TimeStepFactory.createEmpty(result, timestamp))
        return result
    }

    static fromDTO(dto: SnapshotDTO, city: City): Snapshot {
        const result = new Snapshot(city, DateTime.fromISO(dto.timestamp, { zone: 'UTC' }))

        for (const timeStepDTO of dto.history) {
            result.addHistory(TimeStepFactory.fromDTO(timeStepDTO, result))
        }

        result.setReference(TimeStepFactory.fromDTO(dto.reference, result))

        for (const timeStepDTO of dto.forecasts) {
            result.addForecast(TimeStepFactory.fromDTO(timeStepDTO, result))
        }

        result.setMax(TimeStepMaxFactory.fromDTO(dto.maxFrame, result))

        return result
    }
}


























































import { IAuthService } from '@/services/auth/IAuthService'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SettingsDropdown extends Vue {
    onLogout(): void {
        this.$services.get<IAuthService>('auth').logout()
        this.$router.push('/auth')
    }
}

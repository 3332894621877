import { Statistic } from './Statistic'

export class StatisticCollection {
    constructor(private statistics: Statistic[], private totalStatistics: number) {
        this.statistics = statistics
        this.totalStatistics = totalStatistics
    }

    getStatistics(): Statistic[] {
        return this.statistics
    }

    getTotalStatistics(): number {
        return this.totalStatistics
    }
}

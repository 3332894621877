import { RainGaugeRainfall } from "@/model/RainGaugeRainfall"

export class RainGauge {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly stationTypes: string[],
        readonly location: [number, number],
        readonly rainfall: RainGaugeRainfall
    ) {}

    getLngLat(): [number, number] {
        return [this.location[1], this.location[0]]
    }
}

import { DateTime } from 'luxon'

export const STEPS_IN_MINUTES = 5

export class DateTimeFactory {
    /**
     * Maps the {@code datetime} to UTC, then rounds it to the latest 5 minutes {@code luxon.DateTime}
     *
     * @param datetime an optional datetime. If {@code datetime === null} it is replaced by {@code DateTime.now}
     * @return a datetime with 0 second and 0 millisecond, such as his minute is a 5-multiple.
     */
    static utcRounded(datetime: DateTime | null = null): DateTime {
        if (!datetime) {
            datetime = DateTime.now()
        }
        datetime = datetime.toUTC()
        return datetime.set({
            minute: STEPS_IN_MINUTES * Math.floor(datetime.minute / STEPS_IN_MINUTES),
            second: 0,
            millisecond: 0
        })
    }
}

import { DateTime } from 'luxon'

import { MeasureKind } from '@/model/MeasureKind'
import { RainGaugeTimeSeriesDTO } from '@/api/raingauges/dto/RainGaugeTimeSeriesDTO'
import { MeasureDTO } from '@/api/stations/dto/MeasureDTO'
import { MeasureCollection } from '@/model/MeasureCollection'
import { RainGaugeTimeSeries } from '@/model/RainGaugeTimeSeries'

export class RainGaugeTimeSeriesFactory {
    static fromDTO(dto: RainGaugeTimeSeriesDTO): RainGaugeTimeSeries {
        const { rainfall_intensity, rainfall_accumulated } = dto
        const intensityMeasureKind = new MeasureKind(rainfall_intensity.measure_kind_name, rainfall_intensity.measure_kind_unit)
        const accumulatedMeasureKind = new MeasureKind(rainfall_accumulated.measure_kind_name, rainfall_accumulated.measure_kind_unit)

        return new RainGaugeTimeSeries(
            intensityMeasureKind,
            RainGaugeTimeSeriesFactory.mapToRow(rainfall_intensity.timeseries),
            accumulatedMeasureKind,
            RainGaugeTimeSeriesFactory.mapToRow(rainfall_accumulated.timeseries)
        )
    }

    static mapToRow(measureDTOS: MeasureDTO[]): MeasureCollection {
        // TODO remove filter when -1 is gone
        return new MeasureCollection(
            measureDTOS
                .filter(m => m.value > -1)
                .map((m) => [DateTime.fromISO(m.timestamp).toUTC(), m.value])
        )
    }
}

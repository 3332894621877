








import { Vue, Component } from 'vue-property-decorator'

import { City } from '@/model/City'
import { User } from '@/model/User'

import { showToast } from '@/utils/toast'

import { ICityService } from '@/services/city/ICityService'
import { IUserService } from '@/services/user/IUserService'

import UserCreateFormComponent from './UserCreateForm.vue'

@Component({
    components: {
        UserCreateFormComponent
    }
})
export default class UserCreate extends Vue {
    cities: City[] = []

    private cityService!: ICityService
    private userService!: IUserService

    created(): void {
        this.cityService = this.$services.get<ICityService>('city')
        this.userService = this.$services.get<IUserService>('user')

        const cityCollection = this.cityService.getCityCollection()
        this.cities = cityCollection.getCities()
    }

    onCreateUserRequested(newUser: User): void {
        this.userService
            .createUser(newUser)
            .then(() => {
                this.$router.push({ name: 'Users' })
                showToast('is-success', 3000, `${this.$t('dialogs.create_user.success')}`)
            })
            .catch((err) => showToast('is-danger', 3000, `${this.$t('dialogs.create_user.failure')}`))
        this.$router.push({ name: 'Users' })
    }
}

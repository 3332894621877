/**
 * Manage the sorting of the cities by column depending of the window width
 */
export class SorterHelper {
    private cities: any[] = []

    constructor(cities: any[]) {
        this.cities = cities
    }

    /**
     * Sorts the cities by column (by default the cities are sorted by row)
     * @returns {any} cities - city name (string) and is checked (boolean)
     */
    public sortCities(): any[] {
        const sortedCities: any[] = []
        const nbCities = this.cities.length
        const nbFullRows = Math.floor(nbCities / 5)
        const nbCitiesLeft = nbCities % 5
        const nbRows = nbCitiesLeft > 0 ? nbFullRows + 1 : nbFullRows
        const lastLeftCityIndex = this.getLastLeftCityIndex(nbRows, nbCities, nbCitiesLeft)

        if (nbCities > 5) {
            if (nbCitiesLeft == 0) {
                for (let i = 0; i < nbRows; i++) {
                    for (let j = i; j < nbCities; j = j + nbFullRows) {
                        sortedCities.push(this.cities[j])
                    }
                }
            } else {
                for (let i = 0; i < nbRows; i++) {
                    for (let j = i; j < nbCities; j = j + nbFullRows) {
                        // for each city before a left city (i.e. on the incomplete row)
                        if (j != i && j - nbFullRows <= lastLeftCityIndex) {
                            j = j + 1
                        }
                        sortedCities.push(this.cities[j])
                        // for each left city (i.e. on the incomplete row)
                        if (sortedCities.length == nbCities) {
                            j = nbCities
                        }
                    }
                }
            }

            return sortedCities
        }

        return this.cities
    }

    /**
     * Organises the cities into a single column if the window shrinks
     * @param {HTMLElement} element - the cities area
     * @returns {any} cities - city name (string) and is checked (boolean)
     */
    public handleCitySorting(element: HTMLElement): any[] {
        const totalScreenWidthPx = window.screen.width
        const currentScreenWidthPx = window.innerWidth
        const currentScreenWidthPercentage = (100 / totalScreenWidthPx) * currentScreenWidthPx
        let citiesToDisplay = []

        if (element) {
            if (currentScreenWidthPercentage < 60) {
                citiesToDisplay = this.cities
                element.classList.remove('columns')
                element.classList.remove('is-multiline')
            } else {
                citiesToDisplay = this.sortCities()
                element.classList.add('columns')
                element.classList.add('is-multiline')
            }
        }
        return citiesToDisplay
    }

    /**
     * Returns the last city in the incomplete row causing the shift
     * @param {number} nbRows - the total number of rows
     * @param {number} nbCities - the total number of cities to display
     * @param {number} nbCitiesLeft - the number of cities on the incomplete row (if there is)
     * @returns {number} lastLeftCityIndex - the index of the last city on the incomplete row
     */
    private getLastLeftCityIndex(nbRows: number, nbCities: number, nbCitiesLeft: number): number {
        let lastLeftCityIndex = 0
        let leftCityComputed = 0

        for (let i = nbRows - 1; i < nbCities; i = i + nbRows) {
            if (leftCityComputed < nbCitiesLeft) {
                lastLeftCityIndex = i
                leftCityComputed++
            }
        }

        return lastLeftCityIndex
    }
}

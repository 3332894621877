export class UserPasswordUpdate {
    constructor(private currentPassword: string, private newPassword: string, private confirmNewPassword: string) {}

    getCurrentPassword(): string {
        return this.currentPassword
    }

    getNewPassword(): string {
        return this.newPassword
    }

    getConfirmNewPassword(): string {
        return this.confirmNewPassword
    }
}

import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { DisclaimerDTO } from './DisclaimerDTO'
import { DisclaimerRaw } from './DisclaimerRaw'

export class DisclaimerDTOFactory {
    static fromRaw(disclaimerRaw: DisclaimerRaw): DisclaimerDTO {
        const mandatoryFields: any[] = [disclaimerRaw.link, disclaimerRaw.disclaimer]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(DisclaimerDTOFactory.name, disclaimerRaw)
        }

        return new DisclaimerDTO(disclaimerRaw.link, disclaimerRaw.disclaimer)
    }
}

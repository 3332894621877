









import { Mode } from '@/model/Mode'
import { IModeService, ModeChangedEventArgs } from '@/services/mode/IModeService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MainModeSwitch extends Vue {
    @Prop({ type: String, default: 'is-bottom' }) tooltipPosition!: 'is-top' | 'is-bottom'

    private modeService!: IModeService

    private mode: Mode | null = null

    created(): void {
        this.modeService = this.$services.get<IModeService>('mode')
        this.mode = this.modeService.getMainMode()
        this.modeService.modeChanged.subscribe(this.onModeChanged)
    }

    beforeDestroy(): void {
        this.modeService?.modeChanged.unsubscribe(this.onModeChanged)
    }

    get labelForLiveOrFreeButton(): string {
        let result = this.$t('components.commandbar.live')
        if (this.mode === 'free') {
            result = this.$t('components.commandbar.free')
        }
        return result as string
    }

    get classForLiveOrFreeButton(): string {
        return `button ${this.mode === 'live' ? 'is-primary' : 'is-danger'}`
    }

    async onModeChanged(args: ModeChangedEventArgs): Promise<void> {
        if (args.newMainMode === 'live' || args.newMainMode === 'free') {
            this.mode = args.newMainMode
        }
    }

    onLiveOrFreeButtonClicked(): void {
        if (this.mode === 'live') {
            this.mode = 'free'
        } else {
            this.mode = 'live'
        }
        this.modeService?.setMode(this.mode)
    }
}































import { Component, Vue } from 'vue-property-decorator'

import { ITranslationService } from '@/services/translation/ITranslationService'

@Component
export default class Disclaimer extends Vue {
    private translationService!: ITranslationService

    private isComponentModalActive = false
    private disclaimerLink = ''
    private disclaimerText = ''

    created(): void {
        this.translationService = this.$services.get<ITranslationService>('translation')
    }

    async mounted(): Promise<void> {
        const currentLanguageCode = this.translationService.getCurrentLanguageCode()
        if (currentLanguageCode) {
            const disclaimerData = await this.translationService.getDisclaimer(currentLanguageCode)
            this.disclaimerLink = disclaimerData.getLink()
            this.disclaimerText = disclaimerData.getDisclaimer()
        }
    }

    onModalOpened(): void {
        this.isComponentModalActive = true
    }
}

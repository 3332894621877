import { DateTime } from 'luxon'

import { CatchmentFactory } from './CatchmentFactory'
import { ITimeAndCityLocalizable } from '@/model/ITimeAndCityLocalizable'
import { TimeStepMax } from '@/model/TimeStepMax'
import { TimeStepMaxDTO } from '@/api/core/dto/snapshot/TimeStepMaxDTO'

export class TimeStepMaxFactory {
    static createEmpty(snapshot: ITimeAndCityLocalizable, timestamp: DateTime): TimeStepMax {
        return new TimeStepMax(snapshot, [], [])
    }

    static fromDTO(dto: TimeStepMaxDTO, snapshot: ITimeAndCityLocalizable): TimeStepMax {
        return new TimeStepMax(snapshot, dto.administrativeAreas, dto.catchments.map(CatchmentFactory.fromDTO))
    }
}








import { Vue, Component, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { DateTime } from 'luxon'
import axios, { AxiosError } from 'axios'

import { ITranslationService } from '@/services/translation/ITranslationService'
import { IAuthService } from './services/auth/IAuthService'
import { ISettingService } from './services/setting/ISettingService'
import { IUserService } from './services/user/IUserService'
import { IRepresentationService } from './services/representation/IRepresentationService'
import { IModeService } from './services/mode/IModeService'
import { Mode } from './model/Mode'
import { Setting } from './model/Setting'

@Component
export default class App extends Vue {
    private isLoaded = false

    private settingService!: ISettingService
    private userService!: IUserService
    private authService!: IAuthService
    private translationService!: ITranslationService
    private representationService!: IRepresentationService
    private modeService!: IModeService

    created(): void {
        this.settingService = this.$services.get<ISettingService>('setting')
        this.userService = this.$services.get<IUserService>('user')
        this.authService = this.$services.get<IAuthService>('auth')
        this.translationService = this.$services.get<ITranslationService>('translation')
        this.representationService = this.$services.get<IRepresentationService>('representation')
        this.modeService = this.$services.get<IModeService>('mode')

        this.setUpResponseInterceptor()
    }

    async mounted(): Promise<void> {
        const settings = await this.settingService.getSettings()
        await this.setLanguage(settings)
        this.setMode()

        this.representationService.updateLegendFromSettings(settings)
        this.userService?.setLastSelectedEventYear(DateTime.now().year)
        this.isLoaded = true
    }

    setUpResponseInterceptor(): void {
        const authService = this.$services.get<IAuthService>('auth')
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    authService.logout()
                    this.$router.push({
                        name: 'Auth'
                    })
                }
                return Promise.reject(error)
            }
        )
    }

    //NOTE: On hard route reload the method can be called with uninitialized services
    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(route: Route): void {
        if (this.modeService) {
            const routeMode = route.query?.mode as Mode
            if (routeMode) {
                this.modeService.setMode(routeMode)
            }
        }
        if (this.translationService) {
            const languageCode = route.query?.lang as string
            if (languageCode) {
                this.useLanguage(languageCode)
            }
        }
    }

    async setLanguage(settings: Setting): Promise<void> {
        //URL query string has highest priority
        const route = this.$router.currentRoute
        const languageCode = route.query?.lang as string | null

        if (languageCode) {
            await this.useLanguage(languageCode)
            return Promise.resolve()
        }
        //User settings has higher priority
        if (this.authService.isAuthenticated()) {
            const currentUser = await this.userService.getCurrentUser()
            if (currentUser.language && currentUser.language != languageCode) {
                await this.useLanguage(currentUser.language)
                return Promise.resolve()
            }
        }
        //Default language
        const defaultLanguage = settings.getDefaultLanguage() || 'en'
        await this.useLanguage(defaultLanguage)
    }

    async useLanguage(code: string): Promise<void> {
        await this.translationService.useLanguage(code)
    }

    //TODO: check user right on mode
    setMode(): void {
        const route = this.$router.currentRoute
        const mode = route.query?.mode as Mode | null

        if (mode) {
            this.modeService.setMode(mode)
        }
    }
}

import { CityCollectionDTO } from '@/api/core/dto/city/CityCollectionDTO'
import { CityCollection } from '@/model/CityCollection'
import { CityFactory } from './CityFactory'

export class CityCollectionFactory {
    static createEmpty(): CityCollection {
        return new CityCollection([])
    }

    static fromDTO(dto: CityCollectionDTO): CityCollection {
        return new CityCollection(dto.cities.map(CityFactory.fromDTO))
    }
}

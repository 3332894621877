



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { Country } from '@/model/Country'

const NumberRegExp = /^\d{8,14}$/

@Component
export default class PhoneNumberField extends Vue {
    @Prop({ type: String, required: true }) phoneNumber!: string
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
    @Prop({ type: Array, required: true }) readonly allCountries!: Country[]
    @Prop({ type: Boolean, required: true }) readonly triggerClearPhoneField!: boolean

    private countryPart = ''
    private selectedCountry: Country | null = null
    private countryPartType = ''

    private nationalPart = ''
    private nationalPartType = ''

    mounted(): void {
        this.displayPhoneNumber()
    }

    displayPhoneNumber(): void {
        if (this.phoneNumber) {
            let countryFound = this.allCountries.find((c) => this.phoneNumber.includes(c.dialCode))
            if (!countryFound) {
                return
            }
            this.selectedCountry = new Country(
                countryFound.name,
                countryFound.dialCode,
                countryFound.a2Code,
                countryFound.flag
            )
            this.countryPart = `${this.selectedCountry.flag} - ${this.selectedCountry.name} (${this.selectedCountry.dialCode})`
            this.nationalPart = this.phoneNumber.substring(countryFound.dialCode.length)
        }
    }

    filterCountries(): Array<string> {
        if (!this.allCountries) {
            return []
        }
        const trimmed = this.countryPart.trim()
        const filtered = trimmed.length == 0 ? this.allCountries : this.allCountries.filter((c) => c.matches(trimmed))
        return filtered.map((c) => `${c.flag} - ${c.name} (${c.dialCode})`)
    }

    tryEmitPhoneNumberChanged(): void {
        const nationalPart = this.nationalPart.replaceAll(' ', '')
        if (this.selectedCountry != null && nationalPart.length > 0 && this.nationalPartValid) {
            this.$emit('phone-number-changed', `${this.selectedCountry?.dialCode}${nationalPart}`)
        } else if (this.selectedCountry == null || nationalPart.length == 0) {
            this.$emit('phone-number-changed', '')
        }
    }

    private get nationalPartValid(): boolean {
        const asE164 = this.nationalPart.replaceAll(' ', '')
        return asE164[0] !== '0' && NumberRegExp.test(asE164)
    }

    onCountrySelected(option: string): void {
        if (!option) {
            this.selectedCountry = null
            this.countryPartType = 'is-danger'
            return
        }
        const dialCode = option.substring(option.lastIndexOf('(') + 1, option.lastIndexOf(')'))
        const countryFound = this.allCountries.find((c) => c.dialCode === dialCode)
        if (countryFound) {
            this.selectedCountry = countryFound
            this.tryEmitPhoneNumberChanged()
        }
    }

    onClearClick(): void {
        this.nationalPart = ''
    }

    onNationalPartBlurred(): void {
        this.updateFieldTypes()
        this.tryEmitPhoneNumberChanged()
    }

    onCountryPartBlurred(): void {
        this.updateFieldTypes()
        this.tryEmitPhoneNumberChanged()
    }

    @Watch('triggerClearPhoneField')
    clearPhoneNumberFields(): void {
        this.countryPartType = ''
        this.nationalPartType = ''
        this.countryPart = ''
        this.selectedCountry = null
        this.nationalPart = ''
    }

    private updateFieldTypes(): void {
        const nationalPart = this.nationalPart.replaceAll(' ', '')
        this.nationalPartType = nationalPart.length > 0 && this.nationalPartValid ? 'is-success' : 'is-danger'
        this.nationalPartType = nationalPart.length == 0 && this.selectedCountry != null ? 'is-danger' : ''
        this.countryPartType = this.selectedCountry == null && nationalPart.length > 0 ? 'is-danger' : ''
    }
}

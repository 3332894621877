import { DateTime } from 'luxon'

export class MeasureCollection implements Iterable<DateTime> {
    private ts2Values: Map<DateTime, number>
    private readonly sortedTs: Array<DateTime>

    constructor(private timestamps2Values: Iterable<[DateTime, number]>) {
        this.ts2Values = new Map(timestamps2Values)
        this.sortedTs = [...this.ts2Values.keys()].sort(this.compare)
    }

    [Symbol.iterator](): Iterator<DateTime, any, undefined> {
        return this.sortedTs.values()
    }

    getValue(dt: DateTime): number {
        const dtAsUtc = dt.toUTC()
        if (this.ts2Values.has(dtAsUtc)) {
            return this.ts2Values.get(dtAsUtc) as number
        } else {
            throw new Error('MeasureCollection.getValue no value found for ' + dtAsUtc.toISO())
        }
    }

    getValues(): number[] {
        return [...this.sortedTs].map((ts) => this.getValue(ts))
    }

    private compare(dt1: DateTime, dt2: DateTime): number {
        return dt1.toSeconds() - dt2.toSeconds()
    }

    getFirstTimestampOr(defaultValue: DateTime): DateTime {
        if (this.sortedTs.length === 0) {
            return defaultValue
        } else {
            return this.sortedTs[0]
        }
    }

    getLastTimestampOr(defaultValue: DateTime): DateTime {
        if (this.sortedTs.length === 0) {
            return defaultValue
        } else {
            return this.sortedTs[this.sortedTs.length - 1]
        }
    }

    getLastTimestamp(): DateTime {
        return this.getLastTimestampOr(DateTime.now().set({ second: 0, millisecond: 0 }))
    }

    getLastValue(): number {
        return this.getValue(this.getLastTimestamp())
    }

    get length(): number {
        return this.sortedTs.length
    }
}

import { DateTime, Duration, Interval } from 'luxon'

import { City } from './City'
import { ITimeAndCityLocalizable } from './ITimeAndCityLocalizable'
import { TimeStep } from './TimeStep'

const DEFAULT_RADIUS_HOURS = 6

export class Snapshot implements ITimeAndCityLocalizable, Iterable<TimeStep> {
    public readonly city: City
    public readonly timestamp: DateTime

    private history: Array<TimeStep> = []
    private reference?: TimeStep
    private forecasts: Array<TimeStep> = []
    private max?: TimeStep
    private period: [DateTime, DateTime]

    constructor(city: City, timestamp: DateTime) {
        this.city = city
        this.timestamp = timestamp.setZone(city.getTimezone())
        this.period = [this.timestamp, this.timestamp]
    }

    setReference(timeStep: TimeStep): void {
        this.reference = timeStep
        this.updatePeriod(timeStep.timestamp)
    }

    addHistory(timeStep: TimeStep): void {
        this.history.push(timeStep)
        this.updatePeriod(timeStep.timestamp)
    }

    addForecast(timeStep: TimeStep): void {
        this.forecasts.push(timeStep)
        this.updatePeriod(timeStep.timestamp)
    }

    private updatePeriod(timestamp: DateTime) {
        const tsSeconds = timestamp.toUTC().toSeconds()
        if (tsSeconds < this.period[0].toUTC().toSeconds()) {
            this.period[0] = timestamp
        }
        if (tsSeconds > this.period[1].toUTC().toSeconds()) {
            this.period[1] = timestamp
        }
    }

    setMax(timeStep: TimeStep): void {
        this.max = timeStep
    }

    getReference(): TimeStep {
        return this.reference as TimeStep
    }

    getHistory(): TimeStep[] {
        return this.history
    }

    getForecasts(): TimeStep[] {
        return this.forecasts
    }

    getStart(): DateTime {
        const defaultStart = this.timestamp.minus({ hours: DEFAULT_RADIUS_HOURS })
        const durationBefore = Interval.fromDateTimes(this.period[0], this.timestamp).toDuration(['hours'])
        return durationBefore.get('hour') > DEFAULT_RADIUS_HOURS ? this.period[0] : defaultStart
    }

    getEnd(): DateTime {
        const defaultEnd = this.timestamp.plus({ hours: DEFAULT_RADIUS_HOURS })
        const durationAfter = Interval.fromDateTimes(this.timestamp, this.period[1]).toDuration(['hours'])
        return durationAfter.get('hour') > DEFAULT_RADIUS_HOURS ? this.period[1] : defaultEnd
    }

    getDurationBefore(): Duration {
        return Interval.fromDateTimes(this.getStart(), this.timestamp).toDuration(['hours'])
    }

    getDurationAfter(): Duration {
        return Interval.fromDateTimes(this.timestamp, this.getEnd()).toDuration(['hours'])
    }

    getMax(): TimeStep | null {
        return this.max ? this.max : null
    }

    isEmpty(): boolean {
        for (const timeStep of this.toArray()) {
            if (timeStep && !timeStep.isEmpty()) {
                return false
            }
        }
        return true
    }

    findTimeStepAt(timestamp: DateTime): TimeStep | null {
        const lookup = timestamp.toUTC().toSeconds()
        const timeSteps = this.toArray()
        const candidate = timeSteps.find((timeStep: TimeStep) => timeStep.timestamp.toUTC().toSeconds() === lookup)

        return candidate ?? null
    }

    nextTimeStep(timestamp: DateTime | null): TimeStep | null {
        if (timestamp == null) return null

        const lookup = timestamp.toUTC().toSeconds()
        const timeSteps = this.toArray()
        const candidate = timeSteps.find((timeStep: TimeStep, index) =>
            index > 0
                ? timeSteps[index - 1].timestamp.toUTC().toSeconds() === lookup
                : timeSteps[timeSteps.length - 1].timestamp.toUTC().toSeconds() === lookup
        )

        return candidate ?? null
    }

    *[Symbol.iterator](): Iterator<TimeStep> {
        for (const ts of this.toArray()) {
            yield ts
        }
    }

    private toArray(): Array<TimeStep> {
        const timeSteps = [...this.history]
        if (this.reference) {
            timeSteps.push(this.reference)
        }
        timeSteps.push(...this.forecasts)

        return timeSteps
    }
}

import { render, staticRenderFns } from "./GeneralForm.vue?vue&type=template&id=a3724c20&scoped=true&"
import script from "./GeneralForm.vue?vue&type=script&lang=ts&"
export * from "./GeneralForm.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralForm.vue?vue&type=style&index=0&id=a3724c20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3724c20",
  null
  
)

export default component.exports
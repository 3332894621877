
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { CityCollection } from '@/model/CityCollection'
import { Route } from 'vue-router'

@Component({
    components: {}
})
export default class CitiesSelector extends Vue {
    @Prop({ type: CityCollection, default: () => new CityCollection([]) })
    cityCollection?: CityCollection

    selection: string | null = null

    mounted(): void {
        this.updateCurrentCity()
    }

    get filteredCities(): City[] {
        if (!this.cityCollection) {
            return []
        }

        if (!this.selection) {
            return this.cityCollection.getCities()
        }

        return this.cityCollection?.getCities().filter((option: City) => {
            return option
                .getName()
                .toString()
                .toLowerCase()
                .startsWith((this.selection as string).toLowerCase())
        })
    }

    updateCurrentCity(): void {
        const cityId = this.$route.params['cityId']
        if (!cityId) {
            return
        }
        this.selection = cityId
    }

    onCitySelected(city: City | null): void {
        if (!city) {
            return
        }

        const cityId = city.getName()
        const parameters = this.$router.currentRoute.params
        if (parameters['cityId'] === cityId) {
            return
        }

        parameters['cityId'] = cityId
        this.$router.push({
            name: this.$router.currentRoute.name as string,
            params: parameters,
            query: this.$router.currentRoute.query
        })
    }

    validateSelection(): void {
        const city = this.cityCollection?.getByName(this.selection as string)
        if (!city) {
            return
        }

        const autoCompleteInput = this.$refs.autoCompleteInput as unknown as { setSelected(city: City): void }
        autoCompleteInput.setSelected(city)

        this.selection = city.getName()
    }

    @Watch('$route')
    onRouteChanged(route: Route): void {
        this.updateCurrentCity()
    }
}

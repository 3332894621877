import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { makeURL } from '../config'
import { ApiError } from '../errors/ApiError'
import { ServerError } from '../errors/ServerError'
import { RainGaugeDTO } from './dto/RainGaugeDTO'
import { RainGaugeTimeSeriesDTO } from './dto/RainGaugeTimeSeriesDTO'

export class RainGaugeAPI {
    private routes = {
        getRainGauges: makeURL('/api/raingauges/${latitude}/${longitude}/${radius}'),
        getRainGaugesWithRainfall: makeURL('/api/raingauges/${latitude}/${longitude}/${radius}/rainfall?timestamp=${timestamp}'),
        getTimeSeries: makeURL('/api/raingauges/${rain_gauge_id}/timeseries/${start}/${end}'),
        getForecasts: makeURL('/api/raingauges/${rain_gauge_id}/timeseries/${timestamp}')
    }

    async getRainGauges(lat: number, longitude: number, radius = 0.000009): Promise<RainGaugeDTO[]> {
        try {
            const url = this.routes.getRainGauges
                .replace('${latitude}', lat.toString())
                .replace('${longitude}', longitude.toString())
                .replace('${radius}', radius.toString())
            const response = await axios.get(url)
            return response.data as RainGaugeDTO[]
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async GetRainGaugesWithRainfall(lat: number, longitude: number, timestamp: DateTime, radius = 0.000009): Promise<RainGaugeDTO[]> {
        try {
            const url = this.routes.getRainGaugesWithRainfall
                .replace('${latitude}', lat.toString())
                .replace('${longitude}', longitude.toString())
                .replace('${radius}', radius.toString())
                .replace('${timestamp}', timestamp.toISO())
            const response = await axios.get(url)
            return response.data as RainGaugeDTO[]
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getTimeseriesForRainGaugeByTimestamp(
        rainGaugeId: string,
        utcTimestamp: DateTime
    ): Promise<RainGaugeTimeSeriesDTO> {
        try {
            const url = this.routes.getForecasts
                .replace('${rain_gauge_id}', rainGaugeId)
                .replace('${timestamp}', utcTimestamp.toISO())
            const response = await axios.get(url)
            return response.data as RainGaugeTimeSeriesDTO
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getTimeSeriesForRainGaugeByPeriod(
        rainGaugeId: string,
        utcStart: DateTime,
        utcEnd: DateTime
    ): Promise<RainGaugeTimeSeriesDTO> {
        try {
            const url = this.routes.getTimeSeries
                .replace('${rain_gauge_id}', rainGaugeId)
                .replace('${start}', utcStart.toISO())
                .replace('${end}', utcEnd.toISO())
            const response = await axios.get(url)
            return response.data as RainGaugeTimeSeriesDTO
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}

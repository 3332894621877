




import { Component, Vue } from 'vue-property-decorator'

import { ICityService } from '@/services/city/ICityService'

@Component({
    components: {}
})
export default class Home extends Vue {
    private cityService: ICityService | null = null

    created(): void {
        this.cityService = this.$services.get<ICityService>('city')
    }

    mounted(): void {
        const cityCollection = this.cityService?.getCityCollection()
        if (cityCollection?.hasCities()) {
            const city = cityCollection.get(0)
            this.$router.push({
                name: 'Overview',
                params: {
                    cityId: city.getName()
                },
                query: {
                    mode: 'live'
                }
            })
        }
    }

    get hasCities(): boolean {
        const result = this.cityService?.getCityCollection().hasCities()
        return result ? result : false
    }
}

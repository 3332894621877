










import { Component, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component({
    components: {}
})
export default class Logo extends Vue {
    get getRoute(): RawLocation {
        const cityId = this.$route.params['cityId']
        if (!cityId) {
            return { name: 'Home' }
        }
        return {
            name: 'Overview',
            params: {
                cityId: cityId
            },
            query: {
                mode: 'live'
                // snapshotTimestamp: this.$route.query['snapshotTimestamp'] ? this.$route.query['snapshotTimestamp'] : '',
                // timeStepTimestamp: this.$route.query['timeStepTimestamp'] ? this.$route.query['timeStepTimestamp'] : ''
            }
        }
    }
}

import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { CatchmentDTOFactory } from './CatchmentDTOFactory'
import { TimeStepDTO } from './TimeStepDTO'
import { TimeStepRaw } from './TimeStepRaw'
import { AdministrativeAreaDTOFactory } from './AdministrativeAreaDTOFactory'

export class TimeStepDTOFactory {
    static fromRaw(raw: TimeStepRaw): TimeStepDTO {
        const timestamp = raw.timestamp
        const administrativeAreas = raw.administrative_areas
        const catchments = raw.catchments
        const maxFloodLevel = raw.max_flood_level
        const maxIntensity = raw.max_intensity
        const maxRisk = raw.max_risk
        const maxRt = raw.max_rt
        const maxTa = raw.max_ta

        const mandatoryFields = [
            timestamp,
            administrativeAreas,
            catchments,
            maxFloodLevel,
            maxIntensity,
            maxRisk,
            maxRt,
            maxTa
        ]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(TimeStepDTOFactory.name, raw)
        }

        return new TimeStepDTO(
            timestamp as string,
            administrativeAreas.map(AdministrativeAreaDTOFactory.fromRaw),
            catchments.map(CatchmentDTOFactory.fromRaw),
            maxFloodLevel as number,
            maxIntensity as number,
            maxRisk as number,
            maxRt as number,
            maxTa as number
        )
    }
}

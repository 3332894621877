import { makeURL } from '@/api/config'
import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { CityDTO } from './CityDTO'
import { CityRaw } from './CityRaw'

export class CityDTOFactory {
    static fromRaw(raw: CityRaw): CityDTO {
        const name = raw.name
        const latitude = raw.latitude
        const longitude = raw.longitude
        const timezone = raw.timezone
        const zoomLevel = raw.zoom_level
        const contoursUrl = raw.city_contour
        const administrativeAreas = raw.administrative_areas
        const administrativeAreasContoursUrl = raw.administrative_areas_contours
        const catchments = raw.catchments

        const mandatoryFields = [
            name,
            latitude,
            longitude,
            timezone,
            zoomLevel,
            administrativeAreas,
            administrativeAreasContoursUrl,
            catchments
        ]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(CityDTOFactory.name, raw)
        }

        const city = new CityDTO(
            name as string,
            latitude as number,
            longitude as number,
            timezone as string,
            zoomLevel as number,
            contoursUrl ? makeURL(contoursUrl) : null,
            administrativeAreas as string[],
            makeURL(administrativeAreasContoursUrl as string),
            catchments as string[]
        )
        return city
    }
}

import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import { serviceLocatorInstance } from '@/services/ServiceLocator'
import { User } from '@/model/User'
import { IUserService } from '@/services/user/IUserService'

const isAdminGuard: NavigationGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
    console.info('In isAdminGuard')
    const userService = serviceLocatorInstance.get<IUserService>('user')
    const currentUser: User = await userService.getCurrentUser()

    if (!currentUser.isAdmin) {
        console.warn('Access denied to non admin user')
        return
    }
    next()
}

export default isAdminGuard

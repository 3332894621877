import { DateTime } from 'luxon'

import { CoreAPI } from '@/api/core/CoreAPI'
import { City } from '@/model/City'
import { Snapshot } from '@/model/Snapshot'
import { ISnapshotService } from '@/services/snapshot/ISnapshotService'
import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { SnapshotFactory } from './SnapshotFactory'

export class SnapshotService extends BaseService implements ISnapshotService {
    private readonly api: CoreAPI = new CoreAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    async loadSnapshot(city: City, timestamp: DateTime): Promise<Snapshot> {
        let dto = null
        try {
            dto = await this.api.getSnapshot(city.getName(), timestamp)
        } catch (exception) {
            console.error(exception)
        }
        return dto ? SnapshotFactory.fromDTO(dto, city) : SnapshotFactory.createEmpty(city, timestamp)
    }
}

export class UserAlarmsUpdate {
    constructor(
        private citiesAlarms: Array<string>,
        private email: string,
        private emailAlarms: boolean,
        private phone: string,
        private smsAlarms: boolean
    ) {}

    getCitiesAlarms(): Array<string> {
        return this.citiesAlarms
    }

    getEmail(): string {
        return this.email
    }

    getEmailAlarms(): boolean {
        return this.emailAlarms
    }

    getPhone(): string {
        return this.phone
    }

    getSmsAlarms(): boolean {
        return this.smsAlarms
    }
}

import { UserCollectionDTO } from '@/api/user/dto/UserCollectionDTO'
import { CityCollection } from '@/model/CityCollection'
import { UserCollection } from '@/model/UserCollection'
import { UserFactory } from './UserFactory'

export class UserCollectionFactory {
    static fromDTO(dto: UserCollectionDTO, cityCollection: CityCollection): UserCollection {
        return new UserCollection(
            dto.users.map((userDTO) => UserFactory.fromUserDTO(userDTO, cityCollection)),
            dto.totalUsers
        )
    }
}

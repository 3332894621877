import { DateTime } from 'luxon'

import { EventDTO } from '@/api/event/dto/events/EventDTO'

import { Event } from '@/model/Event'

export class EventFactory {
    static fromDTO(dto: EventDTO): Event {
        return new Event(
            dto.cityName,
            DateTime.fromISO(dto.startDate, { zone: 'UTC' }),
            DateTime.fromISO(dto.endDate, { zone: 'UTC' }),
            DateTime.fromISO(dto.maxTTime, { zone: 'UTC' }),
            dto.maxTGeojson,
            dto.maxT,
            dto.max1hourVol,
            dto.max1dayVol,
            dto.areaT5Plus
        )
    }
}














































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import { User } from '@/model/User'
import { IUserService } from '@/services/user/IUserService'
import { showToast } from '@/utils/toast'

@Component({
    components: {}
})
export default class Users extends Vue {
    private userService: IUserService | null = null

    private users: User[] | null = []
    private totalUsers: number | null = 0

    private isLoading = false
    private noUsers = true
    private allUsersOnPage = false
    private usersPerPage = 10
    private currentPage = 1
    private sortDirection = 'asc'

    created(): void {
        this.userService = this.$services.get<IUserService>('user')
    }

    async mounted(): Promise<void> {
        this.loadUsers()
    }

    async loadUsers(): Promise<void> {
        this.isLoading = true
        const sortDirectionRequest = this.sortDirection === 'asc' ? true : false
        const users = await this.userService?.getUsers(this.currentPage, this.usersPerPage, sortDirectionRequest)

        if (!users) {
            this.noUsers = true
            this.isLoading = false
            return
        }

        this.users = users.getUsers()
        this.totalUsers = users.getTotalUsers()
        this.noUsers = this.totalUsers == 0 ? true : false
        this.usersPerPage = this.resetNumberOfUsersPerPage()
        this.isLoading = false
    }

    confirmDeleteUser(username: string): void {
        this.$buefy.dialog.confirm({
            title: this.$t('dialogs.delete_user.title').toString(),
            message: this.$t('dialogs.delete_user.message', { user: username }).toString(),
            confirmText: this.$t('dialogs.delete_user.confirm').toString(),
            cancelText: this.$t('common.cancel').toString(),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteUser(username)
        })
    }

    async deleteUser(username: string): Promise<void> {
        try {
            await this.userService?.deleteUser(username)
            this.currentPage = 1
            this.loadUsers()
            showToast('is-success', 3000, `${this.$t('dialogs.delete_user.success')}`)
        } catch (e) {
            showToast('is-danger', 3000, `${this.$t('dialogs.delete_user.failure')}`)
        }
    }

    saveSortDirection(column: string, sort: string): void {
        /**
         * Currently, the only column that is sorted is the start date.
         */
        switch (column) {
            case 'username':
                this.sortDirection = sort
                break
            default:
                break
        }
    }

    formatPermission(permission: string): string {
        return (permission.charAt(0) + permission.slice(1).toLowerCase()).replace('_', ' ')
    }

    resetNumberOfUsersPerPage(): number {
        return this.allUsersOnPage && this.noUsers ? 10 : this.usersPerPage
    }

    get usersPerPageOptions(): number[] {
        const eventsPerPageOptions: number[] = []
        for (let i = 5; i <= 20; i += 5) {
            if (this.totalUsers && i < this.totalUsers) {
                eventsPerPageOptions.push(i)
            }
        }
        if (this.totalUsers) {
            eventsPerPageOptions.push(this.totalUsers)

            if (eventsPerPageOptions.length == 1) {
                this.usersPerPage = this.totalUsers
            }
        }
        return eventsPerPageOptions
    }

    @Watch('usersPerPage')
    onUsersPerPageChanged(): void {
        this.allUsersOnPage = this.usersPerPage === this.totalUsers ? true : false
        this.currentPage = 1
        this.loadUsers()
    }

    @Watch('currentPage')
    onPageChanged(): void {
        this.loadUsers()
    }

    @Watch('sortDirection')
    onSortDirectionChanged(): void {
        this.currentPage = 1
        this.loadUsers()
    }
}

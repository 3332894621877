import { DateTime, Duration } from 'luxon'

import { TimeStep } from './TimeStep'

export class TimeStepSequence {
    private timesteps: Map<number, TimeStep> = new Map()
    private readonly durationInSecs: number

    static fromIterable(iterable: Iterable<TimeStep>, start: DateTime, end: DateTime): TimeStepSequence {
        return new TimeStepSequence(iterable, start, end)
    }

    private constructor(timeSteps: Iterable<TimeStep>, readonly start: DateTime, readonly end: DateTime) {
        this.durationInSecs = this.getSecondsBetween(this.start, this.end)
        for (const ts of timeSteps) {
            this.put(ts)
        }
    }

    private put(ts: TimeStep): void {
        this.timesteps.set(this.getSecondsBetween(this.start, ts.timestamp) / this.durationInSecs, ts)
    }

    private getSecondsBetween(dt1: DateTime, dt2: DateTime): number {
        return Math.abs(dt1.toSeconds() - dt2.toSeconds())
    }

    getFromRelative(x: number): TimeStep | undefined {
        const nearest = this.timesteps.has(x) ? x : this.getNearestRelative(x)

        return isNaN(nearest) ? undefined : this.timesteps.get(nearest)
    }

    getNearestRelative(x: number): number {
        let nearest = NaN
        for (const c of this.timesteps.keys()) {
            if (isNaN(nearest) || Math.abs(c - x) <= Math.abs(nearest - x)) {
                nearest = c
            }
        }
        return nearest
    }

    getDateTimeAt(relativePos: number): DateTime {
        return this.start.plus(Duration.fromMillis(relativePos * this.durationInSecs * 1000))
    }

    get hasTimeSteps(): boolean {
        return this.timesteps.size > 1
    }

    get entries(): [number, TimeStep][] {
        return [...this.timesteps.entries()]
    }

    get firstRelative(): number {
        return this.getNearestRelative(this.toRelative(this.start))
    }

    get lastRelative(): number {
        return this.getNearestRelative(this.toRelative(this.end))
    }

    getRelativePosOf(ts: TimeStep): number {
        return this.toRelative(ts.timestamp)
    }

    toRelative(dt: DateTime): number {
        return this.getSecondsBetween(this.start, dt) / this.durationInSecs
    }
}

import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'

import Auth from '@/views/auth/Auth.vue'
import PasswordForgotten from '@/views/passwordRecovery/PasswordForgotten.vue'
import ResetPassword from '@/views/passwordRecovery/ResetPassword.vue'
import Secure from '@/views/secure/Secure.vue'
import Home from '@/views/secure/Home.vue'
import Overview from '@/views/secure/overview/Overview.vue'
import History from '@/views/secure/history/History.vue'
import EventList from '@/views/secure/event/event-list/EventList.vue'
import EventStats from '@/views/secure/event/event-stats/EventStats.vue'

import Users from '@/views/secure/admin/user/Users.vue'
import UserCreate from '@/views/secure/admin/user/UserCreate.vue'
import UserUpdate from '@/views/secure/admin/user/UserUpdate.vue'
import UserSettings from '@/views/secure/admin/settings/UserSettings.vue'
import authenticationGuard from './guards/AuthenticationGuard'
import isAdminGuard from './guards/IsAdminGuard'
import isNotLiveOnlyGuard from './guards/IsNotLiveOnlyGuard'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        props: true
    },
    {
        path: '/auth/password-forgotten',
        name: 'PasswordForgotten',
        component: PasswordForgotten
    },
    {
        path: '/auth/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        props: (route) => ({
            token: route.query.token
        })
    },
    {
        path: '/',
        name: 'Secure',
        redirect: { name: 'Home' },
        component: Secure,
        beforeEnter: authenticationGuard,
        props: (route: Route) => ({
            cityId: route.meta?.cityId
        }),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home
            },
            {
                path: '/overview/:cityId',
                name: 'Overview',
                component: Overview,
                props: (route) => ({
                    cityId: route.params.cityId,
                    mode: route.query.mode,
                    snapshotTimestamp: route.query.snapshotTimestamp,
                    timeStepTimestamp: route.query.timeStepTimestamp
                })
            },
            {
                path: '/history/:cityId',
                name: 'History',
                component: History,
                props: (route) => ({
                    cityId: route.params.cityId,
                    start: route.query.start,
                    end: route.query.end
                })
            },
            {
                path: '/events/:cityId',
                name: 'Events',
                beforeEnter: isNotLiveOnlyGuard,
                component: EventList,
                props: (route) => ({
                    cityId: route.params.cityId
                })
            },
            {
                path: '/events/:cityId/stats',
                name: 'EventsStats',
                beforeEnter: isNotLiveOnlyGuard,
                component: EventStats,
                props: (route) => ({
                    cityId: route.params.cityId,
                    start: route.query.start,
                    end: route.query.end
                })
            },
            {
                path: '/admin/user/users',
                name: 'Users',
                beforeEnter: isAdminGuard,
                component: Users
            },
            {
                path: '/admin/user/create',
                name: 'UserCreate',
                beforeEnter: isAdminGuard,
                component: UserCreate
            },
            {
                path: '/admin/user/update',
                name: 'UserUpdate',
                component: UserUpdate,
                beforeEnter: isAdminGuard,
                props: (route) => ({
                    username: route.query.username
                })
            },
            {
                path: '/admin/user/settings',
                name: 'UserSettings',
                component: UserSettings
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

export default router

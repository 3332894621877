import { makeURL } from '@/api/config'
import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { LevelOfDetailDTO } from './LevelOfDetailDTO'
import { LevelOfDetailRaw } from './LevelOfDetailRaw'

export class LevelOfDetailDTOFactory {
    static fromRaw(raw: LevelOfDetailRaw): LevelOfDetailDTO {
        const scale = raw.scale
        const geoJsonUrl = raw.url

        const mandatoryFields = [scale, geoJsonUrl]
        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(LevelOfDetailDTOFactory.name, raw)
        }

        return new LevelOfDetailDTO(scale as number, makeURL(geoJsonUrl as string))
    }
}

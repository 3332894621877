export class UserCreateDTO {
    constructor(
        readonly username: string,
        readonly password: string,
        readonly rights: string,
        readonly flood: string,
        readonly admin: string,
        readonly cities: string[],
        readonly email: string = '' // ! Important : should disappear from the API ?
    ) {}
}

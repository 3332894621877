
































import { ITimeAndCityLocalizable } from '@/model/ITimeAndCityLocalizable'
import { Station } from '@/model/Station'
import { IStationService, StationSelectionEventArgs } from '@/services/station/IStationService'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import StationChart from '@/components/station/StationChart.vue'
import { DateTime, Interval } from 'luxon'

@Component({
    components: {
        StationChart
    }
})
export default class StationsTimeSeries extends Vue {
    @Prop({ type: String, default: 'timestamp' }) mode!: string
    @Prop() snapshot!: ITimeAndCityLocalizable
    private snapshotTimestamp!: DateTime
    private stationService!: IStationService
    private stations: Station[] = []
    private open = false

    created(): void {
        this.stationService = this.$services.get<IStationService>('stations')
        this.stationService.stationSelectionChanged.subscribe(this.stationSelectionChanged)
    }

    mounted(): void {
        this.snapshotTimestamp = this.snapshot?.timestamp ?? DateTime.fromSeconds(0)
    }

    beforeDestroy(): void {
        this.stationService.stationSelectionChanged.unsubscribe(this.stationSelectionChanged)
    }

    async stationSelectionChanged(eventArgs: StationSelectionEventArgs): Promise<void> {
        if ('add' === eventArgs.action) {
            this.stations = [...this.stations, ...eventArgs.stations]
        } else if ('remove' === eventArgs.action) {
            eventArgs.stations.forEach((s) => {
                const index = this.stations.findIndex((sc) => sc.no === s.no)
                this.stations.splice(index, 1)
            })
            this.stations = [...this.stations]
        }
    }

    @Watch('stations')
    onStationsChanged(): void {
        this.open = (this.stations?.length ?? 0) > 0
    }

    @Watch('snapshot')
    onSnapshotChanged(): void {
        if (!this.snapshot) return

        if (this.snapshotTimestamp.toSeconds() != this.snapshot.timestamp.toSeconds()) {
            //Reload observed stations to update charts
            this.snapshotTimestamp = this.snapshot.timestamp

            const temp = [...this.stations]
            this.stationService.clearStations()
            window.setTimeout(() => {
                for (const s of temp) {
                    this.stationService.toggleStation(s)
                }
            }, 33)
        }
    }

    onCloseRequested(): void {
        this.stationService.clearStations()
    }

    get timestamp(): DateTime {
        return this.snapshot.timestamp
    }

    get timeperiod(): Interval {
        return Interval.fromDateTimes(this.snapshot.getStart(), this.snapshot.getEnd())
    }
}

import axios from 'axios'
import { GeoJSON, FeatureCollection } from 'geojson'

import { CoreAPI } from '@/api/core/CoreAPI'
import { City } from '@/model/City'
import { TimeStep } from '@/model/TimeStep'
import { BaseService } from '../BaseService'
import { IMapService } from './IMapService'
import { GeoJsonCache } from './cache/GeoJsonCache'
import { Catchment } from '@/model/Catchment'
import { MapBoxGL } from '@/views/secure/overview/map/MapBoxGL'
import { Point } from '@turf/turf'
import { ServiceLocator } from '../ServiceLocator'
import { ISettingService } from '../setting/ISettingService'

export class MapService extends BaseService implements IMapService {
    private readonly api: CoreAPI = new CoreAPI()
    private readonly arbitraryCache = new GeoJsonCache()

    private readonly emptyGeoJson: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    }

    private settingService!: ISettingService
    private cellSize: number | null = null

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
        this.settingService = serviceLocator.get<ISettingService>('setting')
    }

    private async getCellSize(): Promise<number> {
        if (this.cellSize == null) {
            const settings = await this.settingService.getSettings()
            this.cellSize = settings.getCellSize()
        }

        return this.cellSize
    }

    async fetchRainfall(city: City, timeStep: TimeStep): Promise<GeoJSON> {
        let geoJson = null
        if (timeStep.isForecast()) {
            geoJson = await this.api.getRainfallForecastMap(
                city.getName(),
                timeStep.snapshot.timestamp.toUTC(),
                timeStep.timestamp.toUTC()
            )
        } else if (timeStep.isMax()) {
            geoJson = await this.api.getRainfallMaxMap(city.getName(), timeStep.timestamp.toUTC())
        } else {
            geoJson = await this.api.getRainfallMap(city.getName(), timeStep.timestamp.toUTC())
        }

        return geoJson ? await this.convertPointToPolygon(geoJson as FeatureCollection) : this.emptyGeoJson
    }

    async fetchReturnPeriod(city: City, timeStep: TimeStep): Promise<GeoJSON> {
        let geoJson = null
        if (timeStep.isForecast()) {
            geoJson = await this.api.getReturnPeriodForecastMap(
                city.getName(),
                timeStep.snapshot.timestamp.toUTC(),
                timeStep.timestamp.toUTC()
            )
        } else if (timeStep.isMax()) {
            geoJson = await this.api.getReturnPeriodMaxMap(city.getName(), timeStep.timestamp.toUTC())
        } else {
            geoJson = await this.api.getReturnPeriodMap(city.getName(), timeStep.timestamp.toUTC())
        }

        return geoJson ? await this.convertPointToPolygon(geoJson as FeatureCollection) : this.emptyGeoJson
    }

    async fetchFloodDepth(city: City, timeStep: TimeStep, scale: number): Promise<GeoJSON> {
        const geoJsonUrls = timeStep.catchments.map((catchment: Catchment) => catchment.getForScale(scale))
        const geoJsons = await Promise.all(
            geoJsonUrls.map(async (url: string | null): Promise<FeatureCollection> => {
                if (!url) {
                    return this.emptyGeoJson
                }

                let geoJson = this.arbitraryCache.retrieve(url)
                if (geoJson) {
                    return geoJson
                }

                try {
                    const response = await axios.get(url)
                    geoJson = response.data
                } catch (exception) {
                    console.error(exception)
                }

                if (!geoJson) {
                    return this.emptyGeoJson
                }

                geoJson = await this.convertPointToPolygon(geoJson, scale)
                this.arbitraryCache.store(url, geoJson)

                return geoJson
            })
        )

        const result = { ...this.emptyGeoJson }
        for (const geoJson of geoJsons) {
            result.features = result.features.concat(geoJson.features)
        }

        return result
    }

    async convertPointToPolygon(geoJson: FeatureCollection, scale: number | null = null): Promise<FeatureCollection> {
        if (scale == null) {
            scale = await this.getCellSize()
        }
        for (const feature of geoJson.features) {
            feature.geometry = MapBoxGL.convertPointToPolygon(feature.geometry as Point, scale)
        }
        return geoJson
    }
}

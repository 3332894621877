import axios, { AxiosError } from 'axios'
import { GeoJSON } from 'geojson'
import { DateTime } from 'luxon'

import { makeURL } from '@/api/config'
import { ServerError } from '@/api/errors/ServerError'

import { CityCollectionDTO } from './dto/city/CityCollectionDTO'
import { CityCollectionDTOFactory } from './dto/city/CityCollectionDTOFactory'
import { CityCollectionRaw } from './dto/city/CityCollectionRaw'
import { SnapshotDTO } from './dto/snapshot/SnapshotDTO'
import { SnapshotDTOFactory } from './dto/snapshot/SnapshotDTOFactory'
import { SnapshotRaw } from './dto/snapshot/SnapshotRaw'
import { HistoryDTO } from './dto/history/HistoryDTO'
import { HistoryDTOFactory } from './dto/history/HistoryDTOFactory'
import { HistoryRaw } from './dto/history/HistoryRaw'

export class CoreAPI {
    private routes = {
        getCities: makeURL('/api/cities'),
        getSnapshot: makeURL('/api/cities/${cityName}/timestep/${referenceTimestamp}'),
        getHistory: makeURL('/api/cities/${cityName}/history/${isoStart}/${isoEnd}'),
        getRainfallMap: makeURL('/api/maps/${cityName}/rainfall/${referenceTimestamp}'),
        getRainfallForecastMap: makeURL('/api/maps/${cityName}/rainfall/${referenceTimestamp}/${forecastTimestamp}'),
        getRainfallMaxMap: makeURL('/api/maps/${cityName}/max_rainfall/${referenceTimestamp}'),
        getReturnPeriodMap: makeURL('/api/maps/${cityName}/rt/${referenceTimestamp}'),
        getReturnPeriodForecastMap: makeURL('/api/maps/${cityName}/rt/${referenceTimestamp}/${forecastTimestamp}'),
        getReturnPeriodMaxMap: makeURL('/api/maps/${cityName}/max_rt/${referenceTimestamp}')
    }

    public async getCities(): Promise<CityCollectionDTO | null> {
        let result = null

        const url = this.routes.getCities
        try {
            result = await axios.get(this.routes.getCities)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return CityCollectionDTOFactory.fromRaw(result.data as unknown as CityCollectionRaw)
    }

    public async getSnapshot(cityName: string, referenceTimestamp: DateTime): Promise<SnapshotDTO | null> {
        let result = null

        let url = this.routes.getSnapshot
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return SnapshotDTOFactory.fromRaw(result.data as unknown as SnapshotRaw)
    }

    public async getHistory(cityName: string, start: DateTime, end: DateTime): Promise<HistoryDTO | null> {
        let result = null

        const url = this.routes.getHistory
            .replace('${cityName}', cityName)
            .replace('${isoStart}', start.toISO())
            .replace('${isoEnd}', end.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return HistoryDTOFactory.fromRaw(result.data as unknown as HistoryRaw)
    }

    public async getRainfallMap(cityName: string, referenceTimestamp: DateTime): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getRainfallMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    public async getRainfallForecastMap(
        cityName: string,
        referenceTimestamp: DateTime,
        forecastTimestamp: DateTime
    ): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getRainfallForecastMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())
        url = url.replace('${forecastTimestamp}', forecastTimestamp ? forecastTimestamp.toISO() : '')

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    public async getRainfallMaxMap(cityName: string, referenceTimestamp: DateTime): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getRainfallMaxMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    public async getReturnPeriodMap(cityName: string, referenceTimestamp: DateTime): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getReturnPeriodMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    public async getReturnPeriodForecastMap(
        cityName: string,
        referenceTimestamp: DateTime,
        forecastTimestamp: DateTime
    ): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getReturnPeriodForecastMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())
        url = url.replace('${forecastTimestamp}', forecastTimestamp ? forecastTimestamp.toISO() : '')

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    public async getReturnPeriodMaxMap(cityName: string, referenceTimestamp: DateTime): Promise<GeoJSON | null> {
        let result = null

        let url = this.routes.getReturnPeriodMaxMap
        url = url.replace('${cityName}', cityName)
        url = url.replace('${referenceTimestamp}', referenceTimestamp.toISO())

        try {
            result = await axios.get(url)
        } catch (exception) {
            this.handleError(url, exception as AxiosError<ServerError>)
        }

        if (!result) {
            return null
        }

        return result.data as unknown as GeoJSON
    }

    handleError(url: string, error: AxiosError<ServerError>): void {
        if (error && error.request && error.request.status && error.request.status === 500) {
            console.error(url)
            console.error(error)
        }
    }
}

import { GeoJSON } from 'geojson'

import { Coordinates } from './Coordinates'
import { ZoneCollection } from './ZoneCollection'

export class City {
    constructor(
        private name: string,
        private coordinates: Coordinates,
        private timezone: string,
        private contoursUrl: string | null,
        private administrativeAreas: ZoneCollection,
        private catchments: ZoneCollection,
        private contours: GeoJSON | null = null
    ) {}

    getName(): string {
        return this.name
    }

    getCoordinates(): Coordinates {
        return this.coordinates
    }

    getTimezone(): string {
        // return 'Asia/Ho_Chi_Minh'
        //return 'America/New_York'
        return this.timezone
    }

    getContoursUrl(): string | null {
        return this.contoursUrl
    }

    getContours(): GeoJSON | null {
        return this.contours
    }

    setContours(contours: GeoJSON): void {
        this.contours = contours
    }

    getAdministrativeAreas(): ZoneCollection {
        return this.administrativeAreas
    }

    getCatchments(): ZoneCollection {
        return this.catchments
    }
}

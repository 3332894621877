




















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { MapboxEvent } from 'mapbox-gl'
import { isEqual } from 'lodash'

import { City } from '@/model/City'
import { Snapshot } from '@/model/Snapshot'
import { TimeStep } from '@/model/TimeStep'
import { IRepresentation, Rainfall, ReturnPeriod, FloodRisk, FloodDepth } from '@/model/Representation'
import { IMapService } from '@/services/map/IMapService'
import { ISettingService } from "@/services/setting/ISettingService"

import { MapBoxGL } from './MapBoxGL'
import { IController } from './controllers/IController'
import { IControllerParent } from './controllers/IControllerParent'
import { CityController } from './controllers/CityController'
import { RainfallController } from './controllers/RainfallController'
import { ReturnPeriodController } from './controllers/ReturnPeriodController'
import { FloodRiskController } from './controllers/FloodRiskController'
import { FloodDepthController } from './controllers/FloodDepthController'
import { BaseRepresentationController } from './controllers/BaseRepresentationController'
import { StationController } from './controllers/StationController'
import { RainGaugeController } from './controllers/RainGaugeController'

const APPLICATION_PREFIX = 'flood4cast'

type MapLoadingState = 'loading' | 'loaded' | 'empty'

@Component({
    components: {}
})
export default class OverviewMap extends Vue implements IControllerParent {
    @Ref() readonly mapElement!: HTMLElement

    @Prop() private representation?: IRepresentation
    @Prop() private city?: City
    @Prop() private snapshot?: Snapshot
    @Prop() private timeStep?: TimeStep
    @Prop({ type: Boolean, default: false }) private maxMode?: boolean

    private mapService!: IMapService
    private settingService!: ISettingService

    private mapBoxGL!: MapBoxGL
    private cityController!: CityController
    private representationControllers: Map<string, IController> = new Map()
    private stationController!: StationController
    private rainGaugeController!: RainGaugeController

    private layerOpacity = 80

    private cityIsDirty = true

    private loadedSnapshotTimestamp = ''
    private loadedTimeStepTimestamp = ''

    private loadState: MapLoadingState = 'loading'

    private scale = 500

    private intervalId: number | null = null

    created(): void {
        this.mapService = this.$services.get<IMapService>('map')
        this.settingService = this.$services.get<ISettingService>('setting')
    }

    async mounted(): Promise<void> {
        if (!this.$refs.mapElement) {
            throw Error('The map component cannot be created.')
        }

        // TODO: fetch the mapbox access token from the server
        // E.g.: await MapService.fetchAccessToken()
        const accessToken =
            'pk.eyJ1IjoiY3lyaWxsb3JxdWV0IiwiYSI6ImNqeXF5dXdseTA1NmIzY251OGxvcHlmZDIifQ.36idC90lqx5Ig_pss9qnsw'

        let center: [number, number] = [0, 0]
        if (this.city) {
            const coordinates = this.city.getCoordinates()
            center = [coordinates.getLongitude(), coordinates.getLatitude()]
        }

        this.mapBoxGL = new MapBoxGL(
            APPLICATION_PREFIX,
            this.$refs.mapElement as HTMLElement,
            accessToken,
            center,
            'mapbox://styles/cyrillorquet/ckyzlpbag001714qmkpbh2nk3',
            this.onMapLoaded
        )

        this.cityController = new CityController(this, this.mapBoxGL, this.mapService)
        this.addRepresentationControllers(this.mapBoxGL, this.mapService)
        this.stationController = new StationController(this, this.mapBoxGL, this.mapService, this.$services)
        this.rainGaugeController = new RainGaugeController(this, this.mapBoxGL, this.mapService, this.$services)

        this.intervalId = setInterval(() => {
            if (!this.timeStep || this.loadState === 'loading') {
                return
            }

            if (
                this.loadedSnapshotTimestamp !== this.timeStep.snapshot.timestamp.toUTC().toISO() ||
                this.loadedTimeStepTimestamp !== this.timeStep.timestamp.toUTC().toISO()
            ) {
                this.load()
            }
        }, 50)
    }

    beforeDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        this.stationController.beforeDestroy()
        this.rainGaugeController.beforeDestroy()
    }

    addRepresentationControllers(mapBoxGL: MapBoxGL, mapService: IMapService): void {
        this.representationControllers.clear()
        this.representationControllers.set(Rainfall.id, new RainfallController(this, mapBoxGL, mapService))
        this.representationControllers.set(ReturnPeriod.id, new ReturnPeriodController(this, mapBoxGL, mapService))
        this.representationControllers.set(FloodRisk.id, new FloodRiskController(this, mapBoxGL, mapService))
        this.representationControllers.set(FloodDepth.id, new FloodDepthController(this, mapBoxGL, mapService))
    }

    async onMapLoaded(): Promise<void> {
        for (const controller of this.representationControllers.values()) {
            controller.initialize()
        }
        this.cityController.initialize()
        this.stationController.initialize()
        this.rainGaugeController.initialize()

        this.mapBoxGL.addEventHandler('zoom', (event: MapboxEvent) => {
            const currentZoom = this.mapBoxGL.getZoom()

            let newScale = this.scale
            if (this.scale != 500 && currentZoom < 12) {
                newScale = 500
            } else if (this.scale != 100 && currentZoom >= 12 && currentZoom < 14) {
                newScale = 100
            } else if (this.scale != 20 && currentZoom >= 14) {
                newScale = 20
            }

            if (this.representation === FloodDepth && this.scale !== newScale) {
                this.scale = newScale
                this.load()
            } else {
                this.scale = newScale
            }
        })

        this.load()
    }

    async load(): Promise<void> {
        if (!this.mapBoxGL.isReady() || !this.city || !this.representation) {
            return
        }

        const controller = this.representationControllers.get(this.representation.id)
        if (!controller) {
            this.mapBoxGL.showOnlyLayers([])
            return
        }
        this.loadState = 'loading'

        if (this.cityIsDirty) {
            const coordinates = this.city.getCoordinates()
            this.mapBoxGL.jumpTo([coordinates.getLongitude(), coordinates.getLatitude()])
            await this.cityController.load(this.city, this.timeStep, true)
        }

        let mapHasData = false
        if (this.timeStep) {
            const loadedSnapshotTimestamp = this.timeStep.snapshot.timestamp.toUTC().toISO()
            const loadedTimeStepTimestamp = this.timeStep.timestamp.toUTC().toISO()
            mapHasData = await controller.load(
                this.city,
                this.timeStep,
                this.cityIsDirty && this.city.getContours() === null,
                this.scale
            )
            this.loadedSnapshotTimestamp = loadedSnapshotTimestamp
            this.loadedTimeStepTimestamp = loadedTimeStepTimestamp

            await this.stationController.load(this.city, this.timeStep, true)
        }

        if (this.snapshot) {
            await this.rainGaugeController.load(this.city, this.snapshot.getReference(), true)
        }

        if (!this.timeStep) {
            this.loadState = 'loading'
        } else if (this.timeStep.isEmpty() || !mapHasData) {
            this.loadState = 'empty'
        } else {
            this.loadState = 'loaded'
        }
        this.cityIsDirty = false

        for (const layer of (controller as BaseRepresentationController).layers) {
            this.mapBoxGL.setOpacityForLayer(layer.id, this.layerOpacity)
        }
    }

    getCurrentTimeStep(): TimeStep | null {
        return this.timeStep ? this.timeStep : null
    }

    isMaxMode(): boolean {
        return this.maxMode !== undefined ? this.maxMode : false
    }

    async hasRainGauges(): Promise<boolean> {
        const settings = await this.settingService.getSettings()
        return settings.getHasRainGauges()
    }

    @Watch('city')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    onCityChanged(city: City | null): void {
        this.cityIsDirty = true
        this.load()
    }

    @Watch('representation')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async onRepresentationChanged(representation: IRepresentation): Promise<void> {
        this.stationController.clearCities()
        this.rainGaugeController.clearRainGauges()
        this.load()
    }

    @Watch('timeStep')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async onTimeStepChanged(timeStep: TimeStep | null, previousTimeStep: TimeStep | null): Promise<void> {
        if (!timeStep) {
            return
        }

        if (previousTimeStep === null || this.representation === FloodRisk) {
            await this.load()
            return
        }
    }

    @Watch('snapshot')
    async onSnapshotChanged(snapshot: Snapshot | null, previousSnapshot: Snapshot | null): Promise<void> {
        if (!snapshot) {
            return
        }
        
        if (!await this.hasRainGauges()) {
            return
        }
        
        const newReference = snapshot.getReference()
        const previousReference = previousSnapshot?.getReference()
        
        if (isEqual(newReference, previousReference)) {
            return
        }
        
        await this.rainGaugeController.reloadRainGauges(newReference)
    }

    @Watch('maxMode')
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    onMaxModeChanged(maxMode: boolean): void {
        this.load()
    }

    @Watch('layerOpacity')
    onLayerOpacityChanged(opacity: number): void {
        if (!this.representation) {
            return
        }

        const controller = this.representationControllers.get(this.representation.id)
        if (!controller) {
            return
        }

        for (const layer of (controller as BaseRepresentationController).layers) {
            this.mapBoxGL.setOpacityForLayer(layer.id, opacity)
        }
    }
}

import _Vue from 'vue'
import { ServiceLocator, serviceLocatorInstance } from '@/services/ServiceLocator'

declare module 'vue/types/vue' {
    interface Vue {
        $services: ServiceLocator
    }
}

export default {
    install: (Vue: typeof _Vue): void => {
        Vue.mixin({
            created() {
                Vue.prototype.$services = serviceLocatorInstance
            }
        })
    }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { TranslationAPI } from '@/api/translation/TranslationAPI'

import { BaseService } from '../BaseService'
import { ITranslationService } from './ITranslationService'
import { Disclaimer } from '@/model/Disclaimer'
import { TranslationFactory } from '../translation/TranslationFactory'

Vue.use(VueI18n)

// TODO: extract to global config?
const DEFAULT_LANGUAGE = 'en'

/* eslint-disable @typescript-eslint/no-explicit-any */
export class TranslationService extends BaseService implements ITranslationService {
    private readonly api: TranslationAPI = new TranslationAPI()
    protected readonly i18n: VueI18n = new VueI18n({
        fallbackLocale: DEFAULT_LANGUAGE
    })
    protected languages: Map<string, any> = new Map()
    protected disclaimers: Map<string, any> = new Map()

    public getI18nPlugin(): VueI18n {
        return this.i18n
    }

    public getCurrentLanguageCode(): string {
        return this.i18n.locale
    }

    public async useLanguage(languageCode: string): Promise<void> {
        if (this.i18n.locale === languageCode) {
            return Promise.resolve()
        }

        if (this.languages.has(languageCode)) {
            this.setLanguage(languageCode)
            return Promise.resolve()
        }

        const translations = await this.api.getTranslations(languageCode)
        this.storeTranslations(languageCode, translations)

        this.setLanguage(languageCode)

        // in order to have a fallbacklanguage, we have to fetch and store the translations for this language
        this.ensureFallbackLanguage()
    }

    protected storeTranslations(code: string, translations: Map<string, string>): void {
        // in order for the fallback mechanism to work, keys with empty values have to be removed
        const withoutEmptyTranslations: any = {}
        for (const [key, value] of Object.entries(translations)) {
            if (value) {
                withoutEmptyTranslations[key] = value
            }
        }

        this.languages.set(code, withoutEmptyTranslations)
    }

    public async getAvailableLanguages(): Promise<Map<string, string>> {
        return await this.api.getAvailableLanguages()
    }

    protected setLanguage(code: string): void {
        this.i18n.setLocaleMessage(code, this.languages.get(code))
        this.i18n.locale = code
        document.querySelector('html')?.setAttribute('lang', code)
    }

    protected async ensureFallbackLanguage(): Promise<void> {
        if (!this.languages.has(DEFAULT_LANGUAGE)) {
            const defaultTranslations = await this.api.getTranslations(DEFAULT_LANGUAGE)
            this.storeTranslations(DEFAULT_LANGUAGE, defaultTranslations)
        }
        this.i18n.setLocaleMessage(DEFAULT_LANGUAGE, this.languages.get(DEFAULT_LANGUAGE))
    }

    public translate(key: string): string {
        return this.i18n.t(key).toString()
    }

    public async getCustomerHeader(): Promise<string> {
        const languageCode = this.getCurrentLanguageCode()
        const customerHeaders = await this.api.getCustomerHeaders()

        if (!customerHeaders || !customerHeaders[languageCode]) {
            return ""
        }
        return customerHeaders[languageCode]
    }

    public async getDisclaimer(code: string): Promise<Disclaimer> {
        if (this.disclaimers.has(code)) {
            return this.disclaimers.get(code)
        }

        const dto = await this.api.getDisclaimer(code)
        const disclaimer = TranslationFactory.fromDisclaimerDTO(dto)
        this.disclaimers.set(code, disclaimer)
        return disclaimer
    }
}

export class Country {
    constructor(readonly name: string, readonly dialCode: string, readonly a2Code: string, readonly flag: string) {}

    matches(val: string): boolean {
        val = val.toLowerCase()
        return (
            this.name.toLowerCase().includes(val) ||
            this.dialCode.toLowerCase().includes(val) ||
            this.a2Code.toLowerCase().includes(val)
        )
    }
}

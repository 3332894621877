import { TimeStepDTO } from "../snapshot/TimeStepDTO";

export class HistoryDTO {
    constructor(
        readonly city: string,
        readonly start: string,
        readonly end: string,
        readonly history: Array<TimeStepDTO>
    ) {}
}

import { CityDTO } from '@/api/core/dto/city/CityDTO'
import { City } from '@/model/City'
import { Coordinates } from '@/model/Coordinates'
import { ZoneCollection } from '@/model/ZoneCollection'

export class CityFactory {
    static fromDTO(dto: CityDTO): City {
        return new City(
            dto.name,
            new Coordinates(dto.latitude, dto.longitude),
            dto.timezone,
            dto.contoursUrl,
            new ZoneCollection(dto.administrativeAreas, dto.administrativeAreasContoursUrl),
            new ZoneCollection(dto.catchments, null)
        )
    }
}

import { LegendsDTO } from '@/api/setting/dto/LegendsDTO'
import { Legend } from '@/model/Legend'
import { Threshold } from '@/model/Threshold'
import { ThresholdDTO } from "@/api/setting/dto/ThresholdDTO"

const DEFAULT_LABEL_COLOR = "black"

/**
 * Make model.Legend from Legend DTOs object to integrate with Return Period, Flood Risk, Flood Depth and Rainfall representations.
 */
export class LegendFactory {
    static returnPeriodLegendFromDTO(dto: LegendsDTO): Legend {
        return this.legendFromDTO('model.representation.return_period', dto.returnPeriod)
    }

    static rainGaugesLegendFromDTO(dto: LegendsDTO): Legend {
        return this.legendFromDTO('model.raingauges', dto.rainGauges)
    }

    static floodRiskLegendFromDTO(dto: LegendsDTO): Legend {
        return this.legendFromDTO('model.representation.flood_risk', dto.floodRisk)
    }

    static floodDepthLegendFromDTO(dto: LegendsDTO): Legend {
        return this.legendFromDTO('model.representation.flood_depth', dto.floodDepth)
    }

    static rainfallLegendFromDTO(dto: LegendsDTO): Legend {
        return this.legendFromDTO('model.representation.rainfall', dto.rainfall)
    }

    private static legendFromDTO(titleTranslationKey: string, thresholdDTOs: ThresholdDTO[]): Legend {
        const thresholds: Array<Threshold> = []

        for (const thresholdDTO of thresholdDTOs) {
            const { value, color, labelText, labelColor: rawLabelColor, labelHidden } = thresholdDTO

            const label = !labelText ? `${value}` : labelText
            const labelColor = rawLabelColor ? rawLabelColor : DEFAULT_LABEL_COLOR

            thresholds.push(
                new Threshold(value, color, label, labelColor, labelHidden)
            )
        }

        return new Legend(
            titleTranslationKey,
            thresholds.sort((a, b) => a.value - b.value),
            'linear'
        )
    }
}

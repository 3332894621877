








import { Component, Prop, Vue } from 'vue-property-decorator'

import { IRepresentation, Rainfall } from "@/model/Representation"
import GradientLegend from "@/components/legend/GradientLegend.vue"
import RainGaugesLegend from "@/components/legend/RainGaugesLegend.vue"
import { ISettingService } from "@/services/setting/ISettingService"

@Component({
    components: {
        RainGaugesLegend,
        GradientLegend
    }
})
export default class Legends extends Vue {
    @Prop() representation!: IRepresentation
    private hasRainGauges = false
    private settingService!: ISettingService

    created(): void {
        this.settingService = this.$services.get<ISettingService>('setting')
        this.initialize()
    }

    async initialize(): Promise<void> {
        const settings = await this.settingService.getSettings()
        this.hasRainGauges = settings.getHasRainGauges()
    }

    get showRainGaugesLegend(): boolean {
        return this.representation == Rainfall && this.hasRainGauges
    }
}

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { ICityService } from './ICityService'

import { CoreAPI } from '@/api/core/CoreAPI'
import { CityCollectionFactory } from './CityCollectionFactory'
import { CityCollection } from '@/model/CityCollection'

export class CityService extends BaseService implements ICityService {
    protected cityCollection: CityCollection
    private readonly api: CoreAPI = new CoreAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
        this.cityCollection = new CityCollection([])
    }

    async loadCityCollection(): Promise<CityCollection> {
        const dto = await this.api.getCities()
        if (!dto) {
            this.cityCollection = CityCollectionFactory.createEmpty()
        } else {
            this.cityCollection = CityCollectionFactory.fromDTO(dto)
        }
        return this.cityCollection
    }

    getCityCollection(): CityCollection {
        return this.cityCollection
    }
}

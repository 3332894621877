export class CityDTO {
    constructor(
        readonly name: string,
        readonly latitude: number,
        readonly longitude: number,
        readonly timezone: string,
        readonly zoomLevel: number,
        readonly contoursUrl: string | null,
        readonly administrativeAreas: string[],
        readonly administrativeAreasContoursUrl: string,
        readonly catchments: string[]
    ) {}
}

import { Country } from '@/model/Country'
import { BaseService } from '../BaseService'
import { ICountryService } from './ICountryService'

import Countries from './country_dial_info.json'
import { ServiceLocator } from '../ServiceLocator'

export class MockCountryService extends BaseService implements ICountryService {
    private _allCountries: Country[] | null = null

    constructor(locator: ServiceLocator) {
        super(locator)
    }

    get allCountries(): Country[] {
        if (!this._allCountries) {
            this._allCountries = Countries.map((c) => new Country(c.name, c.dial_code, c.code, c.flag))
        }
        return this._allCountries
    }

    getCountriesMatching(val: string): Promise<Country[]> {
        return Promise.resolve(this.allCountries.filter((c) => c.matches(val)))
    }
}

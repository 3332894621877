export class Catchment {
    constructor(readonly floodMapUrlByScale: Map<number, string>) {}

    getForScale(scale: number): string | null {
        const floodMapUrl = this.floodMapUrlByScale.get(scale)
        if (!floodMapUrl) {
            return null
        }
        return floodMapUrl
    }
}








import { Vue, Component, Ref } from 'vue-property-decorator'
import { getEnv } from '@/utils/env'

@Component
export default class RecaptchaComponent extends Vue {
    @Ref('recaptcha') recaptchaRef!: HTMLDivElement

    mounted(): void {
        if (typeof window.grecaptcha === "undefined") {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js";
            script.onload = this.renderWait;
            document.head.appendChild(script);
        } else {
            this.renderRecaptcha();
        }
    }

    private renderWait(): void {
        setTimeout(() => {
            if (typeof window.grecaptcha !== "undefined" && typeof window.grecaptcha.render !== "undefined") {
                this.renderRecaptcha()
            } else {
                this.renderWait()
            }
        }, 100)
    }

    private renderRecaptcha(): void {
        window.grecaptcha.render(this.recaptchaRef, {
            sitekey: getEnv("VUE_APP_GOOGLE_RECAPTCHA_KEY"),
            size: 'normal',
            callback: this.onRecaptchaVerified,
            'expired-callback': this.onRecaptchaExpired,
        })
    }

    private onRecaptchaVerified(): void {
        this.$emit("captcha-updated", true)
    }

    private onRecaptchaExpired(): void {
        this.$emit("captcha-updated", false)
    }
}

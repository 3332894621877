






































import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { allRepresentations, IRepresentation, Rainfall } from '@/model/Representation'
import { Snapshot } from '@/model/Snapshot'
import { TimeStep } from '@/model/TimeStep'
import { DateTimeFactory } from '@/model/DateTimeFactory'

import { ICityService } from '@/services/city/ICityService'
import { ISnapshotService } from '@/services/snapshot/ISnapshotService'

import OverviewMap from './map/OverviewMap.vue'
import RepresentationSelector from './representation-selector/RepresentationSelector.vue'
import Timeline from './timeline/Timeline.vue'
import CommandBar from '@/components/commands/CommandBar.vue'
import Disclaimer from './disclaimer/Disclaimer.vue'
import StationsTimeSeries from '@/components/station/StationsTimeSeries.vue'
import RainGaugesTimeSeries from '@/components/raingauge/RainGaugesTimeSeries.vue'
import { IModeService, ModeChangedEventArgs } from '@/services/mode/IModeService'
import { Mode } from '@/model/Mode'
import { Permissions } from '@/model/Permissions'
import { IAccessControlService } from '@/services/acl/IAccessControlService'
import { ITranslationService } from '@/services/translation/ITranslationService'
import Legends from "@/components/legend/Legends.vue"

const LIVE_MODE_REFRESH_RATE_IN_MILLIS = 30 * 1000
const PLAY_MODE_REFRESH_RATE_IN_MILLIS = 1200

@Component({
    components: {
        Legends,
        OverviewMap,
        RepresentationSelector,
        CommandBar,
        Timeline,
        Disclaimer,
        StationsTimeSeries,
        RainGaugesTimeSeries
    }
})
export default class Overview extends Vue {
    @Prop({ type: String, required: true }) cityId!: string

    @Prop(String) snapshotTimestamp!: string
    @Prop(String) timeStepTimestamp!: string

    private cityService: ICityService | null = null
    private snapshotService: ISnapshotService | null = null
    private modeService: IModeService | null = null
    private userService!: IAccessControlService
    private translationService!: ITranslationService
    private representationId = Rainfall.id

    //those fields will allow downstream event propagation
    mouseMoveEvt: MouseEvent | null = null
    mouseClickEvt: MouseEvent | null = null

    private mode!: Mode
    private city: City | null = null
    private representation: IRepresentation | null = null
    private snapshot: Snapshot | null = null
    private timeStep: TimeStep | null = null

    private liveModeIntervalId: number | null = null
    private playModeIntervalId: number | null = null

    private hasAccess = false
    private customerHeader = ''

    //
    // Component lifecycle
    //
    created(): void {
        this.cityService = this.$services.get<ICityService>('city')
        this.snapshotService = this.$services.get<ISnapshotService>('snapshot')
        this.modeService = this.$services.get<IModeService>('mode')
        this.userService = this.$services.get<IAccessControlService>('acl')
        this.translationService = this.$services.get<ITranslationService>('translation')
        this.initialize()
    }

    beforeDestroy(): void {
        this.modeService?.modeChanged.unsubscribe(this.onModeChanged)
        this.enableMode(this.mode, false)
    }

    //
    // Mutators
    //
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    setRepresentation(representationId: string): void {
        const representation = allRepresentations.find((element) => element.id === this.representationId)
        if (representation === undefined) {
            throw Error(`Representation ${representation} could not be found.`)
        }
        this.representation = representation
    }

    setCity(cityId: string): void {
        if (this.city && this.city.getName() === cityId) {
            return
        }

        let city = this.cityService?.getCityCollection().getByName(cityId)
        if (!city) {
            return
        }

        this.city = city
    }

    setSnapshot(snapshot: Snapshot): void {
        this.snapshot = snapshot
    }

    setTimeStep(timeStep: TimeStep): void {
        this.timeStep = timeStep
    }

    //
    // Logic
    //
    initialize(): void {
        this.setRepresentation(this.representationId)
        this.setCity(this.cityId)

        this.mode = this.modeService?.getMainMode() as Mode
        this.enableMode(this.mode, true)

        this.modeService?.modeChanged.subscribe(this.onModeChanged)
        this.setAccess()
        this.setCustomerHeader()
    }

    private setAccess(): void {
        this.hasAccess =
            this.userService.hasPermission(Permissions.HISTORY) ||
            this.userService.hasPermission(Permissions.FULL_ACCESS)
    }

    private async setCustomerHeader(): Promise<void> {
        this.customerHeader = await this.translationService.getCustomerHeader()
    }

    reRoute(mode: Mode, snapshotTimestamp?: string, timeStepTimestamp?: string): void {
        const currentQuery = this.$route.query

        const query = { ...currentQuery }
        query['mode'] = mode
        if (snapshotTimestamp) {
            query['snapshotTimestamp'] = snapshotTimestamp
        } else {
            delete query['snapshotTimestamp']
        }
        if (timeStepTimestamp) {
            query['timeStepTimestamp'] = timeStepTimestamp
        } else {
            delete query['timeStepTimestamp']
        }

        const route = {
            name: 'Overview',
            params: {
                cityId: this.cityId
            },
            query: query
        }

        if (this.$route.fullPath === this.$router.resolve(route).route.fullPath) {
            return
        }

        this.$router.replace(route)
    }

    async loadSnapshot(city: City, timestamp: DateTime): Promise<Snapshot | null> {
        let timestampUTC = timestamp
        if (timestampUTC.zoneName !== 'UTC') {
            timestampUTC = timestamp.toUTC()
        }

        const snapshot = await this.snapshotService?.loadSnapshot(city, timestampUTC)
        if (!snapshot) {
            return null
        }

        return snapshot
    }

    private enableMode(mode: Mode, enable: boolean): void {
        switch (mode) {
            case 'live':
                this.enableLiveMode(enable)
                break
            case 'free':
                this.enableFreeMode(enable)
                break
            case 'play':
                this.enablePlayMode(enable)
                break
            case 'pause':
                this.enablePauseMode(enable)
                break
            case 'max':
                this.enableMaxMode(enable)
                break
        }
    }

    private async enableLiveMode(enable: boolean): Promise<void> {
        if (enable) {
            this.liveModeIntervalId = setInterval(this.triggerLiveUpdate, LIVE_MODE_REFRESH_RATE_IN_MILLIS)
            await this.triggerLiveUpdate()
        } else {
            if (this.liveModeIntervalId) {
                window.clearInterval(this.liveModeIntervalId)
                this.liveModeIntervalId = null
            }
        }
    }

    private enableFreeMode(enable: boolean): void {
        if (enable) {
            return
        } else {
            return
        }
    }

    private async enablePlayMode(enable: boolean): Promise<void> {
        if (enable) {
            this.playModeIntervalId = window.setInterval(
                () => this.triggerPlayUpdate(),
                PLAY_MODE_REFRESH_RATE_IN_MILLIS
            )
            this.triggerPlayUpdate()
        } else {
            if (this.playModeIntervalId) {
                window.clearInterval(this.playModeIntervalId)
                this.playModeIntervalId = null
            }
        }
    }

    private enablePauseMode(enable: boolean): void {
        if (enable) {
            return
        } else {
            return
        }
    }

    private async enableMaxMode(enable: boolean): Promise<void> {
        if (enable) {
            if (this.snapshot) {
                const timeStepMax = this.snapshot.getMax()
                if (timeStepMax) {
                    this.setTimeStep(timeStepMax)
                }
            }
        } else {
            if (this.snapshot) {
                this.setTimeStep(this.snapshot.getReference())
            }
        }
    }

    private async triggerLiveUpdate(): Promise<void> {
        if (this.mode !== 'live' || !this.city) {
            return
        }

        const snapshot = await this.loadSnapshot(this.city, DateTimeFactory.utcRounded())
        if (!snapshot) {
            return
        }

        this.setSnapshot(snapshot)
    }

    private triggerPlayUpdate(): void {
        if (this.mode === 'play' && this.snapshot && this.timeStep) {
            if (this.timeStep.isMax()) {
                this.setTimeStep(this.snapshot.getReference())
                return
            }
            const nextTimeStep = this.snapshot.nextTimeStep(this.timeStep.timestamp.toUTC())
            if (nextTimeStep) {
                this.setTimeStep(nextTimeStep)
            }
        }
    }

    //
    // Watch URL Parameters
    //
    @Watch('cityId')
    onCityIdChanged(cityId: string): void {
        this.setCity(cityId)
    }

    @Watch('representationId')
    onRepresentationIdChanged(representationId: string): void {
        this.setRepresentation(representationId)
    }

    @Watch('snapshotTimestamp')
    async onSnapshotTimestampChanged(timestamp: string): Promise<void> {
        if (!this.city || !timestamp) {
            return
        }

        const dateTime = DateTime.fromISO(timestamp, { zone: 'UTC' })
        if (!dateTime.isValid) {
            return
        }

        const snapshot = await this.loadSnapshot(this.city, dateTime)
        if (!snapshot) {
            return
        }

        this.setSnapshot(snapshot)
    }

    @Watch('timeStepTimestamp')
    onTimeStepTimestampChanged(timestamp: string): void {
        if (!this.city || !this.snapshot) {
            return
        }

        if (this.mode === 'max') {
            return
        }

        const timestampUTC = DateTime.fromISO(timestamp, { zone: 'UTC' })
        let timeStep = this.snapshot.findTimeStepAt(timestampUTC)
        this.setTimeStep(timeStep ? timeStep : this.snapshot.getReference())
    }

    //
    // Watch attributes
    //
    @Watch('city')
    async onCityChanged(city: City): Promise<void> {
        let snapshotTimestamp = null

        if (this.snapshot) {
            snapshotTimestamp = this.snapshot.timestamp
        } else if (this.snapshotTimestamp) {
            const timestamp = DateTime.fromISO(this.snapshotTimestamp, { zone: 'UTC' })
            snapshotTimestamp = timestamp.isValid ? timestamp : DateTimeFactory.utcRounded()
        } else {
            snapshotTimestamp = DateTimeFactory.utcRounded()
        }

        const snapshot = await this.loadSnapshot(city, snapshotTimestamp)
        if (snapshot) {
            this.setSnapshot(snapshot)
        }
    }

    @Watch('snapshot')
    onSnapshotChanged(snapshot: Snapshot): void {
        const timeStepMax = snapshot.getMax()
        if (this.mode === 'max' && timeStepMax) {
            this.setTimeStep(timeStepMax)
            return
        }

        if (!this.timeStep) {
            this.setTimeStep(snapshot.getReference())
            return
        }

        if (this.timeStep.snapshot.city === this.city) {
            this.setTimeStep(snapshot.getReference())
            return
        }

        const timeStep = snapshot.findTimeStepAt(this.timeStep.timestamp)
        this.setTimeStep(timeStep ? timeStep : snapshot.getReference())
    }

    async onModeChanged(args: ModeChangedEventArgs): Promise<void> {
        if (args.oldMainMode !== args.newMainMode) {
            this.enableMode(this.mode, false)
            this.enableMode('pause', true)
            this.mode = args.newMainMode
        } else {
            this.enableMode(this.mode, false)
            this.mode = args.newSecondaryMode
        }

        this.enableMode(this.mode, true)
        this.reRouteOnModeChanged(this.mode)
    }

    reRouteOnModeChanged(mode: Mode): void {
        const fullReRouteModes = ['free', 'play', 'pause']

        if (fullReRouteModes.indexOf(mode) !== -1) {
            this.reRoute(mode, this.snapshotTimestamp, this.timeStepTimestamp)
        } else {
            this.reRoute(mode)
        }
    }

    //
    // Mouse events
    //
    onMouseMove(evt: MouseEvent): void {
        this.mouseMoveEvt = evt
    }

    onMouseClick(evt: MouseEvent): void {
        this.mouseClickEvt = evt
    }

    //
    // Timeline events
    //
    onTimelineSnapshotChanged(timestamp: DateTime): void {
        this.reRoute('free', timestamp.toUTC().toISO(), timestamp.toUTC().toISO())
    }

    onTimelineTimeStepChanged(timeStep: TimeStep): void {
        this.reRoute(this.mode, this.snapshotTimestamp, timeStep.timestamp.toUTC().toISO())
    }
}

import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import { serviceLocatorInstance } from '@/services/ServiceLocator'
import { IAuthService } from '@/services/auth/IAuthService'

const authenticationGuard: NavigationGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
    const authService = serviceLocatorInstance.get<IAuthService>('auth')
    if (!authService.isAuthenticated()) {
        next({
            name: 'Auth',
            params: {
                to: to.fullPath
            }
        })
        return
    }
    next()
}

export default authenticationGuard

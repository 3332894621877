
















































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'

import { User } from '@/model/User'
import { Country } from '@/model/Country'
import { UserAlarmsUpdate } from '@/model/UserAlarmsUpdate'

import { ICountryService } from '@/services/country/ICountryService'

import PhoneNumberField from './PhoneNumberField.vue'
import { SorterHelper } from '@/model/SorterHelper'

@Component({
    components: {
        PhoneNumberField
    }
})
export default class AlarmForm extends Vue {
    @Prop({ type: User }) user!: User

    private sorterHelper!: SorterHelper

    private countryService!: ICountryService

    private userSettingsLoaded = false
    private availableCountries: Country[] | null = null
    private triggerClearPhoneField = false
    private phoneKey = 0

    private emailAlarms = false
    private email = ''
    private smsAlarms = false
    private phone = ''
    private newPhone: string | null = null

    private cities: string[] | null = null
    private citiesAlarms: string[] | null = null
    private newCitiesAlarms: any[] = []
    private selectAllCities = false

    created(): void {
        this.countryService = this.$services.get<ICountryService>('country')
    }

    async mounted(): Promise<void> {
        this.availableCountries = await this.countryService.getCountriesMatching('')
        window.addEventListener('resize', this.handleCitySorting)
    }

    setEmailAlarmSwitch(): void {
        if (this.email.trim().length === 0) {
            this.emailAlarms = false
        }
    }

    private handleCitySorting(): void {
        let element = document.getElementById('cities')
        if (element) {
            this.newCitiesAlarms = this.sorterHelper.handleCitySorting(element)
        }
    }

    @Emit('submit')
    notifySubmit(): UserAlarmsUpdate {
        if (this.email.trim().length === 0) {
            this.emailAlarms = false
        }
        // first case: invalid phone number (due to missing part) or no phone number encoded
        if (this.newPhone === '') {
            this.triggerClearPhoneField = true
            this.smsAlarms = false
            this.phone = ''
            this.phoneKey += 1
        } else if (this.newPhone) {
            this.phone = this.newPhone
            this.newPhone = null
        }
        return new UserAlarmsUpdate(
            this.formatNewCitiesAlarmsOfUser,
            this.email,
            this.emailAlarms,
            this.phone,
            this.smsAlarms
        )
    }

    get citiesOfUser(): string[] {
        return this.user.cities.map(function (city) {
            return city.getName()
        })
    }

    get citiesAlarmsOfUser(): string[] {
        return this.user.citiesAlarms.map(function (city) {
            return city.getName()
        })
    }

    get newCitiesAlarmsOfUser(): any[] {
        return this.newCitiesAlarms?.filter(function (value) {
            return value.checked
        })
    }

    get formatNewCitiesAlarmsOfUser(): string[] {
        let newCitiesAlarms = this.newCitiesAlarmsOfUser
        newCitiesAlarms = newCitiesAlarms?.map(function (city) {
            return city['text']
        })
        return newCitiesAlarms
    }

    onSelectAllCities(): void {
        if (this.selectAllCities) {
            this.newCitiesAlarms.forEach((city) => (city.checked = true))
        } else {
            this.newCitiesAlarms.forEach((city) => (city.checked = false))
        }
    }

    onPhoneNumberChanged(phoneNumber: string): void {
        this.newPhone = phoneNumber
    }

    @Watch('user')
    onUserSet(): void {
        this.emailAlarms = this.user.emailAlarm
        this.email = this.user.email
        this.smsAlarms = this.user.smsAlarm
        this.phone = this.user.phone
        this.cities = this.citiesOfUser
        this.citiesAlarms = this.citiesAlarmsOfUser
        this.newCitiesAlarms = this.getUserCities(this)
        this.selectAllCities = this.citiesAlarms.length == this.cities.length ? true : false

        this.sorterHelper = new SorterHelper(this.newCitiesAlarms)
        this.newCitiesAlarms = this.sorterHelper.sortCities()
        this.userSettingsLoaded = true
    }

    @Watch('newCitiesAlarms', { deep: true, immediate: true })
    onCitiesCheckboxesChanged(): void {
        this.selectAllCities = this.verifyAllCitiesSelected() ? true : false
    }

    getUserCities(self: any): any[] {
        return self.cities.map(function (cityName: string) {
            return { text: cityName, checked: self.citiesAlarms.includes(cityName) }
        }, self)
    }

    private verifyAllCitiesSelected(): boolean {
        let allChecked = true
        this.newCitiesAlarms.forEach((city) => {
            if (!city.checked) {
                allChecked = false
            }
        })
        return allChecked
    }
}

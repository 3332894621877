export const MonthNames = [
    'common.datetime.january',
    'common.datetime.february',
    'common.datetime.march',
    'common.datetime.april',
    'common.datetime.may',
    'common.datetime.june',
    'common.datetime.july',
    'common.datetime.august',
    'common.datetime.september',
    'common.datetime.october',
    'common.datetime.november',
    'common.datetime.december'
]

export const DayNames = [
    'common.datetime.sunday',
    'common.datetime.monday',
    'common.datetime.tuesday',
    'common.datetime.wednesday',
    'common.datetime.thursday',
    'common.datetime.friday',
    'common.datetime.saturdary'
]

export const ShortDayNames = [
    'common.datetime.sunday.shortcut',
    'common.datetime.monday.shortcut',
    'common.datetime.tuesday.shortcut',
    'common.datetime.wednesday.shortcut',
    'common.datetime.thursday.shortcut',
    'common.datetime.friday.shortcut',
    'common.datetime.saturdary.shortcut'
]



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { DateTime, Duration } from 'luxon'

import ModalDatetimePicker from '@/components/datetime/ModalDateTimePicker.vue'
import { Snapshot } from '@/model/Snapshot'
import { TimeStepSequence } from '@/model/TimeStepSequence'
import LiveFreeSwitch from '@/components/commands/MainModeSwitch.vue'

@Component({
    components: {
        ModalDatetimePicker,
        LiveFreeSwitch
    }
})
export default class DateTimeAxis extends Vue {
    @Prop() snapshot!: Snapshot
    @Prop({ type: Number, default: 3 }) ticksBefore!: number
    @Prop({ type: Number, default: 3 }) ticksAfter!: number
    @Prop() durationBefore!: Duration
    @Prop() durationAfter!: Duration
    @Prop({ type: Boolean, default: false }) activated!: boolean

    timeSequence!: TimeStepSequence
    ticks: number[] = []
    showModal = false

    created(): void {
        this.snapshotChanged()
        this.ticks = []
        for (let i = 0; i < this.ticksBefore; ++i) {
            this.ticks.push((i / this.ticksBefore) * 0.5)
        }
        this.ticks.push(0.5)
        for (let i = 1; i <= this.ticksAfter; ++i) {
            this.ticks.push(0.5 + (i / this.ticksAfter) * 0.5)
        }
    }

    /**
     * Format a given datetime timeline value.
     * */
    labelFor(tick: number): string {
        const dt = this.timeSequence.getDateTimeAt(tick).setZone(this.snapshot.city.getTimezone())
        const format = this.$t(
            tick === 0.5 ? 'common.datetime.format.datetime' : 'common.datetime.format.time'
        ).toString()
        return dt.toFormat(format)
    }

    /**
     * Returns the section matching this value.
     * */
    sectionFor(value: DateTime): string {
        return value.toString()
    }

    onPickDatetimeRequested(): void {
        this.showModal = true
    }

    onModalClosing(): void {
        this.showModal = false
    }

    @Watch('snapshot')
    snapshotChanged(): void {
        this.timeSequence = TimeStepSequence.fromIterable(
            this.snapshot,
            this.snapshot.getStart(),
            this.snapshot.getEnd()
        )
    }

    onDatetimeChanged(_newDt: DateTime): void {
        this.$emit('datetime-changed', this.snapshot.timestamp, _newDt)
    }
}

import { GeoJSON } from 'geojson'

export class ZoneCollection {
    private zoneIndicesMap: Map<string, number> = new Map()

    constructor(
        private zones: string[],
        private contoursUrl: string | null = null,
        private contours: GeoJSON | null = null
    ) {
        this.zones.forEach((value: string, index: number) => {
            this.zoneIndicesMap.set(value, index)
        })
    }

    getZones(): string[] {
        return this.zones
    }

    getContoursUrl(): string | null {
        return this.contoursUrl
    }

    getContours(): GeoJSON | null {
        return this.contours
    }

    setContours(contours: GeoJSON): void {
        this.contours = contours
    }

    getIndexOf(name: string): number {
        const result = this.zoneIndicesMap.get(name)
        if (result === null || result === undefined) {
            return -1
        }
        return result
    }

    get isEmpty(): boolean {
        return this.zones.length <= 0
    }
}

import { FeatureCollection } from 'geojson'

export class GeoJsonCache {
    private readonly map: Map<string, FeatureCollection> = new Map<string, FeatureCollection>()

    store(identifier: string, geoJson: FeatureCollection): void {
        this.map.set(identifier, geoJson)
    }

    retrieve(identifier: string): FeatureCollection | null {
        if (this.map.has(identifier)) {
            return this.map.get(identifier) as FeatureCollection
        }
        return null
    }
}

import axios from 'axios'
import { FeatureCollection } from 'geojson'
import { AnyLayer, LineLayer, SymbolLayer } from 'mapbox-gl'

import { City } from '@/model/City'
import { TimeStep } from '@/model/TimeStep'
import { IController } from './IController'
import { BaseController } from './BaseController'

const CITY_SOURCE = 'city'
const ADMINISTRATIVE_AREA_SOURCE = 'administrativeAreas'

export class CityController extends BaseController implements IController {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async load(city: City, timeStep?: TimeStep, zoomOnSource = false, scale = 500): Promise<boolean> {
        let cityContours = city.getContours()
        if (!cityContours) {
            const url = city.getContoursUrl()
            if (url) {
                cityContours = (await axios.get(url)).data as FeatureCollection
                city.setContours(cityContours)
            }
        }

        if (cityContours) {
            cityContours = cityContours as FeatureCollection
            this.mapBoxGL.updateSource(CITY_SOURCE, cityContours)
            this.mapBoxGL.zoom(cityContours)
        } else {
            this.mapBoxGL.updateSource(CITY_SOURCE, this.mapBoxGL.emptyGeoJson)
        }

        let administrativeAreaContours = city.getAdministrativeAreas().getContours()
        if (!administrativeAreaContours) {
            const url = city.getAdministrativeAreas().getContoursUrl()
            if (url) {
                administrativeAreaContours = (await axios.get(url)).data as FeatureCollection
                city.getAdministrativeAreas().setContours(administrativeAreaContours)
            }
        }

        if (administrativeAreaContours) {
            administrativeAreaContours = administrativeAreaContours as FeatureCollection
            this.mapBoxGL.updateSource(ADMINISTRATIVE_AREA_SOURCE, administrativeAreaContours)
        } else {
            this.mapBoxGL.updateSource(ADMINISTRATIVE_AREA_SOURCE, this.mapBoxGL.emptyGeoJson)
        }

        return true
    }

    getSources(): string[] {
        return [CITY_SOURCE, ADMINISTRATIVE_AREA_SOURCE]
    }

    getLayers(): AnyLayer[] {
        return [
            {
                id: `${this.mapBoxGL.applicationPrefix}-cityContours-administrativeAreasContours`,
                type: 'line',
                source: ADMINISTRATIVE_AREA_SOURCE,
                minzoom: 7,
                paint: {
                    'line-width': 1.5,
                    'line-color': '#C368D9'
                }
            } as LineLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-cityContours`,
                type: 'line',
                source: CITY_SOURCE,
                paint: {
                    'line-blur': 1.0,
                    'line-width': 2.0,
                    'line-color': '#333333'
                }
            } as LineLayer,
            {
                id: `${this.mapBoxGL.applicationPrefix}-cityContours-administrativeAreasNames`,
                type: 'symbol',
                source: ADMINISTRATIVE_AREA_SOURCE,
                minzoom: 10,
                maxzoom: 13,
                layout: {
                    'text-field': ['get', 'Administrative Area'],
                    'text-allow-overlap': false,
                    'text-anchor': 'top',
                    'text-font': ['DIN Pro Medium'],
                    'text-size': 14
                },
                paint: {
                    'text-color': '#506f9f',
                    'text-halo-blur': 1,
                    'text-halo-width': 1.5,
                    'text-halo-color': '#fff',
                    'text-opacity': 1.0
                }
            } as SymbolLayer
        ]
    }

    getLayersWithPopup(): AnyLayer[] {
        return []
    }

    getCursors(): Map<string, string> {
        return new Map()
    }
}

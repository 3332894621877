import { render, staticRenderFns } from "./CommandBar.vue?vue&type=template&id=6ab48fbc&scoped=true&"
import script from "./CommandBar.vue?vue&type=script&lang=ts&"
export * from "./CommandBar.vue?vue&type=script&lang=ts&"
import style0 from "./CommandBar.vue?vue&type=style&index=0&id=6ab48fbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab48fbc",
  null
  
)

export default component.exports
export class Disclaimer {
    constructor(private link: string, private disclaimer: string) {}

    getLink(): string {
        return this.link
    }

    getDisclaimer(): string {
        return this.disclaimer
    }
}

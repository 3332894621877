import { User } from './User'

export class UserCollection {
    constructor(private users: User[], private totalUsers: number) {
        this.users = users
        this.totalUsers = totalUsers
    }

    getUsers(): User[] {
        return this.users
    }

    getTotalUsers(): number {
        return this.totalUsers
    }
}

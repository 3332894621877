//import { StationDTO } from '@/api/stations/dto/StationDTO'
//import { TimeSeriesDTO } from '@/api/stations/dto/TimeSeriesDTO'
import { StationAPI } from '@/api/stations/StationAPI'
import { Coordinates } from '@/model/Coordinates'
import { EventEmitter } from '@/model/EventEmitter'
import { Station } from '@/model/Station'
import { TimeSeries } from '@/model/TimeSeries'
import { Feature, FeatureCollection } from '@turf/turf'
import { GeoJsonProperties, Geometry } from 'geojson'
import { DateTime } from 'luxon'
import { IStationService, StationSelectionEventArgs } from './IStationService'

//import Stations from './mock/dijle_stations.json'

import { StationFactory } from './StationFactory'
import { TimeSeriesFactory } from './TimeSeriesFactory'

export class StationService implements IStationService {
    private api: StationAPI = new StationAPI()
    private pickedStations: Map<number, Station> = new Map()
    private readonly emptyGeoJson: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    }

    readonly stationSelectionChanged = new EventEmitter<StationSelectionEventArgs>()

    async toggleStation(station: Station): Promise<void> {
        if (this.pickedStations.has(station.no)) {
            this.pickedStations.delete(station.no)
            this.stationSelectionChanged.notify(new StationSelectionEventArgs('remove', [station]))
        } else {
            this.pickedStations.set(station.no, station)
            this.stationSelectionChanged.notify(new StationSelectionEventArgs('add', [station]))
        }
    }

    clearStations(): void {
        const deletedStations = [...this.pickedStations.values()]
        this.pickedStations.clear()
        if (deletedStations.length > 0) {
            this.stationSelectionChanged.notify(new StationSelectionEventArgs('remove', deletedStations))
        }
    }

    async getStationsOfCity(cityName: string): Promise<Station[]> {
        try {
            const stationsDTO = await this.api.getStationsOfCity(cityName)
            return Promise.resolve(StationFactory.fromDTO(stationsDTO))
        } catch (e) {
            return Promise.resolve([])
        }
    }

    async getStationsWithinCircle(coordinates: Coordinates, radius: number): Promise<Station[]> {
        try {
            const stationsDTO = await this.api.getStations(coordinates.getLatitude(), coordinates.getLongitude())
            const filteredStations = stationsDTO.filter(
                (dto) => coordinates.distanceFrom(dto.geolocation.latitude, dto.geolocation.longitude) < radius
            )
            return Promise.resolve(StationFactory.fromDTO(filteredStations))
        } catch (e) {
            return Promise.resolve([])
        }
    }

    async getTimeSeriesForStation(station: Station, timestamp: DateTime): Promise<TimeSeries[]> {
        const idsToDTO = await this.api.getTimeseriesForStationByTimestamp(station.id, timestamp.toUTC())
        return [TimeSeriesFactory.newFromDTO(station, Array.from(idsToDTO.values()))]
    }

    async getTimeSeriesForStationByPeriod(station: Station, start: DateTime, end: DateTime): Promise<TimeSeries[]> {
        const idsToDTO = await this.api.getTimeSeriesForStationByPeriod(station.id, start.toUTC(), end.toUTC())
        
        return Array.from(idsToDTO.values()).map((dto) => TimeSeriesFactory.fromDTO(station, dto))
    }

    mapToFeatureCollection(stations: Station[]): FeatureCollection<Geometry, GeoJsonProperties> {
        return {
            type: 'FeatureCollection',
            features: [...stations.map((s) => this.mapToFeature(s))]
        }
    }

    private mapToFeature(s: Station): Feature<Geometry, GeoJsonProperties> {
        return {
            type: 'Feature',
            id: s.no,
            geometry: {
                type: 'Point',
                coordinates: s.getLngLat()
            },
            properties: {
                name_no: `${s.name}`,
                no: s.no
            }
        }
    }
}

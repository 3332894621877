import { DateTime } from 'luxon'

import { EventAPI } from '@/api/event/EventAPI'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IEventService } from './IEventService'

import { EventCollectionFactory } from './EventCollectionFactory'

import { EventCollection } from '@/model/EventCollection'
import { City } from '@/model/City'

export class EventService extends BaseService implements IEventService {
    private readonly api: EventAPI = new EventAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    async getRainEvents(
        city: City,
        year: number,
        page: number,
        itemsPerPage: number,
        ascendingOrder: boolean
    ): Promise<EventCollection> {
        const dto = await this.api.getRainEvents(city.getName(), year, page, itemsPerPage, ascendingOrder)
        return EventCollectionFactory.fromDTO(dto)
    }

    async replayRainEvents(city: City, startDate: DateTime): Promise<void> {
        await this.api.replayRainEvents(city.getName(), startDate)
    }
}




































import { Component, Prop, Vue } from 'vue-property-decorator'

import { TimeStep } from '@/model/TimeStep'

import Slider from '@/components/timeline/Slider.vue'
import DateRangeAxis from '@/components/timeline/DateRangeAxis.vue'
import Heatline from '@/components/timeline/Heatline.vue'
import { IRepresentation } from '@/model/Representation'
import { DateTime } from 'luxon'

@Component({
    components: {
        DateRangeAxis,
        Heatline,
        Slider
    }
})
export default class HistoryLine extends Vue {
    @Prop() history!: History
    @Prop() timeStep!: TimeStep
    @Prop() representation!: IRepresentation
    @Prop() mouseClickEvt!: MouseEvent
    @Prop() mouseMoveEvt!: MouseEvent
    @Prop({ type: Boolean, default: false }) playModeActivated!: false

    onTimeStepChanged(ts: TimeStep): void {
        this.$emit('selected-timestep-changed', ts)
    }

    onDateTimeRangeChanged(start: DateTime, end: DateTime): void {
        this.$emit('datetime-range-changed', start, end)
    }
}

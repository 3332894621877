






















import { Component, Vue } from 'vue-property-decorator'

import { User } from '@/model/User'
import { UserPasswordUpdate } from '@/model/UserPasswordUpdate'
import { UserAlarmsUpdate } from '@/model/UserAlarmsUpdate'

import { showToast } from '@/utils/toast'

import { IUserService } from '@/services/user/IUserService'
import { ITranslationService } from '@/services/translation/ITranslationService'

import GeneralForm from './GeneralForm.vue'
import AlarmForm from './AlarmForm.vue'
import PasswordForm from './PasswordForm.vue'

@Component({
    components: {
        GeneralForm,
        PasswordForm,
        AlarmForm
    }
})
export default class UserSettings extends Vue {
    private userService!: IUserService
    private translationService!: ITranslationService

    private currentUser: User = new User({})

    created(): void {
        this.userService = this.$services.get<IUserService>('user')
        this.translationService = this.$services.get<ITranslationService>('translation')
    }

    async mounted(): Promise<void> {
        this.currentUser = await this.userService.getCurrentUser()
    }

    public async updateUserPassword(userSettings: UserPasswordUpdate): Promise<void> {
        try {
            await this.userService.updateUserPassword(userSettings)
            showToast('is-success', 3000, `${this.$t('dialogs.settings.user.section.password.success')}`)
        } catch (e) {
            showToast('is-danger', 3000, `${this.$t('dialogs.settings.user.section.password.failure')}`)
        }
    }

    public async saveAlarmsettings(userSettings: UserAlarmsUpdate): Promise<void> {
        try {
            await this.userService.updateUserSettings(userSettings)
            showToast('is-success', 3000, `${this.$t('dialogs.settings.user.section.alarms.success')}`)
        } catch (e) {
            showToast('is-danger', 3000, `${this.$t('dialogs.settings.user.section.alarms.failure')}`)
        }
    }

    async saveGeneralSettings(languageCode: string | null): Promise<void> {
        try {
            if (languageCode) {
                await this.userService.updateUserLanguage(languageCode)
                this.translationService.useLanguage(languageCode)
                showToast('is-success', 3000, `${this.$t('dialogs.settings.user.section.general.success')}`)
            }
        } catch (e) {
            showToast('is-danger', 3000, `${this.$t('dialogs.settings.user.section.general.failure')}`)
        }
    }
}

import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { EventDTO } from './EventDTO'
import { EventRaw } from './EventRaw'

export class EventDTOFactory {
    static fromRaw(raw: EventRaw): EventDTO {
        const cityName = raw.city
        const startDate = raw.start
        const endDate = raw.end
        const maxTTime = raw.maxT_time
        const maxTGeojson = raw.maxT_geojson
        const maxT = raw.maxT
        const max1hourVol = raw.max_1hour_volume_max
        const max1dayVol = raw.max_1day_volume_max
        const areaT5Plus = raw.area_T5_plus

        const mandatoryFields = [
            cityName,
            startDate,
            endDate,
            maxTTime,
            maxTGeojson,
            maxT,
            max1hourVol,
            max1dayVol,
            areaT5Plus
        ]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(EventDTOFactory.name, raw)
        }

        return new EventDTO(
            cityName as string,
            startDate as string,
            endDate as string,
            maxTTime as string,
            maxTGeojson as string,
            maxT as number,
            max1hourVol as number,
            max1dayVol as number,
            areaT5Plus as number
        )
    }
}

import { DateTime, Duration } from 'luxon'

import { City } from './City'
import { ITimeAndCityLocalizable } from './ITimeAndCityLocalizable'
import { TimeStep } from './TimeStep'

export class History implements ITimeAndCityLocalizable, Iterable<TimeStep> {
    private history: Array<TimeStep> = []

    constructor(readonly city: City, readonly start: DateTime, readonly end: DateTime) {
        this.city = city
        this.start = start.setZone(city.getTimezone())
        this.end = end.setZone(city.getTimezone())
    }

    *[Symbol.iterator](): Iterator<TimeStep> {
        for (const ts of this.history) {
            yield ts
        }
    }

    getReference(): TimeStep {
        return new TimeStep(this, this.timestamp, [], [], 0, 0, 0, 0, 0)
    }

    getStart(): DateTime {
        return this.start
    }

    getEnd(): DateTime {
        return this.end
    }

    get timestamp(): DateTime {
        return this.end.plus(
            Duration.fromObject({
                minute: 5
            })
        )
    }

    isEmpty(): boolean {
        for (const timeStep of this) {
            if (timeStep && !timeStep.isEmpty()) {
                return false
            }
        }
        return true
    }

    findTimeStepAt(timestamp: DateTime): TimeStep | null {
        const lookup = (timestamp.zoneName === 'UTC' ? timestamp : timestamp.toUTC()).toISO()

        const candidate = this.history.find((timeStep: TimeStep) => timeStep.timestamp.toUTC().toISO() === lookup)
        if (candidate) {
            return candidate
        }
        return null
    }

    nextTimeStep(timestamp: DateTime | null): TimeStep | null {
        if (timestamp == null) return null

        const lookup = (timestamp.zoneName === 'UTC' ? timestamp : timestamp.toUTC()).toSeconds()

        const candidate = this.history.find((timeStep: TimeStep, index) =>
            index > 0
                ? this.history[index - 1].timestamp.toUTC().toSeconds() === lookup
                : this.history[this.history.length - 1].timestamp.toUTC().toSeconds() === lookup
        )

        return candidate ?? null
    }

    addHistory(ts: TimeStep): void {
        this.history.push(ts)
    }
}

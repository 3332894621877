




















import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import CommandBar from '@/components/commands/CommandBar.vue'
import StationsTimeSeries from '@/components/station/StationsTimeSeries.vue'
import RainGaugesTimeSeries from '@/components/raingauge/RainGaugesTimeSeries.vue'
import OverviewMap from '../overview/map/OverviewMap.vue'
import Legends from "@/components/legend/Legends.vue"

import RepresentationSelector from '../overview/representation-selector/RepresentationSelector.vue'
import HistoryLine from './HistoryLine.vue'
import { ICityService } from '@/services/city/ICityService'
import { City } from '@/model/City'
import { DateTimeFactory } from '@/model/DateTimeFactory'
import { History as HistoryModel } from '@/model/History'

import { IHistoryService } from '@/services/history/IHistoryService'
import { allRepresentations, IRepresentation, Rainfall } from '@/model/Representation'
import { TimeStep } from '@/model/TimeStep'
import { IModeService, ModeChangedEventArgs } from '@/services/mode/IModeService'

const PLAY_MODE_REFRESH_RATE_IN_MILLIS = 1200

@Component({
    components: {
        Legends,
        OverviewMap,
        RepresentationSelector,
        CommandBar,
        HistoryLine,
        StationsTimeSeries,
        RainGaugesTimeSeries
    }
})
export default class History extends Vue {
    @Prop(String) cityId!: string
    @Prop(String) start!: string
    @Prop(String) end!: string

    //those fields will allow downstream event propagation
    mouseMoveEvt: MouseEvent | null = null
    mouseClickEvt: MouseEvent | null = null

    private representationId = Rainfall.id

    private city: City | null = null
    private representation: IRepresentation | null = null
    private startDt: DateTime | null = null
    private endDt: DateTime | null = null
    private history: HistoryModel | null = null
    private timeStep: TimeStep | null = null
    private playIntervalId: number | null = null

    private cityService!: ICityService
    private historyService!: IHistoryService
    private modeService!: IModeService

    async created(): Promise<void> {
        this.cityService = this.$services.get<ICityService>('city')
        this.historyService = this.$services.get<IHistoryService>('history')
        this.modeService = this.$services.get<IModeService>('mode')
        await this.modeService.setMode('history')
        await this.initialize()
    }

    beforeDestroy(): void {
        this.modeService.modeChanged.unsubscribe(this.onMainModeChanged)
        this.modeService.modeChanged.unsubscribe(this.onSecondaryModeChanged)
        if (this.playIntervalId) {
            window.clearInterval(this.playIntervalId)
            this.playIntervalId = null
        }
    }

    async initialize(): Promise<void> {
        this.listenToModeChange()

        this.setRepresentation()
        this.setCity(this.cityId)
        this.setDateRange(DateTime.fromISO(this.start), DateTime.fromISO(this.end))

        await this.loadHistory()
    }

    listenToModeChange(): void {
        this.modeService.modeChanged.subscribe(this.onMainModeChanged)
        this.modeService.modeChanged.subscribe(this.onSecondaryModeChanged)
    }

    async onSecondaryModeChanged(args: ModeChangedEventArgs): Promise<void> {
        if (args.newSecondaryMode === 'play') {
            this.playIntervalId = window.setInterval(() => this.tick(), PLAY_MODE_REFRESH_RATE_IN_MILLIS)
        } else if (args.newSecondaryMode === 'pause' && this.playIntervalId) {
            window.clearInterval(this.playIntervalId)
            this.playIntervalId = null
        }
    }

    async onMainModeChanged(args: ModeChangedEventArgs): Promise<void> {
        if (args.newMainMode === 'live') {
            this.$router.push({
                name: 'Overview',
                query: {
                    cityId: this.cityId,
                    mode: 'live'
                }
            })
        }
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    setRepresentation(): void {
        const representation = allRepresentations.find((representation) => representation.id === this.representationId)
        if (representation === undefined) {
            throw Error(`History.vue: representation ${representation} could not be found.`)
        }
        this.representation = representation
    }

    setCity(cityId: string): void {
        if (this.city && this.city.getName() === cityId) {
            return
        }

        const city = this.cityService.getCityCollection().getByName(cityId)
        if (!city) {
            throw Error(`History.vue: city ${cityId} could not be found.`)
        }
        this.city = city
    }

    setDateRange(rawStart: DateTime, rawEnd: DateTime): void {
        this.startDt = DateTimeFactory.utcRounded(rawStart)
        this.endDt = DateTimeFactory.utcRounded(rawEnd)
    }

    async loadHistory(): Promise<void> {
        if (!this.city) return
        if (!this.startDt) return
        if (!this.endDt) return
        if (!this.historyService) return

        this.history = await this.historyService.loadHistory(this.city, this.startDt, this.endDt)
        this.timeStep = this.history.findTimeStepAt(this.startDt)
    }

    onMouseMove(evt: MouseEvent): void {
        this.mouseMoveEvt = evt
    }

    onMouseClick(evt: MouseEvent): void {
        this.mouseClickEvt = evt
    }

    onTimelineTimeStepChanged(timeStep: TimeStep): void {
        this.timeStep = timeStep
    }

    private tick(): void {
        if (this.history && this.timeStep) {
            this.timeStep = this.history.nextTimeStep(this.timeStep.timestamp)
        }
    }

    @Watch('representationId')
    onRepresentationIdChanged(representationId: string): void {
        this.representationId = representationId
        this.setRepresentation()
    }

    @Watch('start')
    @Watch('end')
    onDateRangeChanged(): void {
        this.setDateRange(DateTime.fromISO(this.start), DateTime.fromISO(this.end))
        this.loadHistory()
    }

    onTimelineRangeChanged(start: DateTime, end: DateTime): void {
        this.$router.push({
            name: 'History',
            query: {
                cityId: this.cityId,
                start: start.toISO(),
                end: end.toISO()
            }
        })
    }
}

import { ToastProgrammatic as Toast } from 'buefy'

export function showToast(type: string, duration: number, message: string): void {
    Toast.open({
        type: type,
        duration: duration,
        position: 'is-bottom',
        message: message
    })
}

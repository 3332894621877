import { CatchmentDTO } from '@/api/core/dto/snapshot/CatchmentDTO'
import { Catchment } from '@/model/Catchment'
import { LevelOfDetailDTO } from '@/api/core/dto/snapshot/LevelOfDetailDTO'

export class CatchmentFactory {
    static createEmpty(): Catchment {
        return new Catchment(new Map())
    }

    static fromDTO(dto: CatchmentDTO): Catchment {
        return new Catchment(
            new Map(
                dto.levelsOfDetail.map((levelOfDetailDTO: LevelOfDetailDTO) => [
                    levelOfDetailDTO.scale,
                    levelOfDetailDTO.geojsonUrl
                ])
            )
        )
    }
}

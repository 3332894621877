import { IPasswordRecovery } from '@/api/password-recovery/IPasswordRecoveryAPI'
import { PasswordRecoveryAPI } from '@/api/password-recovery/PasswordRecoveryAPI'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IPasswordRecoveryService } from './IPasswordRecoveryService'

export class PasswordRecoveryService extends BaseService implements IPasswordRecoveryService {
    private readonly api: IPasswordRecovery = new PasswordRecoveryAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    async resetPasswordRequest(username: string, email: string): Promise<void> {
        await this.api.resetPasswordRequest(username, email)
    }

    async validateToken(token: string): Promise<void> {
        await this.api.validateToken(token)
    }

    async resetPassword(token: string, newPassword: string): Promise<void> {
        await this.api.resetPassword(token, newPassword)
    }
}

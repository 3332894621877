import { DateTime } from 'luxon'
import { MeasureCollection } from './MeasureCollection'
import { MeasureKind } from './MeasureKind'

export class RainGaugeTimeSeries {
    private period: [DateTime, DateTime]

    constructor(
        readonly intensityMeasureKind: MeasureKind,
        readonly rainfallIntensity: MeasureCollection,
        readonly accumulatedMeasureKind: MeasureKind,
        readonly rainfallAccumulated: MeasureCollection
    ) {
        const now = DateTime.now().toUTC().set({ second: 0, millisecond: 0 })
        this.period = [rainfallIntensity.getFirstTimestampOr(now), rainfallIntensity.getLastTimestampOr(now)]
    }

    getStart(zone = 'UTC'): DateTime {
        return this.period[0].setZone(zone)
    }

    getEnd(zone = 'UTC'): DateTime {
        return this.period[1].setZone(zone)
    }

    get hasRainfallIntensity(): boolean {
        return this.rainfallIntensity.length > 0
    }

    get hasRainfallAccumulated(): boolean {
        return this.rainfallAccumulated.length > 0
    }
}

export class EventEmitter<TEventArgs> {
    private handlers: Set<(arg: TEventArgs) => Promise<void>> = new Set()

    subscribe(handler: (arg: TEventArgs) => Promise<void>): void {
        if (!this.handlers.has(handler)) {
            this.handlers.add(handler)
        }
    }

    unsubscribe(handler: (arg: TEventArgs) => Promise<void>): void {
        if (this.handlers.has(handler)) {
            this.handlers.delete(handler)
        }
    }

    async notify(arg: TEventArgs): Promise<void> {
        const calls: Array<Promise<void>> = []
        for (const handler of this.handlers) {
            calls.push(handler(arg))
        }
        await Promise.all(calls)
    }
}

import { ThresholdDTO } from "@/api/setting/dto/ThresholdDTO"

export class LegendsDTO {
    constructor(
        readonly floodRisk: ThresholdDTO[],
        readonly rainfall: ThresholdDTO[],
        readonly returnPeriod: ThresholdDTO[],
        readonly floodDepth: ThresholdDTO[],
        readonly rainGauges: ThresholdDTO[]
    ) {}
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class InvalidDTOError {
    public name: string
    public dto: any

    constructor(name: string, dto: any) {
        this.name = name
        this.dto = dto
    }
}







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime, Duration, IANAZone, SystemZone } from 'luxon'
import { TranslateResult } from 'vue-i18n'

import { ITranslationService } from '@/services/translation/ITranslationService'

import { MonthNames, ShortDayNames } from './DaysMonths'

@Component({
    components: {}
})
export default class ModalDateTimeRangePicker extends Vue {
    @Prop({ type: Boolean }) activated!: boolean
    @Prop({ type: DateTime }) start!: DateTime
    @Prop({ type: DateTime }) end!: DateTime
    @Prop({ type: String }) timeZone!: string
    @Prop({ type: Number, default: 5 }) incrementMinutes!: number

    currentStep = 1

    naiveStart!: Date
    naiveStartTime!: Date
    naiveEnd!: Date
    naiveEndTime!: Date

    monthNames: Array<TranslateResult> = []
    dayNames: Array<TranslateResult> = []

    timeZoneObject!: IANAZone
    translationService: ITranslationService | null = null
    currentRange = ''

    created(): void {
        this.translationService = this.$services.get<ITranslationService>('translation')
        this.initialize()
    }

    initialize(): void {
        this.timeZoneObject = IANAZone.create(this.timeZone)
        this.naiveStart = this.convertToNaive(this.start)
        this.naiveStartTime = this.naiveStart
        this.naiveEnd = this.convertToNaive(this.end)
        this.naiveEndTime = this.naiveEnd

        this.monthNames = MonthNames.map((mn) => this.$t(mn))
        this.dayNames = ShortDayNames.map((dn) => this.$t(dn))
    }

    private convertToNaive(dateTime: DateTime): Date {
        const systemZoneOffset = SystemZone.instance.offset(dateTime.toMillis())
        const targetZoneOffset = this.timeZoneObject.offset(dateTime.toMillis())

        return dateTime
            .toUTC()
            .set({
                minute: Math.floor(dateTime.minute / this.incrementMinutes) * this.incrementMinutes
            })
            .plus(Duration.fromMillis((targetZoneOffset - systemZoneOffset) * 60 * 1000))
            .toJSDate()
    }

    get display(): boolean {
        return this.activated
    }

    set display(value: boolean) {
        if (!value) {
            this.$emit('closing')
        }
    }

    private toDatetime(jsDate: Date, jsTime: Date): DateTime {
        return DateTime.fromObject(
            {
                year: jsDate.getFullYear(),
                month: jsDate.getMonth() + 1,
                day: jsDate.getDate(),
                hour: jsTime.getHours(),
                minute: jsTime.getMinutes(),
                second: 0,
                millisecond: 0
            },
            { zone: this.timeZoneObject }
        )
    }

    @Watch('start')
    onCurrentDateTimeChanged(): void {
        if (!this.display) {
            this.initialize()
        }
    }

    onCancel(): void {
        this.display = false
    }

    onInputChanged(): void {
        const startDt = this.toDatetime(this.naiveStart, this.naiveStartTime)
        const endDt = this.toDatetime(this.naiveEnd, this.naiveEndTime)
        const duration = endDt.diff(startDt)
        this.currentRange = `${startDt.toFormat(this.$t('common.datetime.format').toString())} - ${endDt.toFormat(
            this.$t('common.datetime.format').toString()
        )}`

        if (duration.toMillis() <= 0) {
            this.$buefy.toast.open({
                duration: 3000,
                message: this.$t(`timeline.datetime.range.invalid`).toString() + this.currentRange,
                position: 'is-bottom',
                type: 'is-danger'
            })
        } else {
            this.$emit('datetime-range-changed', startDt.toUTC(), endDt.toUTC())
            this.onCancel()
        }
    }
}



























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { hiddenRepresentations, IRepresentation } from '@/model/Representation'

import { ContextFactory } from '@/views/secure/overview/ContextFactory'
import { Threshold } from '@/model/Threshold'

const LegendHeight = 156

@Component({})
export default class GradientLegend extends Vue {
    @Ref('colors-map') canvas!: HTMLCanvasElement

    @Prop() representation!: IRepresentation
    private hiddenRepresentations!: IRepresentation[]

    private min: number = Number.MAX_SAFE_INTEGER
    private max: number = Number.MIN_SAFE_INTEGER

    created(): void {
        this.hiddenRepresentations = hiddenRepresentations
    }

    mounted(): void {
        this.onCurrentRepresentationChanged()
    }

    get name(): string {
        return this.representation?.translationKey
    }

    get unit(): string {
        return this.representation?.unitTranslationKey
    }

    get shouldBeDisplayed(): boolean {
        return this.representation && !this.hiddenRepresentations.some((r) => r.id == this.representation.id)
    }

    get colorMapHeight(): number {
        return LegendHeight
    }

    get thresholdCount(): number {
        return this.representation.legend.thresholdCount
    }

    @Watch('representation')
    onCurrentRepresentationChanged(): void {
        if (!this.canvas) return
        if (this.representation.legend.thresholdCount == 0) {
            window.setTimeout(() => this.onCurrentRepresentationChanged(), 333)
            return
        }

        this.min = Number.MAX_SAFE_INTEGER
        this.max = Number.MIN_SAFE_INTEGER
        for (const threshold of this.representation.legend.thresholds) {
            if (this.min > threshold.value) {
                this.min = threshold.value
            }
            if (this.max < threshold.value) {
                this.max = threshold.value
            }
        }

        this.drawAll(ContextFactory.renderingContextFor(this.canvas))
    }

    private drawAll(ctx: CanvasRenderingContext2D): void {
        const legend = this.representation.legend

        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        const gradient = ctx.createLinearGradient(0, 0, 0, this.canvas.offsetHeight)
        legend.thresholds.forEach((threshold: Threshold, index: number) => {
            gradient.addColorStop(this.computePosition(threshold, index), threshold.color)
        })

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }

    computePosition(threshold: Threshold, index: number): number {
        const legend = this.representation.legend
        return index / (legend.thresholdCount - 1)
    }
}

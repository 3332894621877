




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'

import ModalDateTimeRangePicker from '@/components/datetime/ModalDateTimeRangePicker.vue'

import { History as HistoryModel } from '@/model/History'
import { TimeStepSequence } from '@/model/TimeStepSequence'

@Component({
    components: {
        ModalDateTimeRangePicker
    }
})
export default class DateRangeAxis extends Vue {
    @Prop() history!: HistoryModel
    @Prop({ type: Number, default: 3 }) ticksBefore!: number
    @Prop({ type: Number, default: 3 }) ticksAfter!: number
    @Prop({ type: DateTime }) start!: DateTime
    @Prop({ type: DateTime }) end!: DateTime

    timeStepsSequence!: TimeStepSequence
    ticks: number[] = []
    showModal = false

    created(): void {
        this.timeStepsSequence = TimeStepSequence.fromIterable(this.history, this.start, this.end)

        this.ticks = []
        for (let i = 0; i < this.ticksBefore; ++i) {
            this.ticks.push(this.correctedTick((i / this.ticksBefore) * 0.5))
        }
        this.ticks.push(0.5)
        for (let i = 1; i <= this.ticksAfter; ++i) {
            this.ticks.push(this.correctedTick(0.5 + (i / this.ticksAfter) * 0.5))
        }
    }

    private correctedTick(rawTick: number): number {
        const dt = this.timeStepsSequence.getDateTimeAt(rawTick).set({
            second: 0,
            millisecond: 0
        })
        const corrected = dt.set({ minute: Math.round(dt.minute / 5) * 5 })
        return this.timeStepsSequence.toRelative(corrected)
    }

    labelFor(tick: number): string {
        const dt = this.timeStepsSequence.getDateTimeAt(tick).setZone(this.history.city.getTimezone())
        const format = this.$t(
            tick === 0.0 || tick === 1.0 ? 'common.datetime.format.datetime' : 'common.datetime.format.time'
        ).toString()
        return dt.toFormat(format)
    }

    private onSetRangeRequested(): void {
        this.showModal = true
    }

    private onSetRangeClosing(): void {
        this.showModal = false
    }

    private onDateTimeRangeChanged(start: DateTime, end: DateTime): void {
        this.$emit('datetime-range-changed', start, end)
    }
}

import { FeatureCollection, GeoJsonProperties } from 'geojson'

import { IRepresentation } from '@/model/Representation'
import { IMapService } from '@/services/map/IMapService'
import { serviceLocatorInstance } from '@/services/ServiceLocator'
import { ITranslationService } from '@/services/translation/ITranslationService'
import { MapBoxGL } from '../MapBoxGL'
import { IController } from './IController'
import { BaseController } from './BaseController'
import { Legend } from '@/model/Legend'
import { IControllerParent } from './IControllerParent'

export type LayerEvent = (mapboxgl.MapLayerMouseEvent | mapboxgl.MapLayerTouchEvent) & mapboxgl.EventData

export class BaseRepresentationController extends BaseController implements IController {
    protected readonly representation: IRepresentation
    protected readonly translationService: ITranslationService

    protected readonly emptyGeoJson: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    }

    constructor(
        parent: IControllerParent,
        mapBoxGL: MapBoxGL,
        mapService: IMapService,
        representation: IRepresentation
    ) {
        super(parent, mapBoxGL, mapService)
        this.representation = representation
        this.translationService = serviceLocatorInstance.get<ITranslationService>('translation')
    }

    buildPopupText(properties: GeoJsonProperties): string | null {
        if (!properties) {
            return null
        }

        let unit = this.representation.unitTranslationKey
        const value: number = properties[this.representation.dynamicPropertyName]

        if (Math.round(value) < 2 && this.representation.hasSingularForm) {
            unit = this.representation.unitTranslationKey + '.singular'
        }

        return `
            <b>${this.translationService.translate(this.representation.legend.titleTranslationKey)}</b>
            <br />
            ${this.representation.format(value)}
            ${this.translationService.translate(unit)}
        `
    }

    unpackThresholds(legend: Legend): (string | number)[] {
        const result = []
        for (const threshold of legend.thresholds) {
            result.push(threshold.value)
            result.push(threshold.color)
        }
        return result
    }
}

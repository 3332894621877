import { AdministrativeAreaDTO } from "@/api/core/dto/snapshot/AdministrativeAreaDTO"
import { AdministrativeArea } from "@/model/AdministrativeArea"

export class AdministrativeAreaFactory {
    static createEmpty(): AdministrativeArea {
        return new AdministrativeArea('', 0)
    }

    static fromDTO(dto: AdministrativeAreaDTO): AdministrativeArea {
        return new AdministrativeArea(
            dto.area_name, dto.return_period
        )
    }
}

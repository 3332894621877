
















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { ContextFactory } from '@/views/secure/overview/ContextFactory'

import { TimeStep } from '@/model/TimeStep'
import { TimeStepSequence } from '@/model/TimeStepSequence'
import { DateTime } from 'luxon'

const BackgroundColor = '#C7D2E3'
const ThumbFillColor = '#EBEFF5'
const ThumbStrokeColor = '#607495'
const DefaultTickColor = '#607495FF'
const SelectedTickColor = '#9740ACFF'
const CanvasHeight = 20
const VerticalOffset = 10

@Component({
    components: {}
})
export default class Slider extends Vue {
    @Prop(TimeStep) current!: TimeStep
    @Prop() timeSteps!: Iterable<TimeStep>
    @Prop() start!: DateTime
    @Prop() end!: DateTime
    @Prop() mouseMoveEvt!: MouseEvent
    @Prop() mouseClickEvt!: MouseEvent
    @Prop({ type: Boolean, default: true }) activated!: boolean
    @Prop({ type: Boolean, default: false }) playModeActivated!: boolean

    @Ref('render-width') renderWidth!: HTMLDivElement
    @Ref('slider-canvas') canvas!: HTMLCanvasElement
    @Ref('cursor') cursor!: HTMLDivElement

    tooltipText = ''
    tooltipActive = false

    private timeStepsSequence!: TimeStepSequence
    private selectedIndex = 0
    private hoveredIndex = -1
    private dragging = false
    private renderingWidth = 0
    private renderingOffsetX = 0
    private intervalId!: number | null

    created(): void {
        this.timeStepsSequence = TimeStepSequence.fromIterable(this.timeSteps, this.start, this.end)
    }

    mounted(): void {
        if (this.current === null) {
            this.selectedIndex = -1
        } else {
            this.selectedIndex = this.timeStepsSequence.getRelativePosOf(this.current)
        }

        this.updateCursor()
        this.onResize()

        window.addEventListener('resize', this.onResize)
        this.intervalId = window.setInterval(() => this.redraw(), 33)
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.onResize)
        if (this.intervalId) {
            window.clearInterval(this.intervalId)
            this.intervalId = null
        }
    }

    //#region "Drawing"
    private redraw(): void {
        const ctx: CanvasRenderingContext2D = ContextFactory.renderingContextFor(this.canvas)
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        ctx.fillStyle = BackgroundColor
        ctx.fillRect(0, this.canvas.height / 2 - VerticalOffset, this.canvas.width, this.canvas.height / 2)

        this.drawTicks(ctx)
        if (this.activated) {
            this.drawHovered(ctx, this.hoveredIndex)
            this.drawThumb(ctx, this.selectedIndex)
            this.updateCursor()
        }
    }

    private drawTicks(ctx: CanvasRenderingContext2D) {
        if (!this.timeStepsSequence) return

        ctx.strokeStyle = DefaultTickColor
        ctx.lineWidth = this.dp(1)
        ctx.beginPath()

        const durationFactor = 1

        for (const [t] of this.timeStepsSequence.entries) {
            const asRelative = t * durationFactor
            const x = this.renderingOffsetX + Math.round(asRelative * this.renderingWidth)
            ctx.moveTo(x, (this.canvas.height * 3) / 4 - VerticalOffset)
            ctx.lineTo(x, this.canvas.height - VerticalOffset)
        }
        ctx.stroke()
        ctx.closePath()
    }

    private drawHovered(ctx: CanvasRenderingContext2D, index: number) {
        if (index == -1) return

        const asRelative = index

        ctx.strokeStyle = SelectedTickColor
        ctx.lineWidth = this.dp(2)
        ctx.beginPath()
        const x = this.renderingOffsetX + Math.round(asRelative * this.renderingWidth)
        ctx.moveTo(x, this.canvas.height / 2 - VerticalOffset)
        ctx.lineTo(x, this.canvas.height - VerticalOffset)
        ctx.stroke()
        ctx.closePath()
    }

    private drawThumb(ctx: CanvasRenderingContext2D, index: number): void {
        if (index == -1) return

        const asRelative = index
        const x = this.renderingOffsetX + Math.round(asRelative * this.renderingWidth)

        ctx.beginPath()
        ctx.lineWidth = this.dp(0.5)
        ctx.fillStyle = ThumbFillColor
        ctx.strokeStyle = ThumbStrokeColor
        ctx.ellipse(
            x,
            this.dp(CanvasHeight / 2),
            this.dp(CanvasHeight / 2 - 2.5),
            this.dp(CanvasHeight / 2 - 2.5),
            0,
            0,
            2 * Math.PI,
            false
        )
        ctx.fill()
        ctx.stroke()
        ctx.closePath()
    }

    //#endregion "Drawing"
    //#region "Event Handlers"
    onMouseEnter(): void {
        if (!this.activated) return
        if (this.playModeActivated) return

        this.activateTooltip(true)
    }

    onMouseLeave(): void {
        if (!this.activated) return
        if (this.dragging) return
        if (this.playModeActivated) return

        this.hoveredIndex = -1
        this.activateTooltip(false)
    }

    @Watch('mouseMoveEvt')
    onMouseMove(): void {
        if (this.mouseMoveEvt == null) return
        if (!this.activated) return
        if (this.playModeActivated) return

        const boundingRect: DOMRect = this.$el.getBoundingClientRect()

        const index = this.xToRelative(this.dp(this.mouseMoveEvt.clientX - boundingRect.left))

        if (index != -1) {
            if (this.dragging) {
                this.updateSelectedIndex(index)
                this.hoveredIndex = this.timeStepsSequence.getNearestRelative(index)
            } else if (this.pointInRect({ x: this.mouseMoveEvt.clientX, y: this.mouseMoveEvt.clientY }, boundingRect)) {
                this.hoveredIndex = this.timeStepsSequence.getNearestRelative(index)
            }
        }
    }

    private pointInRect(p: { x: number; y: number }, rect: DOMRect) {
        return rect.left <= p.x && p.x <= rect.right && rect.top <= p.y && p.y <= rect.bottom
    }

    @Watch('mouseClickEvt')
    onClick(): void {
        if (!this.dragging) return
        if (!this.activated) return

        const boundingRect: DOMRect = this.$el.getBoundingClientRect()
        const newSelectedIndex = this.xToRelative(this.dp(this.mouseClickEvt.clientX - boundingRect.left))

        if (newSelectedIndex != -1) {
            this.dragging = false
            this.updateSelectedIndex(newSelectedIndex)
        }
    }

    onMouseDown(evt: MouseEvent): void {
        if (!this.activated) return

        const index = this.xToRelative(this.dp(evt.offsetX))
        this.updateSelectedIndex(index)
        this.dragging = true
    }

    private xToRelative(x: number): number {
        const evtToRelative = Math.max(0, Math.min(1, (Math.round(x) - this.renderingOffsetX) / this.renderingWidth))
        return evtToRelative
    }

    onResize(): void {
        if (this.canvas == null) return

        this.canvas.width = this.dp(this.canvas.offsetWidth)
        this.canvas.height = this.dp(CanvasHeight)

        const style = window.getComputedStyle(this.renderWidth, null)
        this.renderingOffsetX = this.dp(parseInt(style.paddingLeft))
        this.renderingWidth =
            this.dp(this.renderWidth.clientWidth) - this.renderingOffsetX - this.dp(parseInt(style.paddingRight))
    }
    //#endregion "Event Handlers"
    //#region "Component State Update"
    private activateTooltip(activate: boolean): void {
        this.tooltipActive = activate && this.current !== null
    }

    private updateSelectedIndex(newSelectedIndex: number): void {
        if (newSelectedIndex < 0) return
        if (newSelectedIndex == this.selectedIndex) return

        const toEmit = this.timeStepsSequence.getFromRelative(newSelectedIndex)
        this.hoveredIndex = -1

        if (toEmit) {
            this.selectedIndex = this.timeStepsSequence.getRelativePosOf(toEmit)
            this.emitTimeStepChanged(toEmit)
        }
    }

    private emitTimeStepChanged(ts: TimeStep): void {
        this.$emit('time-step-changed', ts)
    }

    private updateCursor(): void {
        if (this.hoveredIndex === -1) return
        if (!this.timeStepsSequence) return

        const asRelative = this.hoveredIndex
        const ts = this.timeStepsSequence.getFromRelative(this.hoveredIndex)

        this.tooltipText = this.formatTimestamp(ts?.timestamp)
        this.cursor.style.left =
            this.dp(this.renderingOffsetX, true) + asRelative * this.dp(this.renderingWidth, true) + 'px'
    }

    private formatTimestamp(timestamp: DateTime | undefined): string {
        if (!timestamp) {
            return 'None'
        } else if (this.playModeActivated) {
            return timestamp.toFormat('HH:mm')
        } else {
            return timestamp.toFormat(this.$t('common.datetime.format.datetime').toString())
        }
    }

    private dp(value: number, reverse = false): number {
        return reverse ? value / window.devicePixelRatio : value * window.devicePixelRatio
    }

    @Watch('current')
    onCurrentTickChanged(): void {
        if (this.current == null) {
            this.selectedIndex = -1
            this.hoveredIndex = -1
        } else {
            const candidate = this.timeStepsSequence.getRelativePosOf(this.current)
            if (this.playModeActivated) {
                this.hoveredIndex = candidate
            }
            if (this.selectedIndex !== candidate) {
                this.selectedIndex = candidate
            }
        }
    }

    @Watch('timeSteps')
    onTimeStepsChanged(): void {
        this.timeStepsSequence = TimeStepSequence.fromIterable(this.timeSteps, this.start, this.end)
        this.selectedIndex = 0.5
    }

    @Watch('playModeActivated')
    onPlayModeActivated(): void {
        this.activateTooltip(this.playModeActivated)
        if (this.playModeActivated === false) {
            this.hoveredIndex = this.selectedIndex
        }
    }
    //#endregion "Component State Update"
}

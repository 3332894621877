import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import { serviceLocatorInstance } from '@/services/ServiceLocator'
import { User } from '@/model/User'
import { IUserService } from '@/services/user/IUserService'
import { Permissions } from '@/model/Permissions'

const isNotLiveOnlyGuard: NavigationGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
    const userService = serviceLocatorInstance.get<IUserService>('user')
    const currentUser: User = await userService.getCurrentUser()

    if (currentUser.permissions == Permissions.LIVE_ONLY) {
        console.log('Access denied to live only user')
        return
    }
    next()
}

export default isNotLiveOnlyGuard

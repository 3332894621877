import { Legend } from './Legend'

export interface IRepresentation {
    id: string
    translationKey: string
    unitTranslationKey: string
    legend: Legend
    dynamicPropertyName: string
    requiresCatchments: boolean
    hasSingularForm: boolean

    format(value: number): string
    colorMatching(value: number): string
}

export abstract class Representation implements IRepresentation {
    constructor(
        readonly id: string,
        readonly translationKey: string,
        readonly unitTranslationKey: string,
        public legend: Legend,
        readonly dynamicPropertyName: string,
        readonly requiresCatchments = false,
        readonly hasSingularForm = false
    ) {}

    abstract format(value: number): string

    colorMatching(value: number): string {
        return this.legend.getThresholdMatching(value).color
    }
}

class RainfallRepresentation extends Representation {
    constructor() {
        super(
            'rainfall',
            'model.representation.rainfall',
            'model.representation.rainfall.unit',
            new Legend('model.representation.rainfall', []),
            'intensity'
        )
    }

    format(value: number): string {
        return `${value.toFixed(0)}`
    }
}

class ReturnPeriodRepresentation extends Representation {
    constructor() {
        super(
            'returnPeriod',
            'model.representation.return_period',
            'model.representation.return_period.unit',
            new Legend('model.representation.return_period', []),
            'rt',
            false,
            true
        )
    }

    format(value: number): string {
        if (value < 1 && value.toFixed(0) != '1') {
            return `${value.toFixed(1)}`
        } else if (value > 1000) {
            return '> 1000'
        } else {
            return `${value.toFixed(0)}`
        }
    }
}

class FloodRiskRepresentation extends Representation {
    constructor() {
        super(
            'floodRisk',
            'model.representation.flood_risk',
            'model.representation.flood_risk.unit',
            new Legend('model.representation.flood_risk', [], 'distributed'),
            'risk'
        )
    }

    format(value: number): string {
        if (value === undefined || value === null) {
            return ''
        }

        const thresholds = this.legend.thresholds
        for (let i = thresholds.length - 1; i >= 0; --i) {
            const threshold = thresholds[i]
            if (value >= threshold.value) {
                return threshold.label
            }
        }
        return thresholds[0].label
    }
}

class FloodDepthRepresentation extends Representation {
    constructor() {
        super(
            'floodDepth',
            'model.representation.flood_depth',
            'model.representation.flood_depth.unit',
            new Legend('model.representation.flood_depth', []),
            'FloodRisk',
            true
        )
    }

    format(value: number): string {
        return `${value.toFixed(2)}`
    }
}

export const Rainfall = new RainfallRepresentation()
export const ReturnPeriod = new ReturnPeriodRepresentation()
export const FloodRisk = new FloodRiskRepresentation()
export const FloodDepth = new FloodDepthRepresentation()

export const allRepresentations: IRepresentation[] = [Rainfall, ReturnPeriod, FloodRisk, FloodDepth]
export const hiddenRepresentations: IRepresentation[] = [FloodRisk]

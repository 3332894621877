import { LegendsDTO } from './LegendsDTO'
import { LegendsRaw } from './SettingRaw'

export class LegendsDTOFactory {
    static fromRaw(raw: LegendsRaw): LegendsDTO {
        const floodDepth = raw.flood_depth
        const floodRisk = raw.flood_risk
        const rainfall = raw.rainfall
        const returnPeriod = raw.return_period
        const rainGauges = raw.rain_gauges

        return new LegendsDTO(
            floodRisk,
            rainfall,
            returnPeriod,
            floodDepth || [],
            rainGauges || []
        )
    }
}


























































import { Vue, Component } from 'vue-property-decorator'

import { showToast } from '@/utils/toast'

import { IPasswordRecoveryService } from '@/services/password-recovery/IPasswordRecoveryService'

@Component
export default class PasswordForgotten extends Vue {
    private username = ''
    private email = ''
    private error: string | null = null

    async recoverPassword(): Promise<void> {
        const passwordRecoveryService = this.$services.get<IPasswordRecoveryService>('password')
        try {
            await passwordRecoveryService.resetPasswordRequest(this.username, this.email)
            showToast('is-success', 3000, `${this.$t('dialogs.password_forgotten.success')}`)
        } catch (error) {
            this.error = 'dialogs.password_forgotten.failure'
        }
    }

    redirectUser(view: string): void {
        this.$router.push({ name: view })
    }

    get canSubmit(): boolean {
        return this.username.trim() !== '' && this.email.trim() !== ''
    }
}

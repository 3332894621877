import { DateTime } from 'luxon'
import { MeasureCollection } from './MeasureCollection'
import { MeasureKind } from './MeasureKind'
import { Station } from './Station'

export class TimeSeries {
    private period: [DateTime, DateTime]

    constructor(
        readonly id: string,
        readonly station: Station,
        readonly waterLevelMeasureKind: MeasureKind,
        readonly waterLevelHistory: MeasureCollection,
        readonly waterLevelForecasts: MeasureCollection,
        readonly flowMeasureKind: MeasureKind,
        readonly flowHistory: MeasureCollection
    ) {
        const now = DateTime.now().toUTC().set({ second: 0, millisecond: 0 })
        const first = waterLevelHistory.length > 0 ? waterLevelHistory : waterLevelForecasts
        const last = waterLevelForecasts.length > 0 ? waterLevelForecasts : waterLevelHistory
        this.period = [first.getFirstTimestampOr(now), last.getLastTimestampOr(now)]
    }

    getStart(zone = 'UTC'): DateTime {
        return this.period[0].setZone(zone)
    }

    getEnd(zone = 'UTC'): DateTime {
        return this.period[1].setZone(zone)
    }

    get hasHistory(): boolean {
        return this.waterLevelHistory.length > 0
    }

    get hasForecasts(): boolean {
        return this.waterLevelForecasts.length > 0
    }

    get hasWaterLevelHistory(): boolean {
        return this.waterLevelHistory.length > 0
    }

    get hasWaterLevelForecasts(): boolean {
        return this.waterLevelForecasts.length > 0
    }

    get hasFlowHistory(): boolean {
        return this.flowHistory.length > 0
    }
}

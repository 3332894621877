























import { Mode } from '@/model/Mode'
import { IModeService, ModeChangedEventArgs } from '@/services/mode/IModeService'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CommandBar extends Vue {
    @Prop({ type: String, default: 'is-top' }) tooltipPosition!: 'is-top' | 'is-bottom'

    private modeService!: IModeService

    mode: Mode = 'pause'
    isLive = true
    isPlay = true

    created(): void {
        this.modeService = this.$services.get<IModeService>('mode')
        this.modeService.modeChanged.subscribe(this.onModeChanged)
        this.mode = this.modeService.getSecondaryMode()
    }

    beforeDestroy(): void {
        this.modeService.modeChanged.unsubscribe(this.onModeChanged)
    }

    async onModeChanged(args: ModeChangedEventArgs): Promise<void> {
        if (args.newSecondaryMode == 'play' || args.newSecondaryMode == 'pause' || args.newSecondaryMode == 'max') {
            this.mode = args.newSecondaryMode
        }
    }

    // get classForLiveOrFreeButton(): string {
    //     if (this.mode === 'live' || this.mode === 'free') {
    //         return `button ${this.mode === 'live' ? 'is-primary' : 'is-danger'}`
    //     }
    //     return 'button is-light'
    // }

    get classForPlayOrPauseButton(): string {
        if (this.mode === 'play' || this.mode === 'pause') {
            return `button ${this.isPlay ? 'is-primary' : 'is-danger'}`
        }
        return 'button is-light'
    }

    get classForMaxButton(): string {
        return this.mode === 'max' ? 'button is-primary' : 'button is-light'
    }

    // get labelForLiveOrFreeButton(): string {
    //     let result = null
    //     switch (this.mode) {
    //         case 'live':
    //             result = this.$t('components.commandbar.free')
    //             break
    //         case 'free':
    //             result = this.$t('components.commandbar.live')
    //             break
    //         default:
    //             result = this.isLive ? this.$t('components.commandbar.live') : this.$t('components.commandbar.free')
    //     }
    //     return result as string
    // }

    get labelForPlayOrPauseButton(): string {
        return (this.isPlay ? this.$t('components.commandbar.play') : this.$t('components.commandbar.pause')) as string
    }

    // @Watch('desiredMode')
    // onDesiredModeChanged(mode: Mode): void {
    //     switch (mode) {
    //         // case 'live':
    //         //     this.isLive = true
    //         //     this.isPlay = true
    //         //     break
    //         // case 'free':
    //         //     this.isLive = false
    //         //     this.isPlay = true
    //         //     break
    //         case 'play':
    //             this.isPlay = false
    //             break
    //         case 'pause':
    //             this.isPlay = true
    //             break
    //         case 'max':
    //             this.isPlay = true
    //     }

    //     this.mode = mode
    // }

    // onLiveOrFreeButtonClicked(): void {
    //     this.isPlay = true
    //     if (this.mode === 'live' || this.mode === 'free') {
    //         this.isLive = !this.isLive
    //     }
    //     this.mode = this.isLive ? 'live' : 'free'
    //     this.$emit('mode-changed', this.mode)
    // }

    onPlayOrPauseButtonClicked(): void {
        if (this.mode === 'play' || this.mode === 'pause') {
            this.isPlay = !this.isPlay
            this.mode = this.mode === 'play' ? 'pause' : 'play'
        } else {
            this.mode = 'play'
            this.isPlay = false
        }
        this.modeService.setMode(this.mode)
    }

    onMaxButtonClicked(): void {
        if (this.mode === 'max') {
            this.mode = 'pause'
        } else {
            this.isPlay = true
            this.mode = 'max'
        }
        this.modeService.setMode(this.mode)
    }
}

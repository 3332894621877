import { Coordinates } from '@/model/Coordinates'
import { EventEmitter } from '@/model/EventEmitter'
import { Station } from '@/model/Station'
import { TimeSeries } from '@/model/TimeSeries'
import { FeatureCollection } from 'geojson'
import { DateTime } from 'luxon'

type SelectionAction = 'add' | 'remove'

export class StationSelectionEventArgs {
    constructor(readonly action: SelectionAction, readonly stations: Station[]) {}
}

export interface IStationService {
    readonly stationSelectionChanged: EventEmitter<StationSelectionEventArgs>

    getTimeSeriesForStation(station: Station, timestamp: DateTime): Promise<Array<TimeSeries>>
    getTimeSeriesForStationByPeriod(station: Station, start: DateTime, end: DateTime): Promise<Array<TimeSeries>>

    getStationsOfCity(cityName: string): Promise<Station[]>
    getStationsWithinCircle(coordinates: Coordinates, radius: number): Promise<Array<Station>>
    mapToFeatureCollection(stations: Station[]): FeatureCollection

    toggleStation(station: Station): Promise<void>
    clearStations(): void
}

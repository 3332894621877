import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { TimeStepDTOFactory } from '../snapshot/TimeStepDTOFactory'
import { HistoryDTO } from './HistoryDTO'
import { HistoryRaw } from './HistoryRaw'

export class HistoryDTOFactory {
    static fromRaw(raw: HistoryRaw): HistoryDTO {
        const city = raw.city
        const start = raw.start
        const end = raw.end
        const history = raw.history
        

        const mandatoryFields = [city, start, history, end]
        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(HistoryDTOFactory.name, raw)
        }

        return new HistoryDTO(
            city as string,
            start as string,
            end as string,
            history.map(TimeStepDTOFactory.fromRaw)            
        )
    }
}

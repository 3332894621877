import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'
import { CustomerHeadersDTO } from '../translation/dto/CustomerHeadersDTO'
import { DisclaimerDTO } from '../translation/dto/DisclaimerDTO'
import { DisclaimerDTOFactory } from '../translation/dto/DisclaimerDTOFactory'
import { DisclaimerRaw } from '../translation/dto/DisclaimerRaw'

export class TranslationAPI {
    private routes = {
        getTranslations: makeURL('/api/languages/${code}'),
        getAvailableLanguages: makeURL('/api/languages'),
        getCustomerHeaders: makeURL('/api/languages/customer-overview-headers'),
        getDisclaimer: makeURL('/api/languages/disclaimer/${code}')
    }

    public async getTranslations(languageCode: string): Promise<Map<string, string>> {
        try {
            let url = this.routes.getTranslations
            url = url.replace('${code}', languageCode)
            const result = await axios.get(url)
            return result.data['dictionary']
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async getAvailableLanguages(): Promise<Map<string, string>> {
        try {
            const result = await axios.get(this.routes.getAvailableLanguages)

            const languageCodes = new Map()
            for (const language of result.data) {
                languageCodes.set(language.code, language.name)
            }

            return languageCodes
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async getDisclaimer(code: string): Promise<DisclaimerDTO> {
        try {
            let url = this.routes.getDisclaimer
            url = url.replace('${code}', code)
            const disclaimerRaw = await axios.get(url)

            return DisclaimerDTOFactory.fromRaw(disclaimerRaw.data as unknown as DisclaimerRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async getCustomerHeaders(): Promise<CustomerHeadersDTO | null> {
        try {
            const url = this.routes.getCustomerHeaders
            const response = await axios.get(url)
            return response.data as CustomerHeadersDTO
        } catch (e) {
            const error = e as AxiosError<ServerError>

            // the file with the customer headers is optional, a 404 in this case means that this file is not
            // present, so we handle this different from other possible API errors
            if (error.response?.status === 404) {
                return null
            }

            throw ApiError.from(error.response?.data)
        }
    }
}

import { Legend } from './Legend'

export class Setting {
    constructor(private defaultLanguage: string, private cellSize: number, private legends: Map<string, Legend>, private hasRainGauges: boolean) {}

    getDefaultLanguage(): string {
        return this.defaultLanguage
    }

    getCellSize(): number {
        return this.cellSize
    }

    getHasRainGauges(): boolean {
        return this.hasRainGauges
    }

    getLegend(key: string): Legend {
        if (!key) {
            throw new Error('Setting.getLegend.noKey')
        }
        if (!this.legends.has(key)) {
            throw new Error('Setting.getLegend.keyNotFound')
        }
        return this.legends.get(key) as Legend
    }
}











































import { TranslateResult } from 'vue-i18n'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime, Duration, IANAZone, SystemZone } from 'luxon'

import { ITranslationService } from '@/services/translation/ITranslationService'
import { MonthNames, ShortDayNames } from './DaysMonths'

@Component({
    components: {}
})
export default class ModalDatetimePicker extends Vue {
    @Prop({ type: Boolean }) activated!: boolean
    @Prop({ type: DateTime }) datetime!: DateTime
    @Prop({ type: String }) timeZone!: string
    @Prop({ type: Number, default: 5 }) incrementMinutes!: number

    currentStep = 1

    naiveCurrentDate!: Date
    naiveCurrentTime!: Date

    timeZoneObject!: IANAZone
    translationService: ITranslationService | null = null

    monthNames: Array<TranslateResult> = []
    dayNames: Array<TranslateResult> = []

    created(): void {
        this.translationService = this.$services.get<ITranslationService>('translation')

        this.initialize()
    }

    mounted(): void {
        this.initialize()
    }

    initialize(): void {
        this.timeZoneObject = IANAZone.create(this.timeZone)
        const naiveDateTime = this.convertToNaive(this.datetime)

        this.naiveCurrentDate = naiveDateTime
        this.naiveCurrentTime = naiveDateTime
    }

    beforeUpdate() {
        this.monthNames = MonthNames.map((mn) => this.$t(mn))
        this.dayNames = ShortDayNames.map((dn) => this.$t(dn))
    }

    private convertToNaive(dateTime: DateTime): Date {
        const systemZoneOffset = SystemZone.instance.offset(this.datetime.toMillis())
        const targetZoneOffset = this.timeZoneObject.offset(this.datetime.toMillis())

        return dateTime
            .toUTC()
            .set({
                minute: Math.floor(this.datetime.minute / this.incrementMinutes) * this.incrementMinutes
            })
            .plus(Duration.fromMillis((targetZoneOffset - systemZoneOffset) * 60 * 1000))
            .toJSDate()
    }

    get display(): boolean {
        return this.activated
    }

    set display(value: boolean) {
        if (!value) {
            this.$emit('closing')
        }
    }

    private mergeDateAndTime(): DateTime {
        return DateTime.fromObject(
            {
                year: this.naiveCurrentDate.getFullYear(),
                month: this.naiveCurrentDate.getMonth() + 1,
                day: this.naiveCurrentDate.getDate(),
                hour: this.naiveCurrentTime.getHours(),
                minute: this.naiveCurrentTime.getMinutes(),
                second: 0,
                millisecond: 0
            },
            { zone: this.timeZoneObject }
        )
    }

    @Watch('datetime')
    onCurrentDateTimeChanged(): void {
        if (!this.display) {
            this.initialize()
        }
    }

    onCancel(): void {
        this.display = false
    }

    onInputChanged(): void {
        this.$emit('datetime-changed', this.mergeDateAndTime().toUTC())
        this.onCancel()
    }
}

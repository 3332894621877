import { DateTime } from 'luxon'

import { EventAPI } from '@/api/event/EventAPI'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IStatisticService } from './IStatisticService'

import { StatisticCollectionFactory } from './StatisticCollectionFactory'

import { StatisticCollection } from '@/model/StatisticCollection'
import { City } from '@/model/City'

export class StatisticService extends BaseService implements IStatisticService {
    private readonly api: EventAPI = new EventAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    async getCityStatistics(
        city: City,
        page: number,
        itemsPerPage: number,
        ascendingOrder: boolean,
        from?: DateTime,
        to?: DateTime
    ): Promise<StatisticCollection> {
        const dto = await this.api.getCityStatistics(city.getName(), page, itemsPerPage, ascendingOrder, from, to)
        return StatisticCollectionFactory.fromDTO(dto)
    }
}

import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { SettingDTO } from './dto/SettingDTO'
import { SettingDTOFactory } from './dto/SettingDTOFactory'
import { SettingRaw } from './dto/SettingRaw'
// import jsonDoc from './mocks/settings.json'

export class SettingAPI {
    private routes = {
        getSettings: makeURL('/api/settings'),
    }

    public async getSettings(): Promise<SettingDTO> {
        try {
            const rawSettings = await axios.get(this.routes.getSettings)
            return SettingDTOFactory.fromRaw(rawSettings.data as unknown as SettingRaw)
            // return SettingDTOFactory.fromRaw(jsonDoc as SettingRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}

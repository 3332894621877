import { EventEmitter } from '@/model/EventEmitter'
import { Mode } from '@/model/Mode'

export class ModeChangedEventArgs {
    constructor(
        readonly oldMainMode: Mode, 
        readonly oldSecondaryMode:Mode, 
        readonly newMainMode: Mode,
        readonly newSecondaryMode:Mode) {}
}

export interface IModeService {
    readonly modeChanged: EventEmitter<ModeChangedEventArgs>

    setMode(newMode: Mode): Promise<void>
    getMainMode(): Mode
    getSecondaryMode(): Mode
}

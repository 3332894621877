import { StationDTO } from '@/api/stations/dto/StationDTO'
import { Station } from '@/model/Station'

export class StationFactory {
    static fromDTO(stationsDTO: Array<StationDTO>): Array<Station> {
        return stationsDTO.map(
            (dto) =>
                new Station(
                    dto.id,
                    dto.no,
                    dto.name,
                    [dto.geolocation.latitude, dto.geolocation.longitude],
                    dto.station_type,
                    [dto.water_level_min, dto.water_level_max]
                )
        )
    }
}

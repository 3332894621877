import { RainGaugeDTO } from '@/api/raingauges/dto/RainGaugeDTO'
import { RainGauge } from '@/model/RainGauge'

export class RainGaugeFactory {
    static fromDTO(rainGaugeDTOs: Array<RainGaugeDTO>): Array<RainGauge> {
        // TODO finish the stations/raingauges unification so the empty array can go
        return rainGaugeDTOs.map(
            (dto) =>
                new RainGauge(
                    dto.id,
                    dto.name,
                    [],
                    [dto.geolocation.latitude, dto.geolocation.longitude],
                    dto.rainfall
                )
        )
    }
}









import { CityCollection } from '@/model/CityCollection'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Logo from './Logo.vue'
import Menu from './Menu.vue'

@Component({
    components: {
        Logo,
        Menu
    }
})
export default class Navbar extends Vue {
    @Prop({ type: CityCollection, default: () => new CityCollection([]) })
    cityCollection?: CityCollection
}

import { SettingAPI } from '@/api/setting/SettingAPI'

import { Setting } from '@/model/Setting'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { ISettingService } from './ISettingService'
import { SettingFactory } from './SettingFactory'

export class SettingService extends BaseService implements ISettingService {
    protected settings: Setting | null = null

    private readonly api: SettingAPI = new SettingAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    public async getSettings(): Promise<Setting> {
        if (this.settings == null) {
            await this.loadSettings()
        }

        return this.settings as Setting
    }

    private async loadSettings(): Promise<void> {
        const dto = await this.api.getSettings()
        this.settings = SettingFactory.fromSettingDTO(dto)
    }
}

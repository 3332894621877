import { StatisticDTO } from './StatisticDTO'
import { StatisticsRaw } from './StatisticsRaw'

export class StatisticDTOFactory {
    static fromRaw(raw: StatisticsRaw): StatisticDTO {
        const timestamp = raw.timestamp
        const maxT = raw.maxT
        const max1hourVol = raw.rainfall_1hour_vol_max
        const max1dayVol = raw.rainfall_1day_vol_max
        const areaT5Plus = raw.area_T5_plus

        // TODO: check if null
        return new StatisticDTO(
            timestamp as string,
            maxT as number,
            max1hourVol as number,
            max1dayVol as number,
            areaT5Plus as number
        )
    }
}

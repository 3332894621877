





















import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import { hiddenRepresentations, IRepresentation } from '@/model/Representation'

import { ContextFactory } from '@/views/secure/overview/ContextFactory'
import { Threshold } from '@/model/Threshold'
import { ISettingService} from "@/services/setting/ISettingService"
import { Legend } from "@/model/Legend"

@Component({})
export default class RainGaugesLegend extends Vue {
    @Ref('colors-map') canvas!: HTMLCanvasElement

    @Prop() representation!: IRepresentation
    private hiddenRepresentations!: IRepresentation[]

    settingService: ISettingService | null = null

    private readonly canvasWidth = 25
    private readonly canvasHeight = 25
    private legend: Legend | null = null

    private min: number = Number.MAX_SAFE_INTEGER
    private max: number = Number.MIN_SAFE_INTEGER

    created(): void {
        this.hiddenRepresentations = hiddenRepresentations
        this.settingService = this.$services.get<ISettingService>('setting')
    }

    async mounted(): Promise<void> {
        if (!this.settingService) {
            return
        }
        const settings = await this.settingService.getSettings()
        this.legend = settings.getLegend("rain_gauges")
        this.generateLegendImages()
    }

    get name(): string {
        return this.representation?.translationKey
    }

    get unit(): string {
        return this.representation?.unitTranslationKey
    }

    get shouldBeVisible(): boolean {
        return this.representation && !this.hiddenRepresentations.some((r) => r.id == this.representation.id)
    }

    @Watch('representation')
    onCurrentRepresentationChanged(): void {
        if (!this.canvas) return
        if (this.representation.legend.thresholdCount == 0) {
            window.setTimeout(() => this.onCurrentRepresentationChanged(), 333)
            return
        }

        this.min = Number.MAX_SAFE_INTEGER
        this.max = Number.MIN_SAFE_INTEGER
        for (const threshold of this.representation.legend.thresholds) {
            if (this.min > threshold.value) {
                this.min = threshold.value
            }
            if (this.max < threshold.value) {
                this.max = threshold.value
            }
        }

        this.drawAll(ContextFactory.renderingContextFor(this.canvas))
    }

    private drawAll(ctx: CanvasRenderingContext2D): void {
        const legend = this.representation.legend

        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        const gradient = ctx.createLinearGradient(0, 0, 0, this.canvas.offsetHeight)
        legend.thresholds.forEach((threshold: Threshold, index: number) => {
            gradient.addColorStop(this.computePosition(threshold, index), threshold.color)
        })

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    }

    computePosition(threshold: Threshold, index: number): number {
        const legend = this.representation.legend
        return index / (legend.thresholdCount - 1)
    }

    private generateLegendImages(): void {
        if (!this.legend) {
            return
        }
        this.legend.thresholds.forEach((threshold, i) => {
            this.generateLegendImage(threshold, i)
        })
    }

    private getLegends(): Threshold[] {
        if (!this.legend) {
            return[]
        }
        return this.legend.thresholds
    }

    private generateLegendImage(threshold: Threshold, i: number): void {
        const image = this.$refs.image as HTMLImageElement
        const canvasArray = this.$refs['canvas' + i] as HTMLCanvasElement[]

        if (!canvasArray) {
            return
        }

        const canvas = canvasArray[0]

        const { color } = threshold

        const context = canvas.getContext('2d') as CanvasRenderingContext2D

        // Draw the image onto the canvas
        context.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);

        // Get image data from the canvas
        const imageData = context.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
        const data = imageData.data;

        // Iterate through each pixel and apply color change
        for (let i = 0; i < data.length; i += 4) {
            // Assuming the image has a white background, change non-transparent pixels
            if (data[i + 3] > 0) {
                data[i] = parseInt(color.slice(1, 3), 16) // Red component
                data[i + 1] = parseInt(color.slice(3, 5), 16) // Green component
                data[i + 2] = parseInt(color.slice(5, 7), 16) // Blue component
            }
        }

        // Put the modified image data back onto the canvas
        context.putImageData(imageData, 0, 0);

        // Make the canvas visible
        canvas.style.display = 'block';
    }
}

import { TimeStepDTO } from './TimeStepDTO'
import { TimeStepMaxDTO } from './TimeStepMaxDTO'

export class SnapshotDTO {
    constructor(
        readonly city: string,
        readonly timestamp: string,
        readonly history: Array<TimeStepDTO>,
        readonly reference: TimeStepDTO,
        readonly forecasts: Array<TimeStepDTO>,
        readonly maxFrame: TimeStepMaxDTO
    ) {}
}

import { CatchmentDTOFactory } from './CatchmentDTOFactory'
import { TimeStepMaxDTO } from './TimeStepMaxDTO'
import { TimeStepMaxRaw } from './TimeStepMaxRaw'

export class TimeStepMaxDTOFactory {
    static fromRaw(raw: TimeStepMaxRaw): TimeStepMaxDTO {
        const administrativeAreas = raw.administrative_areas
        const catchments = raw.catchments

        return new TimeStepMaxDTO(administrativeAreas, catchments.map(CatchmentDTOFactory.fromRaw))
    }
}

import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { EventCollectionDTO } from './dto/events/EventCollectionDTO'
import { EventCollectionDTOFactory } from './dto/events/EventCollectionDTOFactory'
import { EventCollectionRaw } from './dto/events/EventCollectionRaw'
import { StatisticCollectionDTO } from './dto/statistics/StatisticCollectionDTO'
import { StatisticCollectionDTOFactory } from './dto/statistics/StatisticCollectionDTOFactory'
import { StatisticCollectionRaw } from './dto/statistics/StatisticCollectionRaw'

export class EventAPI {
    private routes = {
        getRainEvents: makeURL('/api/cities/${cityName}/rain_events/${year}'),
        getCityStatistics: makeURL('/api/cities/${cityName}/statistics'),
        replayRainEvents: makeURL('/api/cities/${cityName}/rain_events/replay_alarms/${startDate}')
    }

    public async getRainEvents(
        cityName: string,
        year: number,
        page: number,
        itemsPerPage: number,
        ascendingOrder: boolean
    ): Promise<EventCollectionDTO> {
        try {
            const params = {
                page: page,
                take: itemsPerPage,
                sorting: ascendingOrder ? 'asc' : 'desc'
            }

            let url = this.routes.getRainEvents
            url = url.replace('${cityName}', cityName)
            url = url.replace('${year}', year.toString())
            const result = await axios.get(url, {
                params: params
            })

            return EventCollectionDTOFactory.fromRaw(result.data as unknown as EventCollectionRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async getCityStatistics(
        cityName: string,
        page: number,
        itemsPerPage: number,
        ascendingOrder: boolean,
        from?: DateTime,
        to?: DateTime
    ): Promise<StatisticCollectionDTO> {
        try {
            const params: any = {
                page: page,
                take: itemsPerPage,
                sorting: ascendingOrder ? 'asc' : 'desc'
            }
            if (from) params.from = from.toISO()
            if (to) params.to = to.toISO()

            let url = this.routes.getCityStatistics
            url = url.replace('${cityName}', cityName)
            const result = await axios.get(url, {
                params: params
            })

            return StatisticCollectionDTOFactory.fromRaw(result.data as unknown as StatisticCollectionRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async replayRainEvents(cityName: string, startDate: DateTime): Promise<void> {
        try {
            let url = this.routes.replayRainEvents
            url = url.replace('${cityName}', cityName)
            url = url.replace('${startDate}', startDate.toISO())
            await axios.post(url)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}

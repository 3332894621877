import axios, { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import { makeURL } from '../config'
import { ApiError } from '../errors/ApiError'
import { ServerError } from '../errors/ServerError'
import { StationDTO } from './dto/StationDTO'
import { TimeSeriesDTO } from './dto/TimeSeriesDTO'

export class StationAPI {
    private routes = {
        getStationsOfCity: makeURL('/api/stations/${city_name}'),
        getStations: makeURL('/api/stations/${latitude}/${longitude}/${radius}'),
        getTimeSeries: makeURL('/api/stations/${station_id}/timeseries/${start}/${end}'),
        getForecasts: makeURL('/api/stations/${station_id}/timeseries/${timestamp}')
    }

    async getStationsOfCity(cityName: string): Promise<StationDTO[]> {
        try {
            const url = this.routes.getStationsOfCity.replace('${city_name}', cityName.toString())
            const rawStations = await axios.get(url)

            const res = rawStations.data as StationDTO[]
            return res
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getStations(lat: number, longitude: number, radius = 0.000009): Promise<StationDTO[]> {
        try {
            const url = this.routes.getStations
                .replace('${latitude}', lat.toString())
                .replace('${longitude}', longitude.toString())
                .replace('${radius}', radius.toString())
            const rawStations = await axios.get(url)

            return rawStations.data as StationDTO[]
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getTimeseriesForStationByTimestamp(
        stationId: number,
        utcTimestamp: DateTime
    ): Promise<Map<string, TimeSeriesDTO>> {
        try {
            const url = this.routes.getForecasts
                .replace('${station_id}', stationId.toString())
                .replace('${timestamp}', utcTimestamp.toISO())
            const response = await axios.get(url)
            const result = new Map<string, TimeSeriesDTO>()

            for (const [id, raw] of Object.entries(response.data.timeseries)) {
                result.set(id, raw as TimeSeriesDTO)
            }
            return result
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getTimeSeriesForStationByPeriod(
        stationId: number,
        utcStart: DateTime,
        utcEnd: DateTime
    ): Promise<Map<string, TimeSeriesDTO>> {
        try {
            const url = this.routes.getTimeSeries
                .replace('${station_id}', stationId.toString())
                .replace('${start}', utcStart.toISO())
                .replace('${end}', utcEnd.toISO())
            const response = await axios.get(url)
            const result = new Map<string, TimeSeriesDTO>()

            for (const [id, raw] of Object.entries(response.data.timeseries)) {
                result.set(id, raw as TimeSeriesDTO)
            }
            return result
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}

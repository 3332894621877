import { StatisticCollectionDTO } from './StatisticCollectionDTO'
import { StatisticCollectionRaw } from './StatisticCollectionRaw'
import { StatisticsRaw } from './StatisticsRaw'
import { StatisticDTOFactory } from './StatisticDTOFactory'

export class StatisticCollectionDTOFactory {
    static fromRaw(raw: StatisticCollectionRaw): StatisticCollectionDTO {
        return new StatisticCollectionDTO(
            raw.result.map((rainfall) => StatisticDTOFactory.fromRaw(StatisticsRaw.fromRaw(rainfall))),
            raw.total_items
        )
    }
}

import { City } from './City'

export class CityCollection {
    private citiesMap: Map<string, City> = new Map()

    constructor(private cities: City[]) {
        this.cities = cities
        this.cities.sort((a: City, b: City) => (a.getName() > b.getName() ? 1 : -1))
        for (const city of this.cities) {
            this.citiesMap.set(city.getName(), city)
        }
    }

    get(index: number): City {
        return this.cities[index]
    }

    getByName(name: string): City | null {
        const result = this.citiesMap.get(name)
        return result ? result : null
    }

    getCities(): City[] {
        return this.cities
    }

    hasCities(): boolean {
        return this.cities.length > 0
    }
}

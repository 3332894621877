import { DateTime } from 'luxon'

import { UserAPI } from '@/api/user/UserAPI'
import { IAuthAPI } from '@/api/auth/IAuthAPI'

import { BaseService } from '../BaseService'
import { IUserService } from './IUserService'
import { ServiceLocator } from '../ServiceLocator'
import { UserDTOFactory } from './UserDTOFactory'
import { ICityService } from '../city/ICityService'
import { UserFactory } from './UserFactory'
import { UserCollectionFactory } from './UserCollectionFactory'

import { UserCollection } from '@/model/UserCollection'
import { UserPasswordUpdate } from '@/model/UserPasswordUpdate'
import { UserAlarmsUpdate } from '@/model/UserAlarmsUpdate'
import { User } from '@/model/User'

export class UserService extends BaseService implements IUserService {
    private readonly api: UserAPI = new UserAPI()
    private readonly authService!: IAuthAPI
    private readonly cityService!: ICityService
    private currentUser!: User

    constructor(locator: ServiceLocator) {
        super(locator)
        this.authService = locator.get<IAuthAPI>('auth')
        this.cityService = locator.get<ICityService>('city')
    }

    async getUsers(page: number, itemsPerPage: number, ascendingOrder: boolean): Promise<UserCollection> {
        const dto = await this.api.getUsers(page, itemsPerPage, ascendingOrder)
        return UserCollectionFactory.fromDTO(dto, this.cityService.getCityCollection())
    }

    async getUser(username: string): Promise<User> {
        const dto = await this.api.getUser(username)
        return UserFactory.fromUserDTO(dto, this.cityService.getCityCollection())
    }

    async getCurrentUser(): Promise<User> {
        if (!this.authService.isAuthenticated()) {
            throw new Error('user.errors.not_authenticated')
        }

        const userAsDTO = await this.api.getMe()
        if (!userAsDTO) {
            throw new Error('user.errors.current_not_found')
        }

        this.currentUser = UserFactory.fromUserDTO(userAsDTO, this.cityService.getCityCollection())
        return this.currentUser
    }

    async createUser(newUser: User): Promise<void> {
        await this.api.createUser(UserDTOFactory.dtoForCreate(newUser))
    }

    async updateUser(username: string, newUser: User): Promise<void> {
        await this.api.updateUser(username, UserDTOFactory.dtoForUpdate(newUser))
    }

    async deleteUser(username: string): Promise<void> {
        await this.api.deleteUser(username)
    }

    async updateUserLanguage(language: string): Promise<void> {
        this.api.updateUserLanguage(language)
    }

    async updateUserPassword(userSettings: UserPasswordUpdate): Promise<void> {
        this.api.updateUserPassword(
            userSettings.getCurrentPassword(),
            userSettings.getNewPassword(),
            userSettings.getConfirmNewPassword()
        )
    }

    async updateUserSettings(userSettings: UserAlarmsUpdate): Promise<void> {
        this.api.updateUserSettings(
            userSettings.getCitiesAlarms(),
            userSettings.getEmail(),
            userSettings.getEmailAlarms(),
            userSettings.getPhone(),
            userSettings.getSmsAlarms()
        )
    }

    getLastSelectedEventYear(): number {
        if (localStorage.lastSelectedEventYear) {
            return localStorage.lastSelectedEventYear
        }
        return DateTime.now().year
    }

    setLastSelectedEventYear(year: number): void {
        localStorage.lastSelectedEventYear = year
    }
}

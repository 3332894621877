import { History } from '@/model/History'
import { HistoryDTO } from '@/api/core/dto/history/HistoryDTO'
import { DateTime } from 'luxon'
import { City } from '@/model/City'
import { TimeStepFactory } from '../snapshot/TimeStepFactory'

export class HistoryFactory {
    static createEmpty(city: City, start: DateTime, end: DateTime): History {
        return new History(city, start.toUTC(), end.toUTC())
    }

    static fromDTO(dto: HistoryDTO, city: City): History {
        const result = new History(
            city,
            DateTime.fromISO(dto.start, { zone: 'UTC' }),
            DateTime.fromISO(dto.end, { zone: 'UTC' })
        )

        for (const timeStepDTO of dto.history) {
            result.addHistory(TimeStepFactory.fromDTO(timeStepDTO, result))
        }

        return result
    }
}

import { DateTime } from 'luxon'

import { City } from '@/model/City'
import { History } from '@/model/History'

import { CoreAPI } from '@/api/core/CoreAPI'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { IHistoryService } from './IHistoryService'
import { HistoryFactory } from './HistoryFactory'

export class HistoryService extends BaseService implements IHistoryService {
    private readonly api: CoreAPI = new CoreAPI()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
    }

    /**
     * Try to load the history for a {@code city} and a date range {@code [start; end]}.
     */
    async loadHistory(city: City, start: DateTime, end: DateTime): Promise<History> {
        let dto = null
        try {
            dto = await this.api.getHistory(city.getName(), start, end)
        } catch (exception) {
            console.error(exception)
        }

        return dto ? HistoryFactory.fromDTO(dto, city) : HistoryFactory.createEmpty(city, start, end)
    }
}

import axios, { AxiosError } from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { DateTime } from 'luxon'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { IAuthAPI } from './IAuthAPI'
import { DefaultCredentials } from './model/DefaultCredentials'

const ACCESS_TOKEN_KEY = 'accessToken'

export class SimpleAuthAPI implements IAuthAPI {
    private accessToken: string | null = null
    private routes = {
        login: makeURL('/api/auth/login')
    }

    public async initialize(): Promise<void> {
        this.setAccessToken(localStorage.getItem(ACCESS_TOKEN_KEY))
    }

    private setAccessToken(token: string | null) {
        this.accessToken = token
        if (this.accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            localStorage.setItem(ACCESS_TOKEN_KEY, this.accessToken)
            return
        }
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        delete axios.defaults.headers.common['Authorization']
    }

    public isAuthenticated(): boolean {
        if (this.accessToken === null) {
            return false
        }

        const decoded = jwtDecode<JwtPayload>(this.accessToken)
        if (!decoded.exp) {
            return false
        }

        const expirationDate = DateTime.fromSeconds(decoded.exp)
        if (expirationDate <= DateTime.now()) {
            return false
        }

        return true
    }

    public async login(credentials: DefaultCredentials): Promise<boolean> {
        try {
            const body = {
                username: credentials.username,
                password: credentials.password
            }

            const result = await axios.post(this.routes.login, body)

            const accessToken = result.data['access_token']
            if (!accessToken) {
                return false
            }

            this.setAccessToken(accessToken)
            return true
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async logout(): Promise<void> {
        this.setAccessToken(null)
    }
}
